import React from "react";

// Customizable Area Start

import AccountRegistrationController, { Props }  from "./AccountRegistrationController";
import {
  Box,
  Grid,
  Typography,
  Button,
  styled,
} from "@material-ui/core";
import {
    App_Logo,Collage,indiaFlag,iphoneImage,iphoneLogo, Wave
} from "./assets";

// Customizable Area End




export default class GetStartedSignup extends AccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
      {}
        <ParentGrid container>
            <ImageGridMain item md={6}>
                <OuterBox>
                    <ImageLogoMain src={App_Logo} alt="Logo Image" />
                    <ImageBox style={{display: 'flex',  position: 'relative'}}>
                        <Box style={{background: '#F5EA16', borderRadius: '48px', width: 403, height: 378, position: 'relative'}}>
                            <img style={{position: 'absolute', left: 20, top: -40}} src={iphoneImage} alt="Logo Image" />
                            <img style={{position: 'absolute', right: 20, bottom: -30, zIndex: 3}} src={iphoneLogo} alt="Logo Image" />
                        </Box>
                        <Box style={{background: '#F5EA16', borderRadius: '32px', width: 358, height: 357, position: 'relative', top: 280, left: -50}}>
                            <img style={{borderRadius: '22px',position: 'absolute', top: -20, left: -20}} src={Collage} alt="Logo Image" />
                        </Box>
                    </ImageBox>
                </OuterBox>
            </ImageGridMain>
            <RightGrid item md={6}>
                <WaveImg src={Wave} alt="overlay" />
                <WaveImg2 src={Wave} alt="overlay" />
                <ContentBox>
                    <Box>
                        <HeadingTypography>Go Live, Buy, Sell all at one place</HeadingTypography>
                            <ButtonBox >
                                <BuyerButton variant="contained" color="primary" onClick={this.goToBuyerSignup} >
                                    Signup as Buyer
                                </BuyerButton>
                                <BuyerButton variant="contained" color="primary" onClick={this.goToSellerSignup}>
                                    Signup as Seller
                                </BuyerButton>
                            </ButtonBox>
                            <Box style={{ color: 'white', textAlign: 'center', marginTop: 40, marginBottom: 110 }}>
                                Already have an account?

                                <span style={{ color: "#F5EA16", cursor: "pointer", marginLeft:"5px" }} onClick={this.goToLoginPage}>
                                    Login
                                </span>
                                <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center', gridGap: '5px', height: '16'
                                        }} 
                                        style={{ marginTop: '28px' }}>
                                        <img src={indiaFlag} alt="" />
                                        <Typography component={'p'} style={{ fontFamily: 'Poppins-Regular', fontSize: '16px', }}>Made in Bharat</Typography>
                                </Box>
                            </Box>
                    </Box>
                </ContentBox>
            </RightGrid>
        </ParentGrid>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const ParentGrid = styled(Grid)({
    background:"#000",
    // height:"100vh"
})
const OuterBox = styled(Box)({
    maxWidth: 800,
    position: 'relative',
    '@media(min-width: 1750px)': {
        transform: 'scale(0.8)',
        transformOrigin: 'top',
        marginTop: 50,
        minHeight: '100vh',
    },
    '@media(min-width: 1280px) and (max-width: 1750px)': {
        transform: 'scale(0.65)',
        transformOrigin: 'top',
        marginTop: 50,
        minHeight: '100vh',
    },
    '@media(min-width: 1100px) and (max-width: 1280px)': {
        transform: 'scale(0.5)',
        transformOrigin: 'top',
        marginTop: 50,
        minHeight: '100vh',
    },
    '@media(min-width: 960px) and (max-width: 1100px)': {
        transform: 'scale(0.4)',
        transformOrigin: 'top',
        marginTop: 50,
        minHeight: '100vh',
    },
    '@media(min-width: 767px) and (max-width: 959px)': {
        transform: 'scale(0.7)',
        transformOrigin: 'top',
        marginTop: 50,
    },
    '@media(min-width: 500px) and (max-width: 767px)': {
        transform: 'scale(0.6)',
        transformOrigin: 'top',
        marginTop: 50
    },
    '@media(min-width: 100px) and (max-width: 500px)': {
        transform: 'scale(0.5)',
        transformOrigin: 'top',
        marginTop: 50
    },
    // '@media(min-width: 100px) and (max-width: 760px)': {
    //     minHeight: "500px",
    // },
    '@media(min-width: 1920px)': {
        minHeight: '100vh',
    },
})
const ImageBox = styled(Box)({
    marginTop: 250, 
    marginLeft: 40,
    '@mediaand (min-width: 1920px)': {
        height: 700
    },
    '@media(max-width: 1920px)': {
        marginTop: 200
    },
    '@media(min-width: 1280px) and (max-width: 1550px)': {
        transform: 'scale(0.9)',
    },
})
const RightGrid = styled(Grid)({
    background: 'black',
    width: '100%',
    position:"relative",
    overflow:"hidden",
    height: '100vh',

    '@media(max-width: 500px)': {
        marginTop:-110,
    },
    '@media(max-width: 960px)': {
        height: 'unset'
    },
    '@media(min-width: 500px) and (max-width: 767px)': {
        marginTop:-100,
    },
})
const ContentBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    '@media(max-width: 959px)': {
        marginLeft:0,
        marginTop:30,
    },
    '@media(min-width: 959px) and (max-width: 1290px)': {
        transform: 'scale(0.8)',
        marginTop:50,
    },
    '@media(min-width: 960px) and (max-width: 1100px)': {
        transform: 'scale(0.6)',
        transformOrigin: 'top',
        marginTop: 50,
    },
    '@media(min-width: 1100px) and (max-width: 1280px)': {
        marginTop:100,
        transformOrigin: 'top',
        transform: 'scale(0.6)',
    },
    '@media(min-width: 1750px)': {
        transform: 'scale(0.8)',
        transformOrigin: 'top',
        marginTop: 100,
    },
    '@media(min-width: 1280px) and (max-width: 1750px)': {
        transform: 'scale(0.65)',
        // transformOrigin: 'top',
        marginTop: 0,
    },
    marginTop:173,
})
const ImageGridMain = styled(Grid)({
    // border: '1px solid #FFF',
    background: 'rgba(255, 255, 255, 0.25)',
    backdropFilter: 'blur(13.591408729553223px)',
    width: "100%",
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    height: '100vh',
    '@media(max-width: 959px)': {
        height: 'unset',
        alignItems: 'flex-end',
        background: 'unset',
        border: 'none',
    },
    '@media(min-width: 500px) and (max-width: 959px)': {
        paddingBottom: 50,
    },
    '@media(min-width: 1200)': {
        height: "100%",
    }
})

const ImageLogoMain = styled('img')({
    position: 'absolute', 
    top: 50, 
    left: 20,
    '@media(max-width: 1920px)': {
        top: 0, 
        left: 0,
    },
    '@media(min-width: 960px) and (max-width: 1919px)': {
        left: 20
    },
})

const HeadingTypography = styled(Typography)({
    color: '#FFF',
    textAlign: 'center',
    fontFamily: "'Poppins-Regular', sans-serif",
    paddingBottom: 10,
    fontSize: '60px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    maxWidth: 710,
    '@media(max-width: 959px)': {
        fontSize: '44px',
        letterSpacing: '-1.8px',
        lineHeight: '58px',
        paddingInline: 20,
    },
    '@media(max-width: 1280px)': {
        fontSize: '50px',
    }
})

const ButtonBox = styled(Box)({
    marginTop: 90,
    margin: 'auto',
    textAlign: 'center',
    '& .MuiButton-label': {
        textTransform: 'initial'
    },
    '@media(max-width: 959px)': {
        marginTop:30
    },
    '@media(max-width: 512px)': {
        margin:"0px 20px"
    }
})
const BuyerButton = styled(Button)({
borderRadius: '50px',
border: '1px solid #F5EA16',
background: 'black',
width: '100%',
maxWidth: '412px',
margin: '50px auto 0',
height: '68px',
color: '#fff',
textAlign: 'center',
fontFamily: 'Poppins-Regular',
fontSize: '20px',
fontStyle: 'normal',
fontWeight: 500,
lineHeight: 'normal',
letterSpacing: '0.417px',
"&:hover":{
    background:"#F5EA16",
    color: 'black'
},
});

const WaveImg = styled('img')({
    position: 'absolute',
    bottom: 0,
    width: '100%'
})
const WaveImg2 = styled('img')({
    position: 'absolute',
    bottom: 0,
    left: -169,
    width: '100%'
})


// Customizable Area End
