import React from "react";

import {
    // Customizable Area Start
    Box,
    Grid,
    Typography,
    TextField,
    styled,
    Button,
    makeStyles,
    Theme,
    createStyles,
    Stepper,
    Step,
    StepLabel,
    Radio,
    RadioGroup,
    FormControlLabel,
    InputLabel,
    Select,
    MenuItem,
    FormControl,
    LinearProgress,
    Checkbox
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import {
    twoWave,
    App_Logo,
} from "./assets";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";


const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        backButton: {
            marginRight: theme.spacing(1),
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    }),
);
import { configJSON } from "./AccountRegistrationController";
import LoginFailModalWeb from "../../../components/src/LoginFailModal.web";
// Customizable Area End

import AccountRegistrationController, {
    Props,
} from "./AccountRegistrationController";

class BankingDetails extends AccountRegistrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    getSteps() {

        const { bankDetailsFilled, addressDetailsFilled, businessDetailsFilled } = this.state;

        return [
            { label: 1, complete: bankDetailsFilled },
            { label: 2, complete: addressDetailsFilled },
            { label: 3, complete: businessDetailsFilled }
        ];
    }
    renderBankingDetails() {
        const { classes } = this.props
        return (
            <>
                <Box className={'formHeading'}>
                    <Typography className={classes.sectionTitle} variant="h6" component={'h6'}>{configJSON.bankDetails}</Typography>
                    <Typography component={'p'}>{'Enter your bank account details'}</Typography>
                </Box>
                <Box
                    component="form"
                    className={'signupFormWrapper'}
                    data-test-id="sellerSignupForm"
                >
                    <Box>
                        <InputLabel
                            className={'BusinessLabel textWhite customShrink'}
                            shrink={true}
                            htmlFor="bank-type-label"
                        >
                            {"Bank Account Type"}
                        </InputLabel>
                        <Select
                            className={`${this.state.bankAccountType ? "selectActive" : "selectActiveNot"}`}
                            labelId="bank-type-label"
                            id="bank-type-label"
                            data-test-id="bankSelectTestId"
                            value={this.state.bankAccountType || configJSON.selectAnyone}
                            onChange={this.handleBankAccountTypeChange}
                            variant="outlined"
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                },
                                getContentAnchorEl: null,
                                PaperProps: {
                                    style: selectStylePaper,
                                },
                            }}
                        >
                            <MenuItem
                                className={classes.accountTypeLabel}
                                value={configJSON.selectAnyone}
                            >
                                {configJSON.selectAntoneLabel}
                            </MenuItem>
                            <MenuItem
                                className={classes.menuItems}
                                value={"saving"}
                            >
                                {"Saving"}
                            </MenuItem>
                            <MenuItem
                                className={classes.menuItems}
                                value={"current"}
                            >
                                {"Current"}
                            </MenuItem>
                        </Select>
                        <ErrorTag>{this.state.bankAccountTypeError}</ErrorTag>
                    </Box>
                    <Box className={'inputFeild'}>
                        <TextField
                            id="outlined-number"
                            label="Bank Account Number"
                            variant="outlined"
                            data-test-id="businessName"
                            placeholder={configJSON.bankAccountPlaceholder}
                            value={this.state.bankAccountNumber}
                            onChange={this.handleBankAccNumber}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            autoComplete="off"
                        />
                        {this.state.bankAccountNumberError !== "" && (
                            <ErrorTag>{this.state.bankAccountNumberError}</ErrorTag>
                        )}
                    </Box>
                    <Box className={'inputFeild'}>
                        <TextField
                            id="outlined-textarea"
                            data-test-id="fullName"
                            label="Account Holder Name"
                            variant="outlined"
                            placeholder={configJSON.AccountHolderNamePlaceholder}
                            name="fullName"
                            value={this.state.AccountHolderName}
                            onChange={this.handleAccHolderName}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        {this.state.AccountHolderNameError !== "" && (
                            <ErrorTag>{this.state.AccountHolderNameError}</ErrorTag>
                        )}
                    </Box>
                    <Box className={'inputFeild'}>
                        <TextField
                            id="outlined-textarea"
                            label="Bank Name"
                            data-test-id="userName"
                            name="userName"
                            placeholder={configJSON.bankNamePlaceholder}
                            onChange={this.handleBankName}
                            variant="outlined"
                            value={this.state.bankName}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        {this.state.bankNameError !== "" && (
                            <ErrorTag>{this.state.bankNameError}</ErrorTag>
                        )}

                    </Box>
                    <Box className={'inputField'} style={{ position: 'relative' }}>
                        <TextField
                            id="outlined-textarea"
                            label="IFSC Code"
                            variant="outlined"
                            value={this.state.ifscCode}
                            onChange={this.handleIFSCcode}
                            placeholder={configJSON.ifscPlaceholder}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        {this.state.ifscCodeError !== "" && (
                            <ErrorTag>{this.state.ifscCodeError}</ErrorTag>
                        )}
                    </Box>
                    <Box className={'inputFeild'}>
                        <TextField
                            id="outlined-textarea"
                            label="Upi Id"
                            data-test-id="upiIdTestId"
                            name="upiId"
                            placeholder={"john@hsbc"}
                            onChange={this.handleUpiChange}
                            variant="outlined"
                            value={this.state.upiId}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <ErrorTag>{this.state.upiIdError}</ErrorTag>
                    </Box>
                </Box>
            </>
        );
    }

    renderAddressDetails() {
        const { classes } = this.props
        return (
            <>
                <Box className={'formHeading'}>
                    <Typography variant="h6" component={'h6'} className={classes.sectionTitle}>{configJSON.returnAndPickUp}</Typography>
                    <Typography component={'p'}>{'Enter your Address details'}</Typography>
                </Box>
                <Box
                    component="form"
                    className={'signupFormWrapper'}
                    data-test-id="sellerSignupForm"
                >
                    <Box className={'inputFeild'}>
                        <TextField
                            id="outlined-number"
                            label="Address line 1"
                            variant="outlined"
                            data-test-id="businessName"
                            name="business_Name"
                            value={this.state.addressOne}
                            onChange={this.handleAddressOne}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            autoComplete="off"
                            placeholder={configJSON.addressLineOnePlaceholder}
                        />
                        {this.state.addressOneError !== "" && (
                            <ErrorTag>{this.state.addressOneError}</ErrorTag>
                        )}
                    </Box>
                    <Box className={'inputFeild'}>
                        <TextField
                            id="outlined-textarea"
                            data-test-id="fullName"
                            label="Address line 2"
                            variant="outlined"
                            name="fullName"
                            value={this.state.addressTwo}
                            onChange={this.handleAddressTwo}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            autoComplete="off"
                            placeholder={configJSON.addressLineTwoPlaceholder}
                        />
                    </Box>
                    <Box className={'inputFeild'}>
                        <TextField
                            id="outlined-textarea"
                            label="Pin code"
                            data-test-id="userName"
                            name="userName"
                            onChange={this.handlePincode}
                            variant="outlined"
                            value={this.state.pinCode}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            placeholder={configJSON.pincodePlaceholder}
                        />
                        {this.state.pinCodeError !== "" && (
                            <ErrorTag>{this.state.pinCodeError}</ErrorTag>
                        )}

                    </Box>
                    <Box className={'inputField'} style={{ position: 'relative' }}>
                        <TextField
                            id="outlined-textarea"
                            label="City"
                            variant="outlined"
                            value={this.state.city}
                            onChange={this.handleCityChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            placeholder="Port Rubyeborough"
                        />
                        {this.state.cityError !== "" && (
                            <ErrorTag>{this.state.cityError}</ErrorTag>
                        )}
                    </Box>
                    <Box className={'inputFeild'}>
                        <TextField
                            id="outlined-textarea"
                            label="State"
                            data-test-id="userName"
                            name="userName"
                            onChange={this.handleStateChange}
                            variant="outlined"
                            value={this.state.state}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            placeholder="Indiana"
                        />
                        {this.state.stateError !== "" && (
                            <ErrorTag>{this.state.stateError}</ErrorTag>
                        )}

                    </Box>
                    <Box className={'inputFeild'}>
                        <TextField
                            id="outlined-textarea"
                            label="Country"
                            data-test-id="userName"
                            name="userName"
                            onChange={this.handleCountryChange}
                            variant="outlined"
                            value={this.state.country}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            placeholder="united states"
                        />
                        {this.state.countryError !== "" && (
                            <ErrorTag>{this.state.countryError}</ErrorTag>
                        )}

                    </Box>
                </Box>
            </>
        )
    }
    renderBusinessDetails() {
        const { classes } = this.props
        return (
            <>
                <Box className={'formHeading'}>
                    <Typography variant="h6" component={'h6'} className={classes.sectionTitle}>{configJSON.businessDetails}</Typography>
                    <Typography component={'p'}>{'Enter your Business and legal details'}</Typography>
                </Box>
                <Box
                    component="form"
                    className={'signupFormWrapper'}
                    data-test-id="sellerSignupForm"
                >
                    {this.renderSelectOptionView()}
                    <CustomFormControl variant="outlined"
                    >
                        <InputLabel
                            className={'BusinessLabel textWhite customShrink'}
                            shrink={true}
                            htmlFor="demo-simple-select"
                            data-test-id="businessType"
                            
                        >
                           <span> Category </span> 
                        </InputLabel>
                        {this.renderInputLabel()}
                        <div style={{ width: '100%', maxWidth: '100%' }}>
                        <Select
                            multiple  
                            onOpen={this.handleOpenCat}
                            className={'selectActive textWhite'}
                            labelId="demo-simple-select"
                            id="demo-simple-select"
                            data-test-id="category-select"
                            value={this.state.selectedCategories}  
                            onChange={this.changebusinessCategory}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                },
                                getContentAnchorEl: null,
                                PaperProps: {
                                    style: {
                                        backgroundColor: 'black',
                                        border: '2px solid rgba(255, 255, 255, 0.50)',
                                        borderRadius: '0px 0px 12px 12px',
                                        margin: '-8px 0px',
                                        borderTop: 'none',
                                        maxHeight: '300px', 
                                        width: 'auto',
                                    },
                                },
                            }}
                            renderValue={(selected:any) => selected.map((id: any) => {
                                const category = this.state.businessCategories.find((category: any) => category.attributes.id === id);
                                return category ? category.attributes.name : '';
                            }).join(', ')} 
                        >
                            <MenuItem
                                style={{ display: 'none' }}
                                value={configJSON.selectAnyone}
                            >
                                {configJSON.selectAntoneLabel}
                            </MenuItem>
                            {this.state.businessCategories && this.state.businessCategories.map((i: any) =>
                                <MenuItem key={i.attributes.id} value={i.attributes.id}>
                                    <Checkbox
                                         checked={Array.isArray(this.state.selectedCategories) && this.state.selectedCategories.includes(i.attributes.id)}
                                         style={{ color: '#ffffff' }}
                                    />
                                   <span style={{ color: '#ffffff' }}>{i.attributes.name}</span>  
                                </MenuItem>
                            )}
                        </Select>
                        </div>
                        {this.renderCategoryError()}
                    </CustomFormControl>
                    <CustomFormControl variant="outlined" >
                        <InputLabel
                            className={'BusinessLabel textWhite customShrink'}
                            shrink={true}
                            htmlFor="demo-simple-select"
                            data-test-id="businessType"
                        >
                            Sub category
                        </InputLabel>
                        {this.renderSubCategoryLabel()}
                          <div style={{ width: '100%', maxWidth: '100%' }}>
                        <Select
                            multiple
                            onOpen={this.handleOpenSubCat}
                            className={'selectActive textWhite'}
                            labelId="demo-simple-select"
                            id="demo-simple-select"
                            data-test-id="sub-category-select"
                            value={this.state.selectedSubCategories}
                            onChange={this.changebusinessSubCategory}
                            variant="outlined"
                            displayEmpty
                            renderValue={(selected: any) => selected.map((id: any) => {
                                const subcategory = this.state.businessSubCategories.find((subcategory: any) => subcategory.attributes.id === id);
                                return subcategory ? subcategory.attributes.name : '';
                            }).join(', ')}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                },
                                getContentAnchorEl: null,
                                PaperProps: {
                                    style: {
                                        backgroundColor: 'black',
                                        border: '2px solid rgba(255, 255, 255, 0.50)',
                                        borderRadius: '0px 0px 12px 12px',
                                        margin: '-14px 0px',
                                        borderTop: 'none',
                                        maxHeight: '300px',
                                        overflowY: 'auto',
                                        width: 'auto',
                                    },
                                },
                            }}
                        >
                            {this.state.businessSubCategories
                                .filter((subcategory: any) =>
                                    Array.isArray(this.state.selectedCategories) && this.state.selectedCategories.includes(subcategory.attributes.category_id)
                                )
                                .map((i: any) => (
                                    <MenuItem key={i.attributes.id} value={i.attributes.id}>
                                        <Checkbox
                                            checked={Array.isArray(this.state.selectedSubCategories) && this.state.selectedSubCategories.includes(i.attributes.id)}
                                            style={{ color: '#ffffff' }}
                                        />
                                        <span style={{ color: '#ffffff' }}>{i.attributes.name}</span>
                                    </MenuItem>
                                ))
                            }
                        </Select>
                        </div>
                    {this.renderSubCategoryError()}
                    </CustomFormControl>
                    
                    {this.renderGstOption()}
                    {this.renderGstView()}
                    {this.renderPanNumberInput()}
<Box style={{ border: '1px dashed rgba(255, 255, 255, 0.5)', borderRadius: '10px', height: 155, width: '100%', position: 'relative' }}>
    <input onClick={this.clickUpload} data-testid="file-input"
        onChange={this.handleFileChange}
        type="file" name="" id="" multiple 
        style={{ height: 150, opacity: 0, cursor: "pointer", width: '100%', zIndex: 10, position: 'relative' }} />
    <Typography data-testid="upload-text" style={{
        position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }}>
         <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                                    <path d="M20.9997 39.666V25.666L16.333 30.3327" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M21 25.667L25.6667 30.3337" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M51.3337 23.3327V34.9994C51.3337 46.666 46.667 51.3327 35.0003 51.3327H21.0003C9.33366 51.3327 4.66699 46.666 4.66699 34.9994V20.9994C4.66699 9.33268 9.33366 4.66602 21.0003 4.66602H32.667" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M42.0003 23.3327C35.0003 23.3327 32.667 20.9993 32.667 13.9993V4.66602L51.3337 23.3327H42.0003Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
        <span>
            <span className={classes.uploadTxt}>Upload</span> {this.state.isGstNumber === "No"? "" : "GST Certificate,"} Pan Card, Trade license here</span>
    </Typography>
</Box>

                    {this.state.uploadFiles.map((file:any, index: number) => (
                       
                        <Box ata-testid="file-input" key={index} style={{ border: '1px dashed rgba(255, 255, 255, 0.5)', borderRadius: '10px', height: 90, width: '100%', position: 'relative', padding: '18px 27px' }}>
                            <Box ata-testid="file-input" style={{ display: 'flex', gap: 8 }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                    <path d="M25.0002 36.6663C33.3335 36.6663 36.6668 33.333 36.6668 24.9997V14.9997C36.6668 6.66634 33.3335 3.33301 25.0002 3.33301H15.0002C6.66683 3.33301 3.3335 6.66634 3.3335 14.9997V24.9997C3.3335 33.333 6.66683 36.6663 15.0002 36.6663H25.0002Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.9998 16.6667C13.1589 16.6667 11.6665 15.1743 11.6665 13.3333C11.6665 11.4924 13.1589 10 14.9998 10C16.8408 10 18.3332 11.4924 18.3332 13.3333C18.3332 15.1743 16.8408 16.6667 14.9998 16.6667Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M4.4502 31.5836L12.6669 26.0669C13.9835 25.1836 15.8835 25.2836 17.0669 26.3003L17.6169 26.7836C18.9169 27.9003 21.0169 27.9003 22.3169 26.7836L29.2502 20.8336C30.5502 19.7169 32.6502 19.7169 33.9502 20.8336L36.6669 23.1669" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <Box>
                                    <Typography style={{ fontWeight: 500, fontSize: 14, margin: 0, textAlign: 'left' }}>{file.name}</Typography>
                                    <Typography style={{ fontWeight: 300, fontSize: 12, margin: 0, paddingTop: 4, textAlign: 'left' }}>{this.formatFileSize(file.size)}</Typography>
                                </Box>
                                <svg ata-testid="file-input" onClick={() => this.clickImg(index)} style={{ position: 'absolute', top: 20, right: 20, cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                    <path d="M9.66901 0.283156C9.59963 0.213628 9.51721 0.158468 9.42648 0.120832C9.33575 0.0831955 9.23849 0.0638229 9.14026 0.0638229C9.04204 0.0638229 8.94478 0.0831955 8.85405 0.120832C8.76332 0.158468 8.6809 0.213628 8.61152 0.283156L4.94402 3.94316L1.27652 0.275656C1.20708 0.206219 1.12465 0.151139 1.03392 0.113561C0.9432 0.0759819 0.845963 0.0566406 0.747766 0.0566406C0.649568 0.0566406 0.552331 0.0759819 0.461608 0.113561C0.370885 0.151139 0.288452 0.206219 0.219015 0.275656C0.149579 0.345092 0.0944988 0.427525 0.0569201 0.518248C0.0193413 0.608972 -7.31631e-10 0.706208 0 0.804406C7.31632e-10 0.902604 0.0193413 0.99984 0.0569201 1.09056C0.0944988 1.18129 0.149579 1.26372 0.219015 1.33316L3.88652 5.00066L0.219015 8.66816C0.149579 8.73759 0.0944988 8.82003 0.0569201 8.91075C0.0193413 9.00147 0 9.09871 0 9.19691C0 9.2951 0.0193413 9.39234 0.0569201 9.48306C0.0944988 9.57379 0.149579 9.65622 0.219015 9.72565C0.288452 9.79509 0.370885 9.85017 0.461608 9.88775C0.552331 9.92533 0.649568 9.94467 0.747766 9.94467C0.845963 9.94467 0.9432 9.92533 1.03392 9.88775C1.12465 9.85017 1.20708 9.79509 1.27652 9.72565L4.94402 6.05816L8.61152 9.72565C8.68095 9.79509 8.76338 9.85017 8.85411 9.88775C8.94483 9.92533 9.04207 9.94467 9.14026 9.94467C9.23846 9.94467 9.3357 9.92533 9.42642 9.88775C9.51715 9.85017 9.59958 9.79509 9.66901 9.72565C9.73845 9.65622 9.79353 9.57379 9.83111 9.48306C9.86869 9.39234 9.88803 9.2951 9.88803 9.19691C9.88803 9.09871 9.86869 9.00147 9.83111 8.91075C9.79353 8.82003 9.73845 8.73759 9.66901 8.66816L6.00151 5.00066L9.66901 1.33316C9.95401 1.04816 9.95401 0.568156 9.66901 0.283156Z" fill="white" />
                                </svg>
                            </Box>
                            {this.state.progress !== 0 && (
                                <CustomLinearProgress ata-testid="file-input" variant="determinate" value={this.state.progress} />
                            )}
                        </Box>
                    ))}

                    {(this.state.uploadFiles === null || this.state.uploadFiles.length < 3) && (
                        <ErrorTag style={{ marginTop: "-29px" }}>{this.state.uploadError}</ErrorTag>
                    )}
                </Box>
            </>
        )
    }
    activeStep1() {
        return (
            this.state.activeStep === 1 ?
                this.renderAddressDetails()
                : this.renderBusinessDetails()
        )
    }
    activeStep1Button() {
        return (
            this.state.activeStep === 1 ? this.validateAddress : this.validateBusinessDetails
        )
    }

    renderSelectOptionView = () => {
        const { classes } = this.props;
        return (
            <>
                <Box>
                    <InputLabel className={'BusinessLabel textWhite customShrink'} shrink={true} htmlFor="company-type-toohle"  >
                        {"Company Type"}
                    </InputLabel>
                    <Select
                        id="company-type-toohle"
                        className={`${this.state.companyType !== "" ? "selectActive" : "selectActiveNot"}`}
                        labelId="company-type-toohle"
                        value={this.state.companyType || configJSON.selectAnyone}
                        data-test-id="companyTypeSelect"
                        variant="outlined"
                        onChange={this.handleCompanyTypeSelect}
                        MenuProps={{
                            PaperProps: {
                                style: selectStylePaper,
                            },
                            transformOrigin: {
                                horizontal: 'left',
                                vertical: 'top',
                            },
                            anchorOrigin: {
                                horizontal: 'left',
                                vertical: 'bottom',
                            },
                            getContentAnchorEl: null,
                            
                        }}
                    >
                        <MenuItem className={classes.accountTypeLabel} value={configJSON.selectAnyone} >
                                {configJSON.selectAntoneLabel}
                        </MenuItem>
                        <MenuItem 
                         className={classes.menuItems} value={"pvt_limited"} >
                            {"Private Limited"}
                        </MenuItem>
                        <MenuItem 
                         className={classes.menuItems} value={"proprietor"} >
                            {"Sole Proprietor"}
                        </MenuItem>
                        <MenuItem 
                         className={classes.menuItems} value={"partnership"} >
                            {"Partnership"}
                        </MenuItem>
                        <MenuItem 
                         className={classes.menuItems} value={"llp"} >
                            {"Limited Liability Partnership"}
                        </MenuItem>
                    </Select>
                    <ErrorTag>{this.state.companyTypeError}</ErrorTag>
                </Box>
            </>
        )
    };

    renderGstOption = () => {
        const { classes } = this.props;
        return (
            <Box>
                <InputLabel className={'BusinessLabel textWhite customShrink'} shrink={true} htmlFor="gst-toggle"  >
                    {"Have GST Number?"}
                </InputLabel>
                <Select
                    id="gst-toggle"
                    className={`${this.state.isGstNumber ? "selectActive" : "selectActiveNot"}`}
                    labelId="gst-toggle"
                    value={this.state.isGstNumber || configJSON.selectAnyone}
                    data-test-id="gstSelectTestId"
                    variant="outlined"
                    onChange={this.handleGstToogle}
                    MenuProps={{
                        anchorOrigin: {
                            horizontal: 'left',
                            vertical: 'bottom',
                        },
                        transformOrigin: {
                            horizontal: 'left',
                            vertical: 'top',
                        },
                        getContentAnchorEl: null,
                        PaperProps: {
                            style: selectStylePaper,
                        },
                    }}
                >
                    <MenuItem
                        className={classes.accountTypeLabel}
                        value={configJSON.selectAnyone}
                    >
                        {configJSON.selectAntoneLabel}
                    </MenuItem>
                    <MenuItem className={classes.menuItems} value={"Yes"} >
                        {"Yes"}
                    </MenuItem>
                    <MenuItem className={classes.menuItems} value={"No"} >
                        {"No"}
                    </MenuItem>
                </Select>
                {this.state.isGstNumber === "" && <ErrorTag>{this.state.gst_panError}</ErrorTag>}
            </Box>
        )
    };

    renderGstView = () => {
        if (this.state.isGstNumber === "Yes") {
            return (
                <Box className={'inputFeild'}>
                    <TextField
                        id="outlined-number"
                        label="GST Number"
                        variant="outlined"
                        value={this.state.gst_pan}
                        onChange={this.handleGSTPAN}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        autoComplete="off"
                        placeholder={configJSON.gstinOrPanPlaceholder}
                    />
                    {this.state.gst_panError !== "" && (
                        <ErrorTag>{this.state.gst_panError}</ErrorTag>
                    )}
                </Box>)
        }
    };
    
    renderInputLabel = () => {
        return this.state.slectText && <span className="placeholderStyle"> Select Category</span>;
    };
    
    renderSubCategoryLabel = () => {
        return this.state.slectTextSub && <span className="placeholderStyle"> Select Sub Category</span>
    };

    renderPanNumberInput = () => {
        return (
            <Box className={'inputFeild'}>
                <TextField
                    id="outlined-number"
                    label="PAN Number"
                    variant="outlined"
                    data-test-id="businessName"
                    value={this.state.business_Licence}
                    onChange={this.handleBusinessLicence}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    autoComplete="off"
                    placeholder={configJSON.businessLicense}
                />
                {this.state.business_LicenceError !== "" && (
                    <ErrorTag>{this.state.business_LicenceError}</ErrorTag>
                )}
            </Box>
        )
    };

    renderSubCategoryError = () => {
        if (this.state.selectSubCategoryError !== "") {
            return <ErrorTag>{this.state.selectSubCategoryError}</ErrorTag>
        }
    };

    renderCategoryError = () => {
        if (this.state.selectCategoryError !== "") {
            return <ErrorTag>{this.state.selectCategoryError}</ErrorTag>
        }
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { activeStep } = this.state;
        const steps = this.getSteps();  
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <section className={classes.sectionSellerSignUp}>
                    <Box className={classes.SignupBgWrapper}>
                        <Box className={'overlayImg'}>
                            <img src={twoWave} alt="" />
                        </Box>
                        <Box className={classes.formWrapper}>
                            <ParentGrid container spacing={2} style={{ maxWidth: '1440px', margin: 'auto' }}>
                                <Grid item lg={5} md={6} sm={12} xs={12}>
                                    <Box className={'leftColumn'}>
                                        <img src={App_Logo} alt="" />
                                        <Typography variant="h6" component={'h6'}>{'Live shopping & market place'}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={7} md={6} sm={12} xs={12}>
                                    <Box className={'rightColumn'}>
                                        <div className={classes.root}>
                                            <CustomStepper activeStep={activeStep} alternativeLabel>
                                                {steps.map((item, index) => (
                                                    <Step key={item.label} completed={item.complete && index !== activeStep} disabled={!item.complete && index !== activeStep}>
                                                        <StepLabel>{item.label}</StepLabel>
                                                    </Step>
                                                ))}
                                            </CustomStepper>

                                        </div>
                                        <Box className={'rightColumnInner'}>
                                            {activeStep === 0 ?
                                                this.renderBankingDetails()
                                                :
                                                this.activeStep1()
                                            }

                                            <Box className={classes.submitBtn}>
                                                <Button variant="contained"
                                                    data-test-id="next-btn"
                                                    onClick={activeStep === 0 ? this.validateBankDetails : this.activeStep1Button()}
                                                >
                                                    Next
                                                </Button>
                                                <SkipButton data-test-id="skip-btn" onClick={this.handleNext}>Skip</SkipButton>
                                            </Box>

                                        </Box>
                                    </Box>
                                </Grid>
                            </ParentGrid>
                            <LoginFailModalWeb
                                displayPopup={this.state.showAlert}
                                closeErrorPopup={this.closeAlert}
                                titleMessage={'Error'}
                                errorMessage={this.generateErrorMessage()} />
                        </Box>
                    </Box>
                </section>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const selectStylePaper = {
    backgroundColor: 'black',
    border: '2px solid rgba(255, 255, 255, 0.50)',
    borderRadius: '0px 0px 12px 12px',
    margin: '-14px 0px',
    borderTop: 'none',
    "& .MuiSvgIcon-root":{
        fill:"white"
    }
};
const styles = (theme: any) => ({
    accountTypeLabel:{
        display: "none",
        color: "white",
        opacity: 0.5
    },
    uploadTxt:{
        textDecoration: 'underline', fontWeight: 700 
    },
    menuItems: {
        backgroundColor: '#000000',
        borderBottom: '1px solid rgba(255, 255, 255, 0.10)',
        color: '#ffffff',
        opacity: 1,
        "& .MuiSelect-select":{
            opacity : 1
        },
        fontSize:"14px"
    },
    sectionTitle: {
        fontSize: '2em  !important',
        fontWeight: 600,
        lineHeight: 'normal',
    },
    sectionSellerSignUp: {
        width: '100%',
        height: '100%',
        color: '#ffffff !important',
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            fontSize: '15px',
            lineHight: '18px',
            color: 'rgba(255, 255, 255) !important',
            padding: '0 5px',
            backgroundColor: 'black',
        },
        '& .MuiCheckbox-colorPrimary': {
            color: "#ffffff",
            height: 'max-content',
        },
        '& .MuiCheckbox-colorPrimary.Mui-checked': {
            color: 'yellow',
        },
    },
    SignupBgWrapper: {
        width: '100%',
        minHeight: '100vh',
        backgroundColor: '#000000',
        position: 'relative' as const,
        '& .overlayImg': {
            position: 'absolute',
            width: '100%',
            bottom: '0',
            left: '0',
            '& img': {
                objectFit: 'cover',
                width: '100%',
            },
        },
    },
    formWrapper: {
        padding: '100px',
        [theme.breakpoints.down('sm')]: {
            padding: '20px',
        },
        '& .leftColumn': {
            marginTop: '56%',
            '& h6': {
                fontFamily: 'Poppins-Medium',
                color: '#ffffff',
                fontSize: '20px',
                lineHeight: '30px',
            },
            '& img': {
                maxWidth: '156px',
                lineHeight: '62px',
            },
            [theme.breakpoints.down('sm')]: {
                marginTop: '0%',
            },
        },
        '& .rightColumn': {
            minHeight: '600px',
            maxWidth: 660,
            border: "1px solid rgba(255, 255, 255, 0.5)",
            borderRadius: '20px',
            padding: '30px 30px 15px 30px',
            [theme.breakpoints.down('sm')]: {
                padding: '20px',
                margin: 'auto',
                marginBottom: '150px',
                marginTop: '50px',
            },
            [theme.breakpoints.down('xs')]: {
                padding: '10px',
            },

            '& .rightColumnInner': {
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                textAlign: 'center',
                gridGap: '40px',
                alignItems: 'center',
                '& .formHeading': {
                    '& p': {
                        fontFamily: 'Poppins-Regular',
                        fontSize: '18px',
                        lineHeight: '27px',
                        opacity: '0.5',
                    },
                },
                '& h6': {
                    fontFamily: 'Poppins-SemiBold',
                    fontSize: '32px',
                    lineHieght: '48px',
                },
                '& h6, & p': {
                    color: 'white',
                },
                '& .signupFormWrapper': {
                    display: 'flex',
                    width: '100%',
                    gridGap: '30px',
                    flexDirection: 'column',
                    '& .MuiTextField-root': {
                        width: '100%',
                    },
                    '& input': {
                        color: '#ffffff',
                    },
                    '& .MuiInputLabel-outlined': {
                        color: '#ffffff',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        color: '#ffffff',
                        borderColor: 'rgba(255, 255, 255, 0.5)',
                        borderRadius: '12px',
                    },
                    '& .BusinessLable': {
                        color: '#ffffff',
                        maxWidth: 'max-content',
                        background: 'black',
                        height: '18px',
                        marginLeft: '16px',
                        marginBottom: '-12px',
                        zIndex: 999,
                        position: 'relative',
                        padding: '0 3px',
                        fontSize: '12px',
                    },
                    '& .MuiInputBase-input::placeholder': {
                        color: '#ffffff',
                        opacity: 0.5,
                        fontSize: '14px'
                    },
                    '& .MuiInputBase-input': {
                        '&:focus': {
                            caretColor: 'white',
                        },
                    },
                    '& .MuiSelect-select': {
                        color: '#ffffff',
                        textAlign: 'left',
                        opacity :  0.5
                    },
                    '& .MuiInputBase-root': {
                        width: '100%',
                    },
                    '& .BusinessSelect': {
                        '& svg': {
                            color: "white",
                        },
                        "&::-webkit-scrollbar": {
                            height: "6px",
                        },
                        "&::-webkit-scrollbar-track": {
                            borderRadius: "10px",
                            background: "#D9D9D955"
                        },
                        "&::-webkit-scrollbar-thumb": {
                            background: "#D9D9D9",
                            borderRadius: "10px",
                        },
                    },
                    '& .customShrink': {
                        position: 'absolute',
                        fontSize: '16px',
                        color: 'rgba(255, 255, 255)',
                        transform: "translate(14px, -6px) scale(0.75)",
                        height: '14px',
                        backgroundColor: '#000000',
                        padding: '0 5px',
                        zIndex: '9',
                        '& .MuiInputBase-input::placeholder': {
                            color: '#ffffff',
                            opacity: 0.5,
                          },
                    },
                    '& .inputFeild': {
                        width: '100%',
                        '& .MuiFormLabel-root': {
                            fontFamily: 'Poppins-Regular !important',
                            fontSize: '16px',
                            lineHeight: '18px',
                            color: "#ffffff"
                        },
                        '& .MuiInputBase-input::placeholder': {
                            color: '#ffffff',
                            opacity: 0.5,
                          },
                        '& .BusinessLable.textWhite': {
                            fontSize: '10px',
                            lineHeight: '15px',
                            color: "#ffffff"
                        },
                    },
                    "& .radioButtonBox":{
                        width: '100%',
                        display:"flex",
                        gap: "32px",
                        marginLeft:"4px",
                        alignItems:"center"
                    },
                    "& .gstText":{
                        color: "#ffffff"
                    },
                    "& .customRadio":{
                        color: '#F5EA16' 
                    },
                    "& .selectActive":{
                        "& .MuiSvgIcon-root":{
                            fill:"white"
                        },
                        "& .MuiSelect-select":{
                            opacity : 1,
                            fontSize:"14px"
                        }
                    },
                    "& .selectActiveNot": {
                        "& .MuiSvgIcon-root": {
                            fill: "white"
                        },
                        "& .MuiSelect-select": {
                            opacity: 0.5,
                            fontSize: "14px"
                        }
                    },
                    "& .gstTypeSelect":{
                        "& .MuiSvgIcon-root":{
                            fill:"white"
                        },
                        "& .MuiSelect-select":{
                            opacity : 1,
                            fontSize:"14px"
                        }
                    },
                    "& .placeholderStyle":{
                            position: "absolute",
                            fontSize: "14px",
                            width: "100%",
                            left: "17px",
                            display: "flex",
                            alignItems: "center",
                            lineHeight: "57px",
                            opacity: 0.5
                    }
                },
            },
        },
    },
    customCheckBox: {
        '& .MuiSvgIcon-root': {
            lienHeight: '18px',
            fontSize: '1rem',
        },
        "& .Mui-checked.MuiCheckbox-root": {
            padding: '0px !important',
            color: "#F5EA16 !important",
        },
        "& .MuiCheckbox-root": {
            padding: '0px !important',
            color: "white !important",
        },
    },
    countryCodeFeild: {
        '& .countryCodeSelect': {
            height: '52px',
            width: '129px',
            borderRadius: 10,
            border: '1px solid rgba(255, 255, 255, 0.50)',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        position: 'relative' as const,
        '& .countryCodeLable': {
            color: 'rgba(255, 255, 255, 0.30)',
            backgroundColor: '#000000',
            position: 'absolute',
            fontSize: "12px",
            left: "15px",
            top: "-8px",
            padding: '0px 5px',
            display: 'inline-block',
        },
    },
    submitBtn: {
        marginBittom: '25px',
        width: "100%",
        marginTop: "-30px",
        '& .MuiButton-contained': {
            width: '100%',
            height: '56px',
            backgroundColor: '#F5EA16',
            borderRadius: '24px',
            fontSize: '16px',
            lineHeight: '24px',
            fontFamily: 'Poppins-Medium',
            color: '#444444',
            textTransform: 'capitalize',
        },
    },
    horizontalLine: {
        width: '100%',
        height: '1px',
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        '& p': {
            textAlign: 'center',
            backgroundColor: 'black',
            marginTop: '-9px',
            width: 'max-content',
            margin: 'auto',
            padding: '0px 7px',
            fontFamily: 'Poppins-Regular',
            fontSize: '12px',
            lineHieght: '18px',
            color: 'rgba(255, 255, 255, 0.5)',
        }
    },
    passwordError: {
        fontSize: "12px",
        textAlign: 'left' as const,
        marginTop: "-27px",
        marginLeft: "14px",
        color: 'red',
    }

});
const ParentGrid = styled(Grid)({
    '@media(max-width: 900px)': {
        width: '100%'
    }
});
const CustomLinearProgress = styled(LinearProgress)({
    height: 7, borderRadius: 20, marginTop: 31,
    '& .MuiLinearProgress-barColorPrimary': {
        backgroundColor: '#F5EA16 !important',
    }
});
const CustomFormControl = styled(FormControl)({
    width: '100%',
    '& .MuiFormLabel-root': {
        color: 'white'
    },
    '& .MuiSelect-icon': {
        color: 'white',
    },
    '& .MuiSelect-outlined.MuiSelect-outlined': {
        textAlign: 'start',
        color: 'white',
        borderRadius: 4,
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'unset'
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'unset'
    },
    '& .Mui-Focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(255, 255, 255, 0.5) !important'
    }
});

const CustomRadioGroup = styled(RadioGroup)({
    flexDirection: 'unset',
    '& .MuiRadio-colorSecondary.Mui-checked svg': {
        fill: 'yellow'
    },
    '& .MuiIconButton-label svg': {
        fill: 'white'
    }
});
const CustomStepper = styled(Stepper)({
    background: 'transparent',
    alignItems: 'center !important',
    '@media(max-width: 1399px)': {
        transform: 'scale(1.2)',
    },
    '@media(min-width: 1400px)': {
        paddingInline: 0,
        justifyContent: 'space-between',
        '& .MuiStep-alternativeLabel': {
            flex: 'unset'
        },
    },
    '& .MuiStepLabel-active': {
        display: 'none'
    },
    '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
        display: 'none'
    },
    '& .MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel': {
        border: '1px dashed #F5EA167A',
        borderRadius: '50%',
        padding: 5,
    },
    '& .MuiStepLabel-root.Mui-disabled .MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel': {
        border: 'none',
        padding: 12,
    },
    '& .MuiStep-completed .MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel': {
        display: 'none',
    },
    '& .MuiStep-completed .MuiStepLabel-alternativeLabel': {
        border: 'none',
        padding: '0px',
        '& .MuiStepIcon-root.MuiStepIcon-completed': {
            display: 'none'
        },
        '& .MuiStepLabel-completed': {
            background: '#F5EA16',
            width: '55px',
            height: '55px',
            margin: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            fontSize: '27px',
            '@media(max-width: 600px)': {
                width: '30px',
                height: '30px',
                fontSize: '14px',
            }
        },
    },
    '& .MuiStepIcon-root.MuiStepIcon-active': {
        color: '#F5EA16',
        fontSize: 55,
        border: '1px dashed',
        borderRadius: '50%',
        padding: 5,
        '& .MuiStepIcon-text': {
            fill: 'black'
        },
        '@media(max-width: 600px)': {
            fontSize: 30,
        }
    },
    '& .MuiStepIcon-root': {
        color: '#55555566',
        fontSize: 55,
        '& .MuiStepIcon-text': {
            fill: 'white'
        },
        '@media(max-width: 600px)': {
            fontSize: 30,
        }
    },
    '& .MuiStepConnector-completed': {
        top: '29px !important',
    },
    '& .MuiStepConnector-active': {
        top: '34px !important',
    },
    '& .MuiStepConnector-alternativeLabel': {
        top: '40px',
        left: 'calc(-50% + 40px)',
        right: 'calc(50% + 40px)',
        position: 'absolute',
        '@media(min-width: 1400px)': {
            left: 'calc(-50% + -124px)'
        },
        '@media(max-width: 600px)': {
            top: 25,
            left: 'calc(-50% + 27px)',
            right: 'calc(50% + 27px)',
        }
    },
    '& .MuiStepConnector-alternativeLabel.MuiStepConnector-completed': {
        top: '40px',
        left: 'calc(-50% + 40px)',
        right: 'calc(50% + 40px)',
        position: 'absolute',
        '@media(min-width: 1400px)': {
            left: 'calc(-50% + -140px)'
        },
        '@media(max-width: 600px)': {
            top: 25,
            left: 'calc(-50% + 27px)',
            right: 'calc(50% + 27px)',
        }
    },
    '& .MuiStepConnector-alternativeLabel.MuiStepConnector-active': {
        top: '40px',
        left: 'calc(-50% + 40px)',
        right: 'calc(50% + 40px)',
        position: 'absolute',
        '@media(min-width: 1400px)': {
            left: 'calc(-50% + -140px)'
        },
        '@media(max-width: 600px)': {
            top: 25,
            left: 'calc(-50% + 27px)',
            right: 'calc(50% + 27px)',
        }
    },

});
const ErrorTag = styled(Typography)({
    fontSize: 12,
    color: 'red !important',
    textAlign: 'left' as const
});
const SkipButton = styled('p')({
    "border-bottom": "1px solid #555555",
    "display": "inline-block",
    "cursor": "pointer",
    "z-index": 11111,
    "position": "relative",
    color: "#555555"
})
const OverlayBoxBD = styled(Box)({
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'rgba(0, 0, 0, 0.50)',
    filter: 'blur(13px)',
    width: '100vw',
    height: '100vh',
});
const AlertBoxBD = styled(Alert)({
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'white',
    textAlign: 'center',
    borderRadius: 20,
    maxWidth: 300,
    zIndex: 100,
    minWidth: "200px",
    '& .MuiSvgIcon-root': {
        position: 'absolute',
        top: '10px',
        right: '10px'
    },
    '& .MuiAlert-message': {
        width: '100%'
    },
    '& .MuiAlert-icon': {
        display: 'none'
    },
    "& .MuiButton-containedPrimary:hover": {
        backgroundColor: "#8080805e"
    }
});
const AlertHeadText = styled(Box)({
    color: '#000',
    textAlign: 'center',
    fontFamily: 'Poppins-Regular, sans-serif',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    borderBottom: '1px solid #E9E9E9',
    paddingBottom: '20px',
    maxWidth: '200px',
    margin: 'auto',
});
const AlertTextBD = styled(Box)({
    color: '#000',
    textAlign: 'center',
    fontFamily: 'Poppins-Regular, sans-serif',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    paddingTop: '20px'
})

export default withStyles(styles)(BankingDetails);
export { BankingDetails }
// Customizable Area End
