Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Settings";
exports.labelBodyText = "Settings Body";
exports.sellerPolicyEndPoint ="privacy_policy?policy_for=seller"
exports.sellerTermsEndPoint = "terms_and_condition?condition_for=seller"
exports.notificationsSettingsEndpoint = "bx_block_settings/notifications_settings"
exports.getNotificationSettingEndPoint = "bx_block_settings/notifications_settings"
exports.getSellerSupportEndpoint = "bx_block_settings/get_seller_support"

exports.btnExampleTitle = "CLICK ME";
exports.settings = "Settings"
exports.audio = "Audio"
exports.inputDevice = "Input Device"
exports.outputDevice = "Output Device"
exports.inputVolume = "Input Volume"
exports.outputVolume = "Output Volume"
exports.micTest = "Mic Test"
exports.micTestDescriptionText = "Having mic issues? Start a test and say something fun- we’ll play your voice back to you"
exports.letsCheck = "Lets Check"
exports.video = "Video"
exports.inputDevice = "Input Device"
exports.testVideo = "Test Video"
exports.videoTest = "Video Test"
exports.support = "Support"
exports.notifications = "Notifications"
exports.allNotification = "Enable/Disable  all notification"
exports.emailNotification = "Enable/Disable  Email notification"
exports.pushNotification = "Enable/Disable  Push notification"
exports.privacyPolicy = "Privacy Policy"
exports.readTandC ="Read our Terms, Conditions & Privacy Policy"
exports.policy = "Policy"
exports.tAndC = "Terms & Conditions"
exports.logoutAccount = "Log out account"
exports.logoutHelperText = "Make sure you've saved everything important before logging out."
exports.logout= "Log Out"

exports.audioInputDropdown = [
  {
    "id": "1",
    "type": "Built-in Microphone"
  },
  {
    "id": "2",
    "type": "Headphones"
  },
  {
    "id": "3",
    "type": "External Speakers"
  }
]

exports.audioOutputDropdown = [
  {
    "id": "1",
    "type": "Built-in Speaker"
  },
  {
    "id": "headphones002",
    "type": "Headphones"
  },
  {
    "id": "bluetooth003",
    "type": "Bluetooth Speakers"
  }
]
exports.videoDropdown = [
  {
    "id": "1",
    "type": "Default Device Display"
  },
  {
    "id": "2",
    "type": "External Monitor"
  },
  {
    "id": "3",
    "type": "Smart TV"
  }
]

// Customizable Area End