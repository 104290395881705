import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Dialog,
  TextField,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { plusIcon, crossIcon, locationIcon, deleteIcon, clockIcon, closeIcon, tickIcon, statusRejectIcon, statusCheckIcon, linkPlus,editIcon } from "./assets"
import Autocomplete from '@material-ui/lab/Autocomplete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import MessageModalWeb from "../../../components/src/MessageModal.web";
import Loader from "../../../components/src/Loader.web";

import {
  AddressObject,
  LinkedStore,
  PendingStoreAddress,
  SellersList,
  StoreAddress
} from "./StoreLocatorController";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

export const YellowCheckbox = withStyles({
  root: {
      '&$checked': {
          color: "yellow",
      },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

// Customizable Area End

import StoreLocatorController, {
  Props,
  configJSON,
} from "./StoreLocatorController";

export class StoreLocator extends StoreLocatorController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  countryError() {
    return (this.state.countryErrorMsg !== "" && (
      <Box className="red-text" data-test-id="countryErrorBox">{this.state.countryErrorMsg}</Box>
    ))
  }
  stateError() {
    return (this.state.stateErrorMsg !== "" && (
      <Box className="red-text" data-test-id="stateErrorBox">{this.state.stateErrorMsg}</Box>
    ))
  }

  cityError() {
    return (this.state.cityErrorMsg !== "" && (
      <Box className="red-text" data-test-id="cityErrorBox">{this.state.cityErrorMsg}</Box>
    ))
  }

  renderLinkedStoreStatus = (status:string):{
    linkedStoreStatus: string;
    icon: JSX.Element;
  } => {
    switch(status){
      case "pending":
        return{
          linkedStoreStatus: "linkedStorePending",
          icon: <img src={clockIcon} alt="status" />
        }
        case "accepted":
        return{
          linkedStoreStatus: "linkedStoreActive",
          icon: <img src={tickIcon} alt="status" />
        }
        default:
          return{
            linkedStoreStatus: "linkedStoreRejected",
            icon: <img src={closeIcon} alt="status" />
          }
    }
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props
    const sellerList = this.handleSearchSellerList();
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <>
        <Loader loading={this.state.loading} />
        <MessageModalWeb
            displayPopup={this.state.isPopupVisible}
            closeErrorPopup={this.closePopup}
            errorMessage={this.state.popupMessage} 
            data-test-id="msgModalID"
            />
          <Box className={classes.storeAddressContainer} data-test-id="mainContainer">
            <Typography className={classes.storeAddressTitle}>
              {configJSON.storeAddress}
            </Typography>
            {this.state.addressDetails.length>0 ?
          <Box className="linkedStoreAddressList">
          {
             this.state.addressDetails.map((storeAddress:StoreAddress)=> 
              <Box className={classes.addressContainer}>
             <Box className={classes.textContainer}>
               <Typography className={classes.addresslines}>{storeAddress.address_line_1}</Typography>
               <Typography className={classes.addresslines}>{storeAddress.address_line_2}</Typography>
               <Typography className={classes.addresslines}>
                 {storeAddress.city}, {storeAddress.state}, {storeAddress.country} - {storeAddress.pin_code}
               </Typography>
             </Box>
             <Box className={classes.iconContainer}>
               <img
                 className={classes.addressIcons}
                 src={editIcon}
                 alt="edit"
                 onClick={this.handleStoreAdress}
                 data-test-id="editIconID"
               />
               <img
                 className={classes.addressIcons}
                 src={deleteIcon}
                 alt="delete"
                 onClick={() => this.handleDeleteCurrentAddress(storeAddress.id)}
                 data-test-id="deleteIconID"
               />
             </Box>
           </Box>
           
            )
          }
        </Box>:  
          
            <Box className={classes.storeAddressInnerContainer}>
              <img src={plusIcon} alt="plusIcon" onClick={this.handleStoreAdress} data-test-id="plusIcon"/>
              <Typography className={classes.storeAddressHelperText}>
                {configJSON.helperTextForstoreAddress}
              </Typography>
            </Box> }
            <Box className={classes.linkAddressTop}>
              <Box className="linkAddressTopLeft">
                <Typography className={classes.storeAddressTitle}>
                  {configJSON.linkAddress}
                </Typography>
                <Button onClick={this.handleStatusModalOpen} data-test-id="requestsBtnID">{configJSON.requests}</Button>
              </Box>
            <img src={linkPlus} alt="plus" onClick={this.handleOpenLinkStoreModal} data-test-id="linkStorePlusImgID" />
            </Box>
            <Box className={classes.linkedStoreInnerContainer}>
              {
                
                this.state.linkedStores.length > 0 ?
                <Box component={"ul"} className="linkedStoresList">
                  {
                    this.state.linkedStores.map((linkedStore: AddressObject)=> {
                      const {icon, linkedStoreStatus} = this.renderLinkedStoreStatus(linkedStore.linked_address_details.status);
                      return <Box key={linkedStore.linked_address_details.id} component={"li"}>
                      <Box className="linkedStoreContent">
                        <Typography className="linkedStoreNameStatus">
                          <span className="linkedStoreName" data-test-id="linkedToTxtID">{linkedStore.linked_address_details.linked_store_name}</span>
                          <span className={`${linkedStoreStatus} linkedStoreStatus`}>{icon} {linkedStore.linked_address_details.status}</span>
                        </Typography>
                        
                        {
                          linkedStore.linked_address_details.status === "pending" ? <Typography className="pendingTxt">
                            <em>{configJSON.pendingTxt}</em>
                          </Typography> :
                        <Box className="linkedStoreAddressList" component={"ul"}>
                          {
                            linkedStore.store_address.map((storeAddress:StoreAddress)=> <Box component={"li"}>
                              <Typography>{storeAddress.address_line_1} , {storeAddress.city} , {storeAddress.state} , {storeAddress.country} , {storeAddress.pin_code}</Typography>
                            </Box>)
                          }
                        </Box>
                        }
                        </Box>
                        <img src={deleteIcon} alt="delete" onClick={()=> this.handleDeleteModalOpen(linkedStore.linked_address_details.id)} data-test-id="deleteIconID" />
                    </Box>
                    }
                  )                  
                    }
                  </Box>
                :
                  <Box className="noLinkedStore">
                    <img src={plusIcon} alt="plusIcon" onClick={this.handleOpenLinkStoreModal} data-test-id="plusIcon" />
                    <Typography className="noLinkedStoreDesc">
                      {configJSON.helperTextForstoreAddress}
                    </Typography>
                  </Box>
              }
            </Box>
          </Box>
          <Dialog
            data-test-id="deleteModalID"
            open={this.state.deleteModal}
            onClose={this.handleDeleteModalClose}
          >
            <Box className={classes.deleteModal}>
              <Box className={classes.statusModalTitleContainer}>
                <Typography className={classes.deleteModalTitle}>{configJSON.deleteModalTitle}</Typography>
                <img src={crossIcon} alt="Close" onClick={this.handleDeleteModalClose} />
              </Box>
              <Box>
                <Typography className={classes.deleteModalDesc}>{configJSON.deleteModalDesc}</Typography>
                <Box className={classes.deleteModalBtns}>
                  <Button className="deleteModalCancelBtn" variant="text" onClick={this.handleDeleteModalClose} data-test-id="cancelBtnID" >{configJSON.cancel}</Button>
                  <Button className="deleteModalDeleteBtn" onClick={this.deleteLinkedStore} data-test-id="deleteBtnID">{configJSON.delete}</Button>
                </Box>
              </Box>
            </Box>
          </Dialog>
          <Dialog
            data-test-id="deleteModalID"
            open={this.state.deleteAddressModal}
            onClose={this.handleDeleteCurrentAddressClose}
          >
            <Box className={classes.deleteModal}>
              <Box className={classes.statusModalTitleContainer}>
                <Typography className={classes.deleteModalTitle}>{configJSON.deleteAddressTitle}</Typography>
                <img src={crossIcon} alt="Close" onClick={this.handleDeleteCurrentAddressClose} />
              </Box>
              <Box>
                <Typography className={classes.deleteModalDesc}>{configJSON.deleteAddressDesc}</Typography>
                <Box className={classes.deleteModalBtns}>
                  <Button className="deleteModalCancelBtn" variant="text" onClick={this.handleDeleteCurrentAddressClose} data-test-id="cancelBtnID" >{configJSON.cancel}</Button>
                  <Button className="deleteModalDeleteBtn" onClick={this.deleteCurrentStoreAddress} data-test-id="deleteBtnID">{configJSON.delete}</Button>
                </Box>
              </Box>
            </Box>
          </Dialog>
          <Dialog
            data-test-id="statusModal"
            open={this.state.statusModal}
            onClose={this.handleStatusModalClose}
          >
            <Box className={classes.statusModal}>
              <Box className={classes.statusModalTitleContainer}>
                <Typography className={classes.addStoreAddressTitle}>{configJSON.statusModalTitle}</Typography>
                <img src={crossIcon} alt="Close" onClick={this.handleStatusModalClose} data-test-id="closeIconID" />
              </Box>
              <Box>
                <Box className={classes.statusModalList} component={"ul"}>
                  {
                    this.state.statusModalStoreDetails?.map((storeDetail:LinkedStore)=> {
                      return <Box component={"li"}>
                        <Box className="statusModalStoreDetails">
                          <Typography className="statusModalStoreName" data-test-id="storeNameID">{storeDetail.linked_address_details.linked_store_name}</Typography>
                          <Box className="storeModalStoreAddressList" component={"ul"}>
                            {
                              storeDetail.store_address.map((storeAddress: PendingStoreAddress) => <Box component={"li"}>
                                <Typography className="storeModalStoreAddress">{storeAddress.address_line_1} , {storeAddress.city} , {storeAddress.state} , {storeAddress.country} , {storeAddress.pin_code}</Typography>
                              </Box>)
                            }
                          </Box>
                          <Typography>{configJSON.comment}: {this.renderComment(storeDetail.linked_address_details.comments)}</Typography>
                        </Box>
                        <Box className="statusActions">
                          <img src={statusCheckIcon} alt="status check" onClick={()=> this.postAcceptRequest(storeDetail.linked_address_details.id)} data-test-id="statusAcceptID" />
                          <img src={statusRejectIcon} alt="status reject" onClick={()=> this.postRejectRequest(storeDetail.linked_address_details.id)} data-test-id="statusRejectID" />
                        </Box>
                      </Box>
                    })
                  }
                </Box>
              </Box>
            </Box>
          </Dialog>
          <Dialog
            data-test-id="linkStoreModal"
            open={this.state.linkStoreModal}
            onClose={this.handleCloseLinkStoreModal}
          >
            <Box className={classes.addStoreAddressDailog}>
              <Box className={classes.addStoreAddressTitleContainer}>
                <Typography className={classes.addStoreAddressTitle}>{configJSON.linkStoresModalTitle}</Typography>
                <img src={crossIcon} alt="Close" onClick={this.handleCloseLinkStoreModal} />
              </Box>
              <Box>
                <div className={`${classes.textField} custom-form-group ${classes.sellerContainer}`} ref={this.dropdownRef}>
                  <TextField
                    fullWidth
                    label={configJSON.searchSeller}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    value={this.state.searchSeller}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleTextFieldChange(event, 'searchSeller')}
                    onFocus={this.onFocusHandler}
                    className="custom-outline-form-control"
                    data-test-id="searchSeller"
                    name="name"
                  />
                  {
                    this.state.nameError &&  <Typography className="errorStyle">{configJSON.noSellerFound}</Typography>
                  }
                  {
                    Boolean(this.state.searchSeller && this.state.isSellerListOpen && sellerList.length) &&
                    <Box className="sellerWrapper">
                          <Box component={"ul"}>
                            {
                              sellerList.map((seller: SellersList) => <li key={seller.id} onClick={() => this.handleClickedStore(seller.business_name, seller.email)}  data-test-id="sellerListItemID">{`${seller.business_name} (${seller.user_name})`}</li>)
                            }
                          </Box>
                    </Box>
                  }
                </div>
                <Box className={`${classes.textField} custom-form-group`}>
                  <TextField
                    fullWidth
                    label={configJSON.email}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    value={this.state.sellerEmail}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleTextFieldChange(event, "sellerEmail")}
                    className="custom-outline-form-control"
                    data-test-id="email"
                    name="email"
                    disabled
                  />
                </Box>
                <Box className={`${classes.textField} custom-form-group`}>
                  <TextField
                    fullWidth
                    label={configJSON.comment}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    value={this.state.comment}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleTextFieldChange(event, 'comment')}
                    className="custom-outline-form-control"
                    data-test-id="comment"
                    name="comment"
                    multiline
                    minRows={3}
                  />
                </Box>
                <Button data-test-id="addBtnID" className={classes.addButton} onClick={this.postLinkStore}>{configJSON.addButton}</Button>
              </Box>
            </Box>
          </Dialog>
          <Dialog
            data-test-id="openAddStoreAddress"
            open={this.state.openAddStoreAddress}
            onClose={() => this.handleCloseStoreAddressModal()}
          >
            <Box className={classes.addStoreAddressDailog}>
              <Box className={classes.addStoreAddressTitleContainer}>
                <Typography className={classes.addStoreAddressTitle}>{configJSON.addStoreAddress}</Typography>
                <img src={crossIcon} alt="Close" onClick={() => this.handleCloseStoreAddressModal()} />
              </Box>
              <Box className={classes.detectLocation}>
                <img src={locationIcon} alt="locationIcon" />
                <Typography className={classes.detectLocationText}>{configJSON.detectLocation}</Typography>
              </Box>
              <Box>
                <Box className={`${classes.textField} custom-form-group`}>
                  <TextField
                    fullWidth
                    label={configJSON.addressLineOne}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    value={this.state.addressOne}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleTextFieldChange(e, 'addressOne')}
                    className="custom-outline-form-control"
                    data-test-id="addressOne"
                  />
                </Box>
                <Box className={`${classes.textField} custom-form-group`}>
                  <TextField
                    fullWidth
                    label={configJSON.addressLineTwo}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    value={this.state.addressTwo}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleTextFieldChange(e, 'addressTwo')}
                    className="custom-outline-form-control"
                    data-test-id="addressTwo"
                  />
                </Box>
                <Box className={`${classes.textField} custom-form-group`}>
                  <TextField
                    fullWidth
                    type="number"
                    label={configJSON.pincodeLabel}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    value={this.state.storeAddressPincode}
                    onChange={this.handleChangePickUpPincode}
                    className="custom-outline-form-control"
                    data-test-id="pincode"
                  />
                </Box>
                <Box className={`${classes.textField} custom-form-group`}>
                  <Autocomplete
                    id="combo-box-demo"
                    options={this.state.countryList.map((item:{name:string}) => item.name)}
                    getOptionLabel={(option:string) => option}
                    onBlur={this.ValidateCountry}
                    onChange={this.handleCountryChange}
                    value={this.state.selectedCountry}
                    data-test-id="country"
                    className="auto-complete-list"
                    renderInput={(params: {InputProps:{}}) => (
                      <TextField
                        className="custom-outline-form-control"
                        {...params}
                        placeholder="Country"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <ExpandMoreIcon className={classes.dropDown} />
                          ),
                        }}
                      />)}
                  />
                  {this.countryError()}
                </Box>

                <Box className={`${classes.textField} custom-form-group`}>
                  <Autocomplete
                    id="combo-box-demo"
                    options={this.state.stateList?.map((item: {name:string}) => item.name)}
                    getOptionLabel={(option: string) => option}
                    onBlur={this.ValidateState}
                    value={this.state.selectedState}
                    className={`${classes.autoComplete} auto-complete-list`}
                    data-test-id="state"
                    disabled={this.state.stateList.length === 0}
                    onChange={this.handleStateChange}
                    renderInput={(params: {InputProps:{}}) => (
                      <TextField
                        {...params}
                        className="custom-outline-form-control"
                        placeholder="State"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <ExpandMoreIcon className={classes.dropDown} />
                          ),
                        }}
                      />)}
                  />
                  {this.stateError()}
                </Box>

                <Box className={`${classes.textField} custom-form-group`}>
                  <Autocomplete
                    id="combo-box-demo"
                    options={this.state.cityList}
                    getOptionLabel={(option: string) => option}
                    onBlur={this.ValidateCity}
                    onChange={this.handleCityChange}
                    value={this.state.selectedCity}
                    data-test-id="city"
                    className={`${classes.autoComplete} auto-complete-list`}
                    disabled={this.state.cityList.length === 0}
                    renderInput={(params: {InputProps:{}}) => (
                      <TextField
                        {...params}
                        className="custom-outline-form-control"
                        placeholder="City"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <ExpandMoreIcon className={classes.dropDown} />
                          ),
                        }}
                      />)}
                  />
                  {this.cityError()}

                </Box>
                <Box className={`${classes.textField} custom-form-group`}>
                  <TextField
                    name="longitudinal"
                    fullWidth
                    data-test-id="longitudinal"
                    label={configJSON.longitudinal}
                    variant="outlined"
                    value={this.state.longitudinal}
                    onChange={this.handleLatAndLongChange}
                    className="custom-outline-form-control"
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
                <Box className={`${classes.textField} custom-form-group`}>
                  <TextField
                    name="latitudinal"
                    fullWidth
                    data-test-id="latitudinal"
                    label={configJSON.latitudinal}
                    variant="outlined"
                    value={this.state.latitudinal}
                    onChange={this.handleLatAndLongChange}
                    className="custom-outline-form-control"
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
                <Box className={classes.franchiseContainer}>
                  <YellowCheckbox
                    checked={false}
                    disabled={true}
                  />
                  <Typography className={classes.storeFranchise}>{configJSON.storeFranchise}</Typography>
                </Box>
                {this.state.addressformError && (
                  <Typography
                    color="error"
                    variant="body2"
                    className={classes.errorText}
                    data-test-id="addressFormError"
                  >
                    {this.state.addressformError}
                  </Typography>
                )}
                <Button data-test-id="add" className={classes.addButton} onClick={this.postAddStoreAddress}>Add Address</Button>
              </Box>

            </Box>

          </Dialog>
        </>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  storeAddressContainer: {
    marginTop: "1em"
  },
  linkAddressTop:{
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .linkAddressTopLeft":{
      display: "flex",
      alignItems: "center",
      gap: "12px",
      "& button":{
        backgroundColor: "rgba(245, 234, 22, 1)",
        borderRadius: "4px",
        "& .MuiButton-label":{
          textTransform: "capitalize",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "21px",
          color: "rgba(85, 85, 85, 1)"
        }
      }
    },
    "& img":{
      width: "24px",
      height: "24px",
      cursor: "pointer"
    }
  },
  storeAddressTitle: {
    color: '#555555',
    fontFamily: 'Poppins !important',
    fontSize: '1.2em',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '27px',
    margin: "1em 0em"

  },
  storeAddressInnerContainer: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
    height: "19em",
    boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.10)",
    pointer: "cursor"
  },
  linkedStoreInnerContainer: {
    boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.10)",
    padding: "16px",
    paddingRight: 0,
    "& .noLinkedStore":{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& img":{
        cursor: "pointer"
      },
      "& .noLinkedStoreDesc":{
        textAlign: "center",
        color: "#4E4E4E",
        fontSize: "0.9em",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "21px",
        maxWidth: "60%"
      }
    },
    "& .linkedStoresList": {
      margin: 0,
      padding: 0,
      listStyle: "none",
      maxHeight: "360px",
      overflowY: "auto",
      paddingRight: "16px",
      "&::-webkit-scrollbar": {
        width: '3px',
        backgroundColor: '#F5F5F5'  // Corrected background color
      },
      "&::-webkit-scrollbar-track": {
        '-webkit-box-shadow': 'inset 0 0 6px #fff',  // Corrected property
        backgroundColor: '#ffffff00'  // Transparent background
      },
      "&::-webkit-scrollbar-thumb": {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,.3)',  // Corrected property
        backgroundColor: '#ccc'
      },
      "& li":{
        marginBottom: "20px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        gap: "12px",
        "& .linkedStoreContent":{
          flex: 1,
        "& .linkedStoreNameStatus":{
          display: "flex",
          gap: "12px",
          marginBottom: "8px",
        "& .linkedStoreName":{
          color: "rgba(85, 85, 85, 1)",
          fontWeigth: 400,
          fontSize: "16px",
          lineHeight: "21px"
        },
        "& .linkedStoreStatus":{
          height: "18px",
          borderRadius: "4px",
          padding: "0 4px",
          fontWeight: 500,
          fontSize: "12px",
          lineHeight: "18px",
          color: "rgba(85, 85, 85, 0.7)",
          textTransform: "capitalize",
          display: "flex",
          alignItems: "center",
          gap: "4px"
        },
      
        "& .linkedStorePending": {
          backgroundColor: "rgba(255, 214, 0, 0.1)"
        },
        "& .linkedStoreActive": {
          backgroundColor: "rgba(75, 181, 67, 0.1)"
        },
        "& .linkedStoreRejected": {
          backgroundColor: "rgba(255, 0, 0, 0.1)"
        }
      }
    },
    "& img":{
      cursor: "pointer"
    }

      },
      "& .linkedStoreAddressList": {
        margin: 0,
        padding: 0,
        listStyle: "none",
        "& li":{
          maxWidth: "300px"
        }
      },
      "& .pendingTxt":{
        color: "rgba(85, 85, 85, 1)",
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: 400,
        maxWidth: "318px"
      },
    }
  },
  storeAddressHelperText: {
    color: '#4E4E4E',
    fontFamily: 'Poppins !important',
    fontSize: '0.9em',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '21px',
    maxWidth: "60%"
  },
  addStoreAddressDailog: {
    width: "36em",
    padding: "0em 2em",
    borderRadius: "25px",
    "& .errorStyle":{
      color: "tomato",
      fontSize: "14px"
    },
    "& .MuiInputBase-input.Mui-disabled":{
      opacity: "0.4"
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline":{
      backgroundColor: "rgba(0,0,0,0.06)"
    }
  },
  addStoreAddressTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "2em 0em",
    "& img":{
      cursor: "pointer"
    }
  },
  addStoreAddressTitle: {
    color: "#2E2E2E",
    fontFamily: 'Poppins !important',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '32px',
  },
  detectLocation: {
    display: "flex",
    alignItems: "center",
    marginBottom: "2em"
  },
  detectLocationText: {
    color: "#4E4E4E",
    fontFamily: 'Poppins !important',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '21px',
    textDecoration: "underline",

  },
  textFieldStyle: {
    height: "3.5em",
    margin: "0.5em 0em",
    borderRadius: "40px"
  },
  franchiseContainer:{
    display:"none",
    alignItems:"center",
    margin:"1em 0em"
},
storeFranchise:{
  color:"#2E2E2E",
  fontFamily: 'Poppins !important',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '32px',
  display:"none"
},
addButton:{
  width:"100%",
  height: '3em',
  background: '#F5EA16',
  borderRadius:"25px",
  color: '#444',
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: 'Poppins',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '24px',
  pointer:"cursor",
  marginBottom:"2em",
  textTransform:"none" as const,
  cursor: "pointer",
  "&:hover":{
      background: '#F5EA16',
  },
  autoComplete:{
    width: '100%',
    maxWidth: '509px'
  },
  dropDown:{
    height: '32px',
    width: '32px' 
  }

},
sellerContainer:{
  position: "relative" as "relative",
  "& .sellerWrapper":{
    position: "absolute",
    left: 0,
    top: "100%",
    boxShadow: "0 0 10px rgba(0,0,0,0.4)",
    backgroundColor: "#fff",
    zIndex: 2,
    width: "100%",
    padding: "12px",
    maxHeight: "220px",
    overflowY: "auto",
    "& ul":{
      listStyle: "none",
      padding: 0,
      margin: 0,
      "& li":{
        marginBottom: "8px",
        padding: "12px",
      },
      "& li:hover":{
        backgroundColor: "lightgray",
        cursor: "pointer"
      }
    }
  },
},
statusModal:{
  padding: "20px"
},
statusModalList:{
  listStyle: "none",
  padding: 0,
  "& li":{
    display: "flex",
    justifyContent: "space-between",
    gap: "20px",
    "& .statusModalStoreName":{
      color: "rgba(85, 85, 85, 1)",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px"
    },
    "& .storeModalStoreAddressList":{
      listStyle: "none",
      padding: 0,
      display: "flex",
      gap: "8px",
      "& .storeModalStoreAddress":{
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "24px"
      }
    },
    "& .statusActions":{
      display: "flex",
      gap: "8px",
      alignItems: "center",
      "& img":{
        cursor: "pointer"
      }
    }
  }
},
statusModalTitleContainer:{
  display: "flex",
  justifyContent: "space-between",
  "& img":{
    cursor: "pointer"
  }
},
deleteModal:{
  padding: "20px"
},
deleteModalTitle: {
  color: "rgba(85, 85, 85, 1)",
  fontWeight: 700,
  fontSize: "18px",
  lineHeight: "32px"
},
deleteModalDesc: {
  color: "rgba(110, 110, 110, 1)",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "26px",
  margin: "20px 0 30px 0"
},
deleteModalBtns:{
  display: "flex",
  justifyContent: "flex-end",
  gap: "20px",
  "& button":{
    cursor: "pointer"
  },
  "& .MuiButton-label":{
    textTransform: "capitalize",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  "& .deleteModalCancelBtn":{
    "& .MuiButton-label":{
      color: "rgba(46, 46, 46, 1)"
    }
  },
  "& .deleteModalDeleteBtn":{
    backgroundColor: "rgba(228, 98, 98, 1)",
    borderRadius: "8px",
    "& .MuiButton-label":{
      color: "rgba(255, 255, 255, 1)"
    }
  }
},
  addresslines:{
  maxWidth: "470px",
  color: "#555",
  fontSize: "16px",
  fontWeight: 600,
  height: "30px",
  overflow: "hidden",
  marginLeft:"15px",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap" as "nowrap",
  "@media(min-width: 1024px)":{
    maxWidth: "340px",
  },
},
addressIcons:{
  height: "15px",
  width: "15px",
  marginLeft: "10px",
  marginBottom: "5px",
  cursor: "pointer"
},
addressContainer: {
  position: "relative" as "relative",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
},
textContainer: {
  flex: 1
},
iconContainer: {
  position: "absolute" as "absolute",
  display: "flex",
  flexDirection: "row" as const,
  alignItems: "flex-end",
  top: 0,
  right:0
}
};
export default withStyles(webStyle)(StoreLocator)
// Customizable Area End
