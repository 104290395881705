// Customizable Area Start
import React from "react";

import {
    Button,
    IconButton,
    // Customizable Area Start
    Tabs,
    Tab,
    Badge
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Avatar from '@material-ui/core/Avatar';
import '../assets/css/styleSeller.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

import {
    star,
    story1,
    story2,
    story3,
    story4,
    story5,
    story6,
    AddGrayIc,
    business1,
    business2,
    business3,
    business4,
    business5,
    calendarIc,
    notificationIcon,
    notificationRedDotIc,
    hamburgerIc,
    postCardImgSeller,
    likeIc,
    likerSeller,
    CloseIc,
    profileImg,
    commentIc,
    collaborationSeller,
    fileSucessIc,
    AddYellowIc,
    EditYellowIc,
    bannerImageBlankSeller

} from "./assets";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import SellerSidebar from "../../landingpage/src/SellerSidebar.web";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Popover from '@material-ui/core/Popover'
import StayTuned from "../../../components/src/StayTuned.web";
import SellerNotificationModal from "../../../components/src/SellerNotificationModal.web";

const truthyValue = (key: any) => {
    if (key !== "" || key !== null || !key.length) {
        return key
    } else {
        return ""
    }
}

// Customizable Area End

import CustomisableSellerProfilesControllerWeb, {
    Props,
} from "./CustomisableSellerProfilesControllerWeb";
import MessageModalWeb from "../../../components/src/MessageModal.web";

export default class SellerProfile extends CustomisableSellerProfilesControllerWeb {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    abstractSellerData = () => {
        let businessName = ""
        let username = ""
        let profilephoto = ""
        let backgroundphoto = ""
        let role = ""
        let bio = ""
        if (this.state.sellerProfileData) {
            businessName = truthyValue(this.state.sellerProfileData.attributes.business_name)
            username = truthyValue(this.state.sellerProfileData.attributes.user_name)
            profilephoto = truthyValue(this.state.sellerProfileData.attributes.profile_photo)
            backgroundphoto = truthyValue(this.state.sellerProfileData.attributes.background_photo)
            role = truthyValue(this.state.sellerProfileData.attributes.role.name)
            bio = truthyValue(this.state.sellerProfileData.attributes.my_bio)
        }
        return { businessName, username, profilephoto, backgroundphoto, role, bio }
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { businessName, username, profilephoto, backgroundphoto, role, bio } = this.abstractSellerData()
        const allRead = this.state.allNotifications.every(notification => notification.attributes.is_read);
        // Customizable Area End
        return (
            // Customizable Area Start
            <div className="main-div">
                <div className="dashboard-outer" data-test-id="sellerProfileMainContainer">
                    <div className="dashboard-inner">
                        <SellerSidebar
                            navigation={""}
                            id={""}
                            classes={"profile"}
                            isOpen={this.state.isOpen}
                            data-test-id="sellerSidebar"
                            closeModal={() => this.toggleDrawer()}
                        />
                        <div className="right-outer">
                            <div className="right-inner">
                                <div className="title-wrap">
                                    <div className="left-wrap">
                                        <span className="title">Profile</span>
                                    </div>
                                    <div className="right-wrap">
                                        <div className="btn-wrap">
                                            <div className="account-list" onClick={this.OpenAccountList}>
                                                <div className="account-title">{username}</div>
                                                <div className="account-list-icon">{this.state.accountListOpen ? <ExpandLess /> : <ExpandMore />}</div>
                                            </div>
                                            <Popover
                                                className="account-list-popover"
                                                open={this.state.accountListOpen}
                                                onClose={() => this.CloseAccountList()}
                                                anchorEl={this.state.accountListAnchorEl}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                            >
                                                <div className="account-list-details">
                                                    <div className="person-detail-wrap">
                                                        <div className="img-wrap">
                                                            <Avatar src={profilephoto} alt="Profile">
                                                            </Avatar>
                                                        </div>
                                                        <div className="detail">
                                                            <span className="title">{businessName}</span>
                                                            <span className="name">{(role == 'seller' ? "Seller’s account" : 'Buyer’s account')}</span>
                                                        </div>
                                                        <div className="account-select">
                                                            <img src={fileSucessIc} alt="file success" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Popover>
                                            <IconButton className="icon-btn">
                                                <img src={calendarIc} alt="calendar" />
                                            </IconButton>
                                            <IconButton className="icon-btn"  onClick={this.handleNotificationBoxToggle} data-test-id="notificationIcon">
                                            <Badge color="secondary" variant={ !allRead? "dot" : "standard"}>
                                                <img src={notificationIcon} alt="notification" />
                                             </Badge>
                                            </IconButton>
                                          
                                            <IconButton
                                                className="icon-btn hamburger-btn"
                                                data-test-id="hamburger-btn"
                                                onClick={() => this.toggleDrawer()}
                                            >
                                                <img src={hamburgerIc} alt="hamburger" />
                                               
                                            </IconButton>
                                        </div>
                                           <>
                                                {this.state.isNotificationBoxOpen && (
                                                    <SellerNotificationModal closeNotificationBox={this.handleNotificationBoxToggle} allNotifications={this.state.allNotifications}  selectedTab={this.state.selectedTab} handleTabChange={this.handleTabChange} />
                                                )}
                                            </>
                                    </div>
                                </div>
                                <section className="banner-section-seller">
                                    {backgroundphoto ?
                                        <div className="banner-img-wrap-seller">
                                            <img src={backgroundphoto} alt="banner Img" />
                                        </div>
                                        :
                                        <div className="banner-img-wrap-seller no-bg">
                                            <img className="no-bg" src={bannerImageBlankSeller} alt="banner Img" />
                                        </div>
                                    }
                                    <div className="profile-detail-seller">
                                        <div className="profile-detail-inner-seller">
                                            <div className="profile-photo-wrap-seller">
                                                <Avatar src={profilephoto} className="profile-photo-seller">
                                                </Avatar>
                                                <IconButton className="edit-icon">
                                                    <img src={profilephoto ? EditYellowIc : AddYellowIc} alt="Edit" />
                                                    <input
                                                        accept="image/*"
                                                        id="upload-avatar-pic"
                                                        type="file"
                                                        data-test-id="uploadFile"
                                                        className="upload"
                                                        onChange={this.handleSellerProfileImage}
                                                    />
                                                </IconButton>
                                            </div>
                                            <div className="profile-description-wrap">
                                                <div className="name-wrap">
                                                    <span className="name">{businessName}</span>
                                                    <span className="review">
                                                        4.5
                                                        <i>
                                                            <img src={star} alt="Review" />
                                                        </i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section className="following-section-seller">
                                    <div className="following-followers-wrap">
                                        <div className="title-number-wrap"
                                            data-test-id="followers"
                                            onClick={() => this.openStayTunedBox()}>
                                            <span className="title">Followers</span>
                                            <span className="number">12k</span>
                                        </div>
                                        <div className="title-number-wrap"
                                            data-test-id="following"
                                            onClick={() => this.openStayTunedBox()}>
                                            <span className="title">Following</span>
                                            <span className="number">1.5k</span>
                                        </div>
                                        <div className="title-number-wrap">
                                            <span className="title">Reviews</span>
                                            <span className="number">350</span>
                                        </div>
                                    </div>
                                    <div className="business-service-wrap">
                                        <div className="inner-wrap">
                                            <div className="business-facility-main" data-test-id="cash" onClick={() => this.handleOpenModalCash()}>
                                                <div className="business-facility">
                                                    <img src={business1} alt="Edit" />
                                                </div>
                                                <span>cash on delivery</span>
                                            </div>
                                            <div className="business-facility-main" data-test-id="shipping" onClick={() => this.handleOpenModalShipping()}>
                                                <div className="business-facility">
                                                    <img src={business2} alt="Edit" />
                                                </div>
                                                <span>Avg. shipping time</span>
                                            </div>
                                            <div className="business-facility-main" data-test-id="order" onClick={() => this.handleOpenModalOrder()}>
                                                <div className="business-facility">
                                                    <img src={business3} alt="Edit" />
                                                    <span className="business-text">On Order</span>
                                                </div>
                                                <span>On Order</span>
                                            </div>
                                            <div className="business-facility-main" data-test-id="item" onClick={() => this.handleOpenModalItem()}>
                                                <div className="business-facility">
                                                    <img src={business4} alt="Edit" />
                                                </div>
                                                <span>Items sold</span>
                                            </div>
                                            <div className="business-facility-main" data-test-id="bargain" onClick={() => this.handleOpenModalBargain()}>
                                                <div className="business-facility">
                                                    <img src={business5} alt="Edit" />
                                                </div>
                                                <span>Bargain</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="business-content-wrap">
                                        <p>{bio}</p>
                                    </div>
                                    <div className="btn-wrap">
                                        <Button
                                            className="custom-button primary-yellow seller-profile-yellow"
                                            component={Link} to={'/SellerUserBusinessEdit'}
                                        >
                                            User & business
                                        </Button>
                                        <Button className="custom-button outline-yellow seller-profile-transparent" data-test-id="buyerOpenStayTuned" onClick={this.openStayTunedBox}>
                                            View as buyer
                                        </Button>
                                    </div>
                                    <div className="stories-highlights-wrap">
                                        <div className="inner-wrap">
                                            <div className="story-outer">
                                                <div className="story-new">
                                                    <IconButton className="edit-icon-story">
                                                        <img src={AddGrayIc} alt="Edit" />
                                                    </IconButton>
                                                </div>
                                                <div>
                                                    <p className="story-new-text">New</p>
                                                </div>
                                            </div>
                                            <div className="story">
                                                <Avatar src={story1} className="profile-photo" />
                                            </div>
                                            <div className="story">
                                                <Avatar src={story2} className="profile-photo" />
                                            </div>
                                            <div className="story">
                                                <Avatar src={story3} className="profile-photo" />
                                            </div>
                                            <div className="story">
                                                <Avatar src={story4} className="profile-photo" />
                                            </div>
                                            <div className="story">
                                                <Avatar src={story5} className="profile-photo" />
                                            </div>
                                            <div className="story">
                                                <Avatar src={story6} className="profile-photo" />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section className="tabs-section">
                                    <div className="tabs-wrap">
                                        <Tabs
                                            value={this.state.tabValue}
                                            onChange={this.handleChange}
                                            className="custom-tabs-list"
                                            data-test-id="sellerProfileTab"
                                        >
                                            <Tab label="Posts" disableRipple />
                                            <Tab label="Lives" disableRipple />
                                            <Tab label="Top products" disableRipple />
                                            <Tab label="Collaborations" disableRipple />
                                        </Tabs>
                                        {this.state.tabValue === 1 && (
                                            <div className="seller-tabs-content">
                                                <div className="seller-inner-tab-content">
                                                    <div className="seller-inner-tab-wrap">
                                                        <div className="post-cards-wrap">
                                                            {this.state.sellerLiveStreamData.map((item: any) => (
                                                                <div className="post-card" key={item.id}>
                                                                    <div className="inner-wrap">
                                                                        <div className="img-wrap">
                                                                            <div className="inner-img-wrap">
                                                                                <img src={item.attributes.thumbnail} alt="Card img" />
                                                                            </div>
                                                                            <div className="content-wrap-live">
                                                                                <span className="live-title">{item.attributes.title}</span>
                                                                                <span className="live-content">{item.attributes.description}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {this.state.tabValue === 2 && (
                                            <div className="seller-tabs-content">
                                                <div className="seller-inner-tab-content">
                                                    <div className="seller-inner-tab-wrap">
                                                        <div className="post-cards-wrap">
                                                            {this.state.sellerTopProduct.map((item: any) => (
                                                                <div className="post-card">
                                                                    <div className="inner-wrap">
                                                                        <div className="img-wrap">
                                                                            <div className="inner-img-wrap">
                                                                                <img src={item.attributes.host_image} alt="Card img" />
                                                                            </div>
                                                                            <div className="content-wrap-product">
                                                                                <span className="product-title">{item.attributes.title}</span>
                                                                                <span className="product-content">{item.attributes.description}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
                <StayTuned openTunedBox={this.state.openTunedBox} data-test-id="closeStayTuned" closeTunedBox={this.closeTunedBox} />
                <Dialog
                    open={this.state.openModalFollowing}
                    data-test-id="followingClose"
                    onClose={() => this.handleCloseModalFollowing()}
                    className="custom-dialog"
                >
                    <DialogTitle className="title-wrap">
                        <span>Following</span>
                        <IconButton
                            aria-label="close"
                            className="close-btn"
                            data-test-id="followingCloseButton"
                            onClick={() => this.handleCloseModalFollowing()}
                        >
                            <img src={CloseIc} alt="Close" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger1</span>
                                    <span className="name">Vaibhav Verma1</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger2</span>
                                    <span className="name">Vaibhav Verma2</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger3</span>
                                    <span className="name">Vaibhav Verma3</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger4</span>
                                    <span className="name">Vaibhav Verma4</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger5</span>
                                    <span className="name">Vaibhav Verma5</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger6</span>
                                    <span className="name">Vaibhav Verma6</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger7</span>
                                    <span className="name">Vaibhav Verma7</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger8</span>
                                    <span className="name">Vaibhav Verma8</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinge9r</span>
                                    <span className="name">Vaibhav Verma9</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger10</span>
                                    <span className="name">Vaibhav Verma10</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger11</span>
                                    <span className="name">Vaibhav Verma11</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger12</span>
                                    <span className="name">Vaibhav Verma12</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.openModalFollowers}
                    data-test-id="followersClose"
                    onClose={() => this.handleCloseModalFollowers()}
                    className="custom-dialog"
                >
                    <DialogTitle className="title-wrap">
                        <span>Followers</span>
                        <IconButton
                            aria-label="close"
                            className="close-btn"
                            data-test-id="followersCloseButton"
                            onClick={() => this.handleCloseModalFollowers()}
                        >
                            <img src={CloseIc} alt="Close" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">kout_Swinger1</span>
                                    <span className="name">Verma1</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">kout_Swinger2</span>
                                    <span className="name">Verma2</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">kout_Swinger3</span>
                                    <span className="name">Verma3</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">kout_Swinger4</span>
                                    <span className="name">Verma4</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">kout_Swinger5</span>
                                    <span className="name">Verma5</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">kout_Swinger6</span>
                                    <span className="name">Verma6</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">kout_Swinger7</span>
                                    <span className="name">Verma7</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">kout_Swinger8</span>
                                    <span className="name">Verma8</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">kout_Swinge9r</span>
                                    <span className="name">Verma9</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">kout_Swinger10</span>
                                    <span className="name">Verma10</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">kout_Swinger11</span>
                                    <span className="name">Verma11</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">kout_Swinger12</span>
                                    <span className="name">Verma12</span>
                                </div>
                                <div className="unfollow-btn">
                                    <Button className="custom-button outline-yellow">
                                        Unfollow
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.cashModal}
                    data-test-id="cashClose"
                    onClose={() => this.handleCloseModalCash()}
                    className="custom-dialog rounded-border"
                >
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <div className="business-facility">
                                <img src={business1} alt="Edit" />
                            </div>
                            <span className="business-facility-title">Cash on delivery</span>
                            <span className="business-facility-content">Lorem ipsum dolor sit amet consectetur adipisci eli mattis sit nsectetur adipiscing eli mattis sit ipsum dolor sit amet.</span>
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.shippingModal}
                    data-test-id="shippingClose"
                    onClose={() => this.handleCloseModalShipping()}
                    className="custom-dialog rounded-border"
                >
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <div className="business-facility">
                                <img src={business2} alt="Edit" />
                            </div>
                            <span className="business-facility-title">Avg. Shipping Time</span>
                            <span className="business-facility-content">Lorem ipsum dolor sit amet consectetur adipisci eli mattis sit nsectetur adipiscing eli mattis sit ipsum dolor sit amet.</span>
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.orderModal}
                    data-test-id="orderClose"
                    onClose={() => this.handleCloseModalOrder()}
                    className="custom-dialog rounded-border"
                >
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <div className="business-facility">
                                <img src={business3} alt="Edit" />
                                <span className="business-text">On Order</span>
                            </div>
                            <span className="business-facility-title">On Order</span>
                            <span className="business-facility-content">Lorem ipsum dolor sit amet consectetur adipisci eli mattis sit nsectetur adipiscing eli mattis sit ipsum dolor sit amet.</span>
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.itemModal}
                    data-test-id="itemClose"
                    onClose={() => this.handleCloseModalItem()}
                    className="custom-dialog rounded-border"
                >
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <div className="business-facility">
                                <img src={business4} alt="Edit" />
                            </div>
                            <span className="business-facility-title">Items Sold</span>
                            <span className="business-facility-content">Lorem ipsum dolor sit amet consectetur adipisci eli mattis sit nsectetur adipiscing eli mattis sit ipsum dolor sit amet.</span>
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.bargainModal}
                    data-test-id="bargainClose"
                    onClose={() => this.handleCloseModalBargain()}
                    className="custom-dialog rounded-border"
                >
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <div className="business-facility">
                                <img src={business5} alt="Edit" />
                            </div>
                            <span className="business-facility-title">Bargain</span>
                            <span className="business-facility-content">Lorem ipsum dolor sit amet consectetur adipisci eli mattis sit nsectetur adipiscing eli mattis sit ipsum dolor sit amet.</span>
                        </div>
                    </DialogContent>
                </Dialog>
                <MessageModalWeb data-test-id="errorMessage" displayPopup={this.state.errorModal} closeErrorPopup={this.errorModalClose} errorMessage={this.state.imageError} />
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// Customizable Area End
// Customizable Area End