// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { WithStyles } from "@material-ui/core";
import { getStorageData,setStorageData } from "../../../framework/src/Utilities";
import StorageProvider from "../../../framework/src/StorageProvider";
interface Specification {
  id: string;
  name: string;
  type: string;
  data: string[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles{
    navigation: any;
    id: string;
    // Customizable Area Start
    handleActiveStep?:any
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    skuId: string;
    productTitle: string;
    primaryCategory: string;
    subCategory: string;
    brand: string;
    productDescription: string;
    statusDetails: string;
    MRP: null | number;
    yourSellingPrice: null | number;
    askPrice:null | number;
    isbrandNameChecked: boolean;
    isBargainOn: boolean;
    isOnOrder: boolean;
    isAskPriceOn: boolean;

    skuIdValidation:string
    productTitleValidation:string;
    primaryCategoryValidation:string,
    subCategoryValidation:string,
    brandValidation:string,
    productDescriptionValidation:string,
    statusDetailsValidation:string,
    MRPValidation:string,
    yourSellingPriceValidation:string,
    SpecificationValidation:string

    categoriesList:Array<any>
    subCategoriesList:Array<any>
    specifications: Specification[]
    dynamicSpecifications: { [id: string]: string | number }[]
    editProduct:boolean,
    editCatalougeId:string,
    askPriceValidation:string,
    isOnOrderDisable: boolean
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ListingProductController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getCategoriesId: string = "";
    getSubCategoriesId: string = "";
    cataloguesId: string = "";
    getProductDetailsId: string = "";

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area End
        ];

        // Customizable Area Start
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        // Customizable Area End

        this.state = {
            // Customizable Area Start
            skuId: "",
            productTitle: "",
            primaryCategory: "default",
            subCategory: "default",
            brand: "",
            productDescription: "",
            statusDetails: "",
            MRP: null,
            yourSellingPrice: null,
            askPrice: null,
            isbrandNameChecked: false,
            isBargainOn: false,
            isOnOrder: false,
            isAskPriceOn: false,

            skuIdValidation:"",
            productTitleValidation:"",
            primaryCategoryValidation:"",
            subCategoryValidation:"",
            brandValidation:"",
            productDescriptionValidation:"",
            statusDetailsValidation:"",
            MRPValidation:"",
            yourSellingPriceValidation:"",
            SpecificationValidation:"",
            askPriceValidation:"",
            categoriesList:[],
            subCategoriesList:[],
            specifications: [],
            
            dynamicSpecifications:[],
            editProduct:false,
            editCatalougeId:"",
            isOnOrderDisable: false
            // Customizable Area End
        };

    }

    // Customizable Area Start
   async componentDidMount() {
        this.getCategories()
        await setStorageData('currentStep',1);
        const catalogueID =  await getStorageData('catalogueID');
        const array = window.location.pathname.split("/");
        const id = array[array.length - 1];
        if(array.length>2){
          this.getProductDetails(id)
        }
        else if(catalogueID){
          this.getProductDetails(catalogueID)
        }
     const storeType = localStorage.getItem('storeType');

     if (storeType === 'online') {
       this.setState({
         isOnOrderDisable: true
       });
     }
      }


      async receive(from: string, message: Message) {

        this.handlegetCategoriesResponse(from, message)
        this.handleSubCategoriesResponse(from, message)
        this.handlecataloguesResponse(from, message)
        this.handlegetProductDetails(from, message) 

      }

      handlegetCategoriesResponse=(from: string, message: Message)=>{
        if (this.getCategoriesId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
          const apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
    
          if (apiResponse?.errors) {
            alert(apiResponse?.errors)
          } else {
            this.setState({
              categoriesList: apiResponse?.data
            })
          }
        }
      }
      handleSubCategoriesResponse=(from: string, message: Message)=>{
        if (this.getSubCategoriesId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
          const apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
    
          if (apiResponse?.errors) {
            alert(apiResponse?.errors)
          } else {
            this.setState({
              subCategoriesList: apiResponse?.data
            })
          }
        }
      }
      handlecataloguesResponse=(from: string, message: Message)=>{
        if (this.cataloguesId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
          const apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
    
          if (apiResponse?.errors) {
            alert(apiResponse?.errors)
          } else {
           this.props.handleActiveStep(2)
          }
        }
      }
      handlegetProductDetails=(from: string, message: Message)=>{
    
        if (this.getProductDetailsId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
          const apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
           if(apiResponse.data?.attributes?.sku !== null){
            const {attributes}  = apiResponse?.data
             this.getSubCategories(attributes?.category?.id);
              setTimeout(()=>{
                this.getSpecifications(String(attributes?.sub_category?.id));
              },500) 
              setTimeout(()=>{
                this.getSpecifications(String(attributes?.sub_category?.id));
                const updatedSpecifications =  attributes?.catalogues_specifications?.map((eachObject:any) => ({
                  "id": eachObject?.specification?.id,
                  "value":eachObject?.specification_text
                }));

            this.setState(
              {skuId: attributes?.sku,
                productTitle: attributes?.title,
                primaryCategory:attributes?.category.id,
                subCategory: attributes?.sub_category.id,
                brand: attributes?.brand?.name,
                productDescription: attributes?.description,
                statusDetails: attributes?.status,
                MRP: Number(attributes?.mrp),
                yourSellingPrice:Number(attributes?.selling_price),
                askPrice: attributes?.ask_price,
                isbrandNameChecked: !attributes?.is_brand,
                isBargainOn: attributes?.bargain,
                isOnOrder: attributes?.on_order,
                isAskPriceOn: attributes?.is_ask_price,
                dynamicSpecifications:updatedSpecifications
              })
              },1000)       
               
           }
          
        }
      }

      getCategories(){
        const singupLogin =  getStorageData("singupLogin");
        
        const headers = {
            token: singupLogin,
          };

        const getcategoriesMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getCategoriesId = getcategoriesMsg.messageId;
      
        getcategoriesMsg.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getCategoriesEndpoint 
        );
        getcategoriesMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
          );
        getcategoriesMsg.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
      
        runEngine.sendMessage(getcategoriesMsg.id, getcategoriesMsg);
    
      }

      getSubCategories(selectedValue:string){   
        const singupLogin =  getStorageData("singupLogin");
        const endPoint = `${configJSON.getSubCategoriesEndpoint }?category_id=${selectedValue}`
        const headers = {
            token: singupLogin,

          };
        const getSubCategoriesMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getSubCategoriesId = getSubCategoriesMsg.messageId;
      
        getSubCategoriesMsg.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        );
        getSubCategoriesMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
          );
          getSubCategoriesMsg.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
      
        runEngine.sendMessage(getSubCategoriesMsg.id, getSubCategoriesMsg);
    
      }
    
      getProductDetails=async(catalogueID:string)=>{      
        const singupLogin =  await getStorageData("singupLogin");
        const endPoint = `${configJSON.catalogueEndpoint}/${catalogueID}`
        const headers = {
            token: singupLogin,

          };
        const getProductDetailsMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getProductDetailsId = getProductDetailsMsg.messageId;
      
        getProductDetailsMsg.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        );
        getProductDetailsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
          );
          getProductDetailsMsg.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
      
        runEngine.sendMessage(getProductDetailsMsg.id, getProductDetailsMsg);
    
      }

      handleTextFieldChange = (fieldName: keyof S) => (
        event: React.ChangeEvent<HTMLInputElement>
      ) => {
        this.setState({
            [fieldName]: event.target.value.trimStart(),
            [`${fieldName}Validation`]: "",
        } as unknown as Pick<S, keyof S>);
      };
     
      handleSelectChange = (fieldName: keyof S) => (
        event: React.ChangeEvent<{ value: unknown }>
      ) => {
        const selectedValue = event.target.value as string;
        if (fieldName === "primaryCategory") {
          StorageProvider.set("category_id", selectedValue);
           this.getSubCategories(selectedValue);
           this.setState({subCategory:"default",dynamicSpecifications:[],specifications:[]});
        }
        if (fieldName === "subCategory") {
          this.setState({ subCategory: selectedValue ,dynamicSpecifications:[]}, () => {
            this.getSpecifications(selectedValue); 
            StorageProvider.set("sub_category_id", selectedValue);
          });
          return
      }

        this.setState({
            [fieldName]: event.target.value as string,
            [`${fieldName}Validation`]: "",
        } as unknown as Pick<S, keyof S>);
      };
      
      handleCheckboxChange = (fieldName: keyof S) => (
        event: React.ChangeEvent<HTMLInputElement>
      ) => {
        if(event.target.checked){
          this.setState({brand:"", brandValidation: "",})
        }
        this.setState({
            [fieldName]: event.target.checked,
        } as unknown as Pick<S, keyof S>);
      };

      handleSwitchChange = (fieldName: keyof S) => (
        event: React.ChangeEvent<HTMLInputElement>
      ) => {
        this.setState({
          [fieldName]: event.target.checked,
        } as unknown as Pick<S, keyof S>);
      };
    
      handleSaveButton = async() => {
        const isValid = this.validateForm();
        if (isValid) {
          const savedImages = JSON.parse(localStorage.getItem("savedImages") || "[]");
          const savedVideos = JSON.parse(localStorage.getItem("savedVideos") || "[]");
          const imagePromises = savedImages.map(
            async (imageSrc: string, index: number) => {
              const response = await fetch(imageSrc);
              const imageBlob = await response.blob();
              const imageFileName = imageSrc.split("/").pop() || `image_${index}.jpg`;
              return new File([imageBlob], imageFileName, { type: imageBlob.type });
            }
          );

          const videoPromises = savedVideos.map(
            async (videoSrc: string, index: number) => {
              const response = await fetch(videoSrc);
              const videoBlob = await response.blob();
              const videoFileName = videoSrc.split("/").pop() || `video_${index}.mp4`;
              return new File([videoBlob], videoFileName, { type: videoBlob.type });
            }
          );

          try {
            const images = await Promise.all(imagePromises);
            const videos = await Promise.all(videoPromises);
            this.handleProductListingData(images, videos);
          } catch (error) {
            console.error("Error handling files:", error);
          }
        }
    }
    
    validateForm = () => {
        let isValid = true;
    
        isValid = this.validateRequiredFields() && isValid;
        isValid = this.validateBrand() && isValid;
        isValid = this.validateAskPrice() && isValid;
        isValid = this.validateSpecifications() && isValid;
        isValid = this.validatePriceComparison() && isValid;
    
        return isValid;
    }
    
    validateRequiredFields = () => {
        let isValid = true;
    
        if (!this.state.skuId) {
            this.setState({
                skuIdValidation: "SKU ID is required",
            });
            isValid = false;
        }
    
        if (!this.state.productTitle) {
            this.setState({
                productTitleValidation: "Product Title is required",
            });
            isValid = false;
        }
    
        if (this.state.primaryCategory === "default") {
            this.setState({
                primaryCategoryValidation: "Primary category is required",
            });
            isValid = false;
        }
    
        if (this.state.subCategory === "default") {
            this.setState({
                subCategoryValidation: "Sub category is required",
            });
            isValid = false;
        }
    
        if (!this.state.productDescription) {
            this.setState({
                productDescriptionValidation: "Product Description is required",
            });
            isValid = false;
        }
    
        if (!this.state.statusDetails) {
            this.setState({
                statusDetailsValidation: "Status Details are required",
            });
            isValid = false;
        }
    
        if (this.state.MRP === null) {
            this.setState({
                MRPValidation: "MRP is required",
            });
            isValid = false;
        }
    
        if (this.state.yourSellingPrice === null) {
            this.setState({
                yourSellingPriceValidation: "Product Selling Price is required",
            });
            isValid = false;
        }
    
        return isValid;
    }
    
    validateBrand = () => {
        let isValid = true;
    
        if (!this.state.brand && !this.state.isbrandNameChecked) {
            this.setState({
                brandValidation: configJSON.brandValidation,
            });
            isValid = false;
        } else {
            this.setState({
                brandValidation: "",
            });
        }
    
        return isValid;
    }
    
    validateAskPrice = () => {
        let isValid = true;
    
        if (this.state.isAskPriceOn && !this.state.askPrice) {
            this.setState({
                askPriceValidation: "Ask price is required",
            });
            isValid = false;
        } else {
            this.setState({
                askPriceValidation: "",
            });
        }
    
        return isValid;
    }
    
    validateSpecifications = () => {
        let isValid = true;
    
        if (this.state.specifications.length !== this.state.dynamicSpecifications.length) {
            this.setState({
                SpecificationValidation: "All the specifications are required"
            });
            isValid = false;
        } else {
            this.setState({
                SpecificationValidation: "",
            });
        }
    
        return isValid;
    }
    
    validatePriceComparison = () => {
        let isValid = true;
    
        const { MRP, yourSellingPrice } = this.state;
        if (yourSellingPrice && MRP && Number(yourSellingPrice) > Number(MRP)) {
            this.setState({
                yourSellingPriceValidation: configJSON.validationForsellingPrice,
            });
            isValid = false;
        } else {
            this.setState({
                yourSellingPriceValidation: "",
            });
        }
    
        return isValid;
    }
    

      getSpecifications(subcategoryId: string) {
        const selectedSubcategory = this.state.subCategoriesList.find(
          (subcategory) => subcategory.id === subcategoryId
        );
       
        if (selectedSubcategory) {
          const specifications = selectedSubcategory?.attributes?.specifications?.data;
          this.setState({ specifications });
        }
      }

      handleSpecificationChange = (id: string) => (event: React.ChangeEvent<{ value: string| number }>) => {
        const value = event.target.value;
        this.setState((prevState) => {
          const updatedDynamicSpecifications = [...prevState.dynamicSpecifications];
          const index = updatedDynamicSpecifications.findIndex((spec) => spec.id == id);
          if (index !== -1) {
            updatedDynamicSpecifications[index] = { id, value };
          } else {
            updatedDynamicSpecifications.push({ id, value });
          }
          return { dynamicSpecifications: updatedDynamicSpecifications };
        });
      };

      handleProductListingData=async(img: any[], vid: any[])=>{
        const formData = new FormData();
        img.forEach((imageFile, index) => {
          formData.append(`product_images_attributes[${index}][avatar]`, imageFile);          
        });
        vid.forEach((videoFile, index) => {
          formData.append(`product_videos_attributes[${index}][avatar]`, videoFile);          
        });
        const singupLogin =  await getStorageData("singupLogin");

        const catalogueID =  await getStorageData("catalogueID");

        const endPoint = `${configJSON.createnewcatalouge}`
     
        const headers = {
            token: singupLogin,
          };

          const updatedSpecifications = this.state.dynamicSpecifications.map(spec => ({
            "specification_id": spec.id,
            "specification_text": spec.value
        }));

          const {skuId,productTitle,primaryCategory,subCategory,brand,productDescription,
            isbrandNameChecked,statusDetails,MRP,yourSellingPrice,askPrice,
            isBargainOn,isOnOrder,isAskPriceOn} = this.state
          
        formData.append("sku", skuId);
        formData.append("title", productTitle);
        formData.append("category_id", primaryCategory);
        formData.append("sub_category_id", subCategory);
        formData.append("brand", brand);
        formData.append("is_brand", brand ? "true" : "false");
        formData.append("description", productDescription);
        formData.append("status", statusDetails);
        formData.append("mrp", (MRP ?? 0).toString());
        formData.append("selling_price", (yourSellingPrice ?? 0).toString());
        formData.append("ask_price", (askPrice ?? 0).toString());
        formData.append("bargain", isBargainOn ? "true" : "false");
        formData.append("on_order", isOnOrder ? "true" : "false");
        formData.append("is_ask_price", isAskPriceOn ? "true" : "false");


        const cataloguesMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.cataloguesId = cataloguesMsg.messageId;
      
        cataloguesMsg.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        );
        cataloguesMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
          );
        cataloguesMsg.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.postMethodType
        );

        cataloguesMsg.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formData
        );
      
        runEngine.sendMessage(cataloguesMsg.id, cataloguesMsg);
      
      }

      
    // Customizable Area End
}