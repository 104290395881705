import React from "react";
// Customizable Area Start
import CreateStoryController,{Props, configJSON} from "./CreateStoryController.web";
import { createTheme,  withStyles, Theme ,createStyles} from "@material-ui/core/styles";
import {Box,IconButton,Typography,Grid,styled,Switch,Button} from "@material-ui/core";
import SellerSidebar from "../../landingpage/src/SellerSidebar.web";
import {hamburgerIcon,backButton,notificationIc,calendarIc,uploadIcon,crossIcon,tickIcon, rectangleUserImg} from "./assets";
import MessageModalWeb from "../../../components/src/MessageModal.web";
import SuccessDialog from "../../../components/src/SuccesDailogMessage.web"
import Loader from "../../../components/src/Loader.web";
import "react-datepicker/dist/react-datepicker.css";





const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

const StyledWrapper = styled(Box)({
    display:"flex",
    margin:"5em 0em 0em",
    "& .scheduleLiveInnerContainer": {
        paddingTop:"4%",
        width:"100%",
        padding:"0em 2em",
    },
    "& .hideHamburger":{
        "@media (min-width: 992px)": {
          display: 'none' 
        },
    },
    "& .hideSideHamburgerSection":{
        display:"flex",
        justifyContent:"end" as const
    },
    "& .backButton":{
        display:"flex",
        alignItems:"center" as const
    },
    "& .screenTitle":{
        color: 'rgba(68, 68, 68, 0.80)',
        fontFamily: 'Poppins',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        marginLeft:"0.5em"
    },
    "& .iconStyle":{
        width: '48px',
        height: '48px',
        borderRadius: '10px',
        border: '1px solid var(--0, #FFF)',
        background: 'var(--0, #FFF)',
        boxShadow: '0px 0px 20px 1px rgba(0, 0, 0, 0.10)',
        display:"flex",
        alignItems:"center"  as const,
        justifyContent:"center"  as const,
        margin:"0em 0.5em"
    },
    "& .uploadContainer": {
        height: "142px",
        border: "2px dashed #ccc",
        display: "flex",
        alignItems: "center" as const,
        justifyContent: "center" as const,
        position: "relative" as const,
        borderRadius:"10px"
    },
    "& .thumbnailContainer": {
        height: "auto",
        border: "2px dashed #ccc",
        display: "flex",
        justifyContent: "space-between" ,
        padding:"1em 2em",
        alignItems:"center",
        borderRadius:"10px"
    },
    "& .uploadedFileName":{
        display:"flex",
        alignItems:"center"
    },
    "& .uploadIcon": {
        width: "48px",
        height: "48px"
    },
    "& .crossIcon": {
        width: "24px",
        height: "24px",
        cursor: "pointer",
        marginLeft: "8px"
    },
    "& .addThumbnail":{
        color: '#444',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal'
    },
    "& .fileName":{
        color: '#444',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        marginLeft:"0.5em"
    },
    "& .fileSize":{
        color: '#444',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 300,
        lineHeight: 'normal',
        marginLeft:"0.5em"
    },
    "& .tickIcon":{
       width:"32px",
       height:"32px"
    },
    "& .uploadIconContainer":{
        display:"flex",
        flexDirection:"column" as const,
        alignItems:"center"
    },
    "& .uploadHereText":{
        color: '#555',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
    },
    "& .uploadText":{
        color: '#555',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        textDecorationLine: 'underline',
    },
    "& .switchContainer":{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        marginBottom:"1em"
     },
     "& .switchTitle":{
        color: '#444',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
         margin:"0.2em 0em"
     },
     "& .switchDescription":{
        color: '#555',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        margin:"0.2em 0em"
     },
    "& .saveAndButton":{
        width: '30%',
        height: '3em',
        background: '#F5EA16',
        borderRadius:"25px",
        color: '#444',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        pointer:"cursor",
        textTransform:"none" as const,
        "&:hover":{
            background: '#F5EA16',
        }
    },
    "& .imgCapContainer":{
        display: 'flex',
        alignItems: 'center',
        gap: '10px'
    },
    "& .editInput": {
        border: 'none',
        '&::placeholder': {
            color: '##555555',
            fontSize: '12px',
            fontWeight: 500
        }
    },    
    "& .tagPeople": {
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
    },
    "& .saveAndButtonContainer":{
        display:"flex",
        justifyContent:"flex-end",
        width:"100%",
         marginBottom:"2em",
        [theme.breakpoints.down('md')]: {
          margin:"1em"
        },
    },
    "& .spaceBetween":{
        display:"flex",
        justifyContent:"space-between"
    },
    "& .backBtnNavigation":{
        display:"flex",
        alignItems:"center"
    },
    "& .flexContainer":{
      display:"flex"
    },
    "& .space3":{
        marginTop:"2em"
    },
    "& .addThumbnailContainer":{
        margin:"0.75em 0em"
    },
})


export const IOSSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#FFFF00', 
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        backgroundColor: 'black', 
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 24,
      height: 24,
      backgroundColor: 'black !important', 
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  })
)(({ classes, ...props }: any) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});




// Customizable Area End

export default class CreateStory extends CreateStoryController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    formatFileSize=(sizeInBytes: number)=>{
        const KB = sizeInBytes / 1024;
        const MB = KB / 1024;
        if (MB >= 1) {
            return `${MB.toFixed(2)} MB`;
        } else {
            return `${KB.toFixed(2)} KB`;
        }
    }

    // Customizable Area End
    render() {
        // Customizable Area Start
       const {thumbnailImage,loading} = this.state


        return (
            <StyledWrapper >
                <div className="dashboard-outer">
                    <div className="dashboard-inner">
                        <SellerSidebar
                            navigation={""}
                            id={""}
                            classes={undefined}
                            isOpen={this.state.isSideBarOpen}
                            data-test-id="sellerSidebar"
                            activetabName="create"
                            closeModal={this.handleSideBar}
                        />
                    </div>
                </div>
                <Loader loading={loading} />
                <Box className='scheduleLiveInnerContainer' data-test-id="scheduleLiveMainContainer">
                    <Box className="hideSideHamburgerSection">
                        <IconButton
                            className="hideHamburger"
                            data-test-id="hamburger-btn"
                            onClick={() => this.handleSideBar()}
                        >
                            <img src={hamburgerIcon} alt="hamburger" />
                        </IconButton>
                    </Box>
                    <Box className="spaceBetween">
                        <Box className="backBtnNavigation" m={0}>
                            <img src={backButton} alt="backButton" onClick={() => this.handleBackNavigation()} />
                            <Typography className='screenTitle'>{configJSON.createStory}</Typography>
                        </Box>
                        <Box component='span' className="flexContainer">
                            <Box component="span" className='iconStyle'><img src={calendarIc} alt="calendarIcon" /></Box>
                            <Box component="span" className='iconStyle'><img src={notificationIc} alt="bellIcon" /></Box>
                        </Box>
                    </Box>
                    <Box className="space3">
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={12} lg={8}>
                                <Typography className="addThumbnail">{configJSON.uploadImage}</Typography>

                                <Box className="addThumbnailContainer">
                                    {thumbnailImage ? (
                                        <>
                                            <Box className="thumbnailContainer" component="fieldset" data-test-id="thumbnailImagePreview">
                                                <legend className="addThumbnail">{configJSON.addThumbnailLabel}</legend>
                                                <Box className="uploadedFileName">
                                                    <img src={tickIcon} alt="Uploaded" className="tickIcon" />
                                                    <Box>
                                                        <Typography className="fileName">{thumbnailImage.name}</Typography>
                                                        <Typography className="fileSize">{this.formatFileSize(thumbnailImage.size)}</Typography>
                                                    </Box>
                                                </Box>
                                                <img src={crossIcon} alt="Remove" className="crossIcon" onClick={this.handleRemoveImage} />
                                            </Box>
                                        </>
                                    ) : (<>

                                        <Box className="uploadContainer" component="fieldset">
                                            <label htmlFor="thumbnail-upload">
                                                <input
                                                    type="file"
                                                    accept=".jpg, .png"
                                                    onChange={this.handleImageChange}
                                                    style={{ display: "none" }}
                                                    id="thumbnail-upload"
                                                    data-test-id="thumbnailImageInput"
                                                />
                                                <Box className="uploadIconContainer" >
                                                    <img src={uploadIcon} alt="Upload Icon" className="uploadIcon" />
                                                    <Typography className="uploadHereText">
                                                        <Typography component="span" className="uploadText">
                                                            {configJSON.upload}
                                                        </Typography>
                                                        {" "}
                                                        {configJSON.here}
                                                    </Typography>
                                                    {/* <Typography className={classes.validationSize}>{configJSON.validationSize}</Typography> */}
                                                </Box>
                                            </label>
                                        </Box>
                                    </>
                                    )}
                                </Box>

                                <Box className="imgCapContainer">
                                    <div>
                                        <img src={rectangleUserImg} alt="captionImg" />
                                    </div>
                                    <div>
                                        <input className="editInput" type="text" placeholder="Write a caption..." /> 
                                    </div>
                                </Box>
                                <hr style={{ color : '#44444433', margin: '10px 0px' }}/>

                                <Box>
                                    <Typography className="tagPeople">{configJSON.tagPeople}</Typography>
                                </Box>
                                <hr style={{ color : '#44444433', margin: '10px 0px' }}/>

                                <Box>
                                    <Typography className="tagPeople">{configJSON.addProducts}</Typography>
                                </Box>
                                <hr style={{ color : '#44444433', margin: '10px 0px' }}/>
                                
                            </Grid>
                            <Grid item xs={12} md={12} lg={4}>
                                <Box className="switchContainer">
                                    <Box>
                                        <Typography className="switchTitle">
                                            {configJSON.storySwitchTitles.explicitContent}
                                        </Typography>
                                        <Typography className="switchDescription">
                                            {configJSON.storySwitchTitles.muteWords}
                                        </Typography>
                                    </Box>
                                    <IOSSwitch
                                        checked={this.state.explicitContent}
                                        onChange={this.handleSwitchChange("explicitContent")}
                                        data-test-id="handleSwitch"
                                    />
                                </Box>
                            </Grid>
                            <Box className="saveAndButtonContainer">
                                <Button data-test-id="save" className="saveAndButton">{configJSON.share}</Button>
                            </Box>
                        </Grid>
                    </Box>
                </Box>
                <MessageModalWeb data-test-id="errorMessage" displayPopup={this.state.errorModal} closeErrorPopup={this.errorModalClose} errorMessage={this.state.errorMessage} />
                <SuccessDialog
                    data-test-id="sucessMessage"
                    open={this.state.sucessModal}
                    closeFn={this.sucessMessageClose}
                    successTitle={configJSON.sucessMessage}
                    successMessage={configJSON.sucessDescription}
                />
            </StyledWrapper>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
// export default withStyles(webStyles)(CreateStory)
// Customizable Area End