import React from "react";
// Customizable Area Start
import AddProductVariantsController, {
    Props
} from "./AddProductVariantsController.web";

import { withStyles, StyleRules } from "@material-ui/core/styles";
import { Paper, Grid, Box, Typography, Button, Dialog, DialogContent, TextField, FormHelperText } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SellerSidebar from "../../landingpage/src/SellerSidebar.web";
import CloseIcon from '@material-ui/icons/Close';
import { backButton, bellIcon, calendarIcon, editIcon, exclamatoryIcon, imageAddButton, plusIcon, removeIcon, successVector, uploadIcon, videoAddButton } from "./assets";
import { StyledSwitch } from "../../../components/src/StyledSwitch.web";
import { hamburgerIc } from "../../landingpage/src/assets";
import { configJSON } from "./AddNewProductController.web";
import MessageModalWeb from "../../../components/src/MessageModal.web";
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    root: {
        margin: "5em 0em 0em",
        display: 'flex',
        width: '100vw'
    },
    addVarientsContainer: {
        display: "flex",
        justifyContent: "space-between",
        margin: "0em 3em",
        '@media(max-width: 656px)': {
            margin: '0em 1em'
        },
    },
    iconStyle: {
        height: '48px',
        width: '48px',
        border: '1px solid var(--0, #FFF)',
        borderRadius: '10px',
        boxShadow: '0px 0px 20px 1px rgba(0, 0, 0, 0.10)',
        background: 'var(--0, #FFF)',
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
    },
    iconHamburgerStyle: {
        height: '48px',
        border: '1px solid var(--0, #FFF)',
        width: '48px',
        boxShadow: '0px 0px 20px 1px rgba(0, 0, 0, 0.10)',
        borderRadius: '10px',
        alignItems: "center",
        background: 'var(--0, #FFF)',
        display: "flex",
        justifyContent: "center",
        cursor: 'pointer',
        '@media(min-width: 991px)': {
            display: 'none',
        },
    },
    title: {
        color: 'rgba(68, 68, 68, 0.80)',
        fontSize: '20px',
        fontWeight: 500,
        marginLeft: "0.45em"
    },
    uploadTitle: {
        color: '#555',
        fontSize: '16px',
        fontWeight: 600,
    },
    imageSection: {
        height: "auto",
        border: '1px dashed rgba(68, 68, 68, 0.60)',
        borderRadius: "8px",
        padding: "1em",
        maxWidth: "100%"
    },
    imagePreviewCard: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: '168px',
        cursor: 'pointer'
    },
    uploadHereText: {
        color: '#555',
        fontSize: '16px',
        display: 'flex',
        gap: '8px',
        fontWeight: 500,
    },
    uploadText: {
        color: '#555',
        fontSize: '16px',
        fontWeight: 600,
        textDecorationLine: 'underline',
    },
    previewImagesSection: {
        position: "relative",
        margin: "0em 0.5em"
    },
    previewVideosSection: {
        position: "relative",
        margin: "0em 0.5em"
    },
    eachPreviewImage: {
        width: "100%",
        height: "8.5em",
    },
    eachvideoPreviewImage: {
        width: "100%",
        height: "8.5em",
        objectFit: "cover"
    },
    removeIcon: {
        position: "absolute",
        top: "43%",
        right: "43%"
    },
    defaultAddButton: {
        marginTop: "0.5em"
    },
    validationInfoBox: {
        background: '#F5EA161A',
        borderRadius: '8px',
        width: '100%',
        padding: '12px',
        display: 'flex',
        alignItems: 'start',
        gap: '8px'
    },
    validationTxt: {
        color: '#444444B2',
        fontSize: '14px',
        fontWeight: 300
    },
    validationValue: {
        fontWeight: 800
    },
    colorAndSizeBox: {
        width: '181px',
        height: '67px',
        background: '#F6F6F6',
        fontSize: '14px',
        fontWeight: 500,
        borderRadius: '8px',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
    addBtn: {
        maxWidth: '342px',
        width: '100%',
        height: '48px',
        background: '#F5EA16',
        alignSelf: 'end',
        fontSize: '16px',
        fontWeight: 500,
        borderRadius: '50px',
        textTransform: 'none',
        color: '#555555',
        '&:hover': {
            background: '#F5EA16'
        },
    },
    addBtn1: {
        maxWidth: '342px',
        width: '100%',
        height: '48px',
        background: '#F5EA16',
        alignSelf: 'end',
        fontSize: '16px',
        fontWeight: 500,
        borderRadius: '50px',
        textTransform: 'none',
        color: '#555555',
        top:"50%",
        '&:hover': {
            background: '#F5EA16'
        },
        "@media (max-width: 480px)": {
            top: "14%"
          }
    },
    variantsBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px'
    },
    addColorDialog: {
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
        padding: '24px',
        '@media(max-width: 697px)': {
            padding: '16px',
        },
        height: '283px',
    },
    colorDialogBackground: {
        "& .MuiBackdrop-root": {
            background: '#000000B2',
        }
    },
    autoCompleteFields: {
        width: '100%',
        height: '50px',
        '& .MuiOutlinedInput-input': {
            padding: '18.5px',
            fontSize: '14px'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '10px',
            border: '1px solid #44444480'
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #44444480'
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
            padding: '9px !important'
        }
    },
    errorTxt: {
        color: 'red',
        marginTop: '16px',
        fontSize: '14px',
    },
    closeIcon: {
        color: 'black',
        background: '#F5EA16',
        borderRadius: '24px',
        padding: '4px',
        position: 'absolute',
        top: '-12px',
        right: '-12px',
        cursor: 'pointer'
    },
    outlinedInputStyle: {
        maxWidth: '274px',
        width: '100%',
        height: '56px',
        fontSize: '14px',
        color: '#44444480',
        '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '10px',
            border: '1px solid #44444480'
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #44444480'
        },
    },
    innerContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0em 5em 5em',
        gap: '40px',
        '@media(max-width: 656px)': {
            padding: '0em 2em 2em'
        },
    }
    // Customizable Area End
}

export class AddProductVariants extends AddProductVariantsController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    addColorDialogBox = () => {
        const { classes } = this.props;
        const { addColorDialog, addColour, colourErrorMessage } = this.state;
        return (
            <Dialog
                open={addColorDialog}
                onClose={this.closeAddColorDialogBox}
                PaperProps={{
                    style: {
                        borderRadius: 23,
                        maxWidth: '573px',
                        width: '100%'
                    },
                }}
                className={classes.colorDialogBackground}
            >
                <DialogContent className={classes.addColorDialog}>
                    <Typography style={{ fontSize: '16px', fontWeight: 600, color: '#444444' }}>Colour</Typography>
                    <Box style={{ width: '100%' }} >
                        <TextField
                            label="Colour"
                            variant="outlined"
                            className={classes.autoCompleteFields}
                            value={addColour}
                            onChange={this.handleColourChange}
                            InputLabelProps={{
                                shrink: true,
                                style: { color: '#444444B2' }
                            }}
                        />
                        <FormHelperText className={classes.errorTxt}>{colourErrorMessage}</FormHelperText>
                    </Box>
                    <Button className={classes.addBtn} style={{ maxWidth: '525px' }} onClick={this.addColour}>Add</Button>
                </DialogContent>
            </Dialog>
        )
    }

    addSizeDialogBox = () => {
        const { classes } = this.props;
        const { addSizeDialog, addSize, sizeErrorMessage } = this.state;
        return (
            <Dialog
                open={addSizeDialog}
                onClose={this.closeAddSizeDialogBox}
                PaperProps={{
                    style: {
                        borderRadius: 23,
                        maxWidth: '573px',
                        width: '100%'
                    },
                }}
                className={classes.colorDialogBackground}
            >
                <DialogContent className={classes.addColorDialog}>
                    <Typography style={{ fontSize: '16px', fontWeight: 600, color: '#444444' }}>Size</Typography>
                    <Box style={{ width: '100%' }} >
                        <TextField
                            label="Size"
                            variant="outlined"
                            className={classes.autoCompleteFields}
                            value={addSize}
                            onChange={this.handleSizeChange}
                            InputLabelProps={{
                                shrink: true,
                                style: { color: '#444444B2' }
                            }}
                        />
                        <FormHelperText className={classes.errorTxt}>{sizeErrorMessage}</FormHelperText>
                    </Box>
                    <Button className={classes.addBtn} style={{ maxWidth: '525px' }} onClick={this.addSize}>Add</Button>
                </DialogContent>
            </Dialog>
        )
    }

    productSuccessDialogBox = () => {
        const { classes } = this.props;
        const { productSuccessDialog } = this.state;
        return (
            <Dialog
                open={productSuccessDialog}
                onClose={this.closeProductSuccessDialogBox}
                PaperProps={{
                    style: {
                        borderRadius: 23,
                        maxWidth: '526px',
                        minHeight: '228px',
                        width: '100%'
                    },
                }}
                className={classes.colorDialogBackground}
            >
                <DialogContent className={classes.addColorDialog}>
                    <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', gap: '24px' }}>
                        <img src={successVector} />
                        <Typography style={{ fontSize: '20x', textAlign: 'center', fontWeight: 600, color: '#555555' }}>Successfully added</Typography>
                        <Typography style={{ fontSize: '16px', textAlign: 'center', color: '#55555599', cursor: 'pointer' }}>
                            Product variant has been successfully added to store inventory
                        </Typography>
                    </Box>
                </DialogContent>
            </Dialog>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { sidebarOpen, uploadedImages, uploadedVideos, sameValueForAll, editColour, editSize, colour, size, isExpanded, pageToShow, selectedColour, varianrPriceError, colourSelctionError, sizeSelectionError, uploadImageError, uploadVideoError, selectedSizeData, selectedColourName } = this.state;
        let isDisble = true;      
        
        return (
            <Paper className={classes.root}>
                {this.addColorDialogBox()}
                {this.addSizeDialogBox()}
                {this.productSuccessDialogBox()}
                <div className="dashboard-outer">
                    <div className="dashboard-inner">
                        <SellerSidebar
                            navigation={""}
                            id={""}
                            classes={undefined}
                            isOpen={sidebarOpen}
                            activetabName="inventory"
                            closeModal={this.closeSideDrawer}
                        />
                    </div>
                </div>
                {pageToShow === 'AddVariant' &&
                    <Box style={{ paddingTop: "40px", width: '100%', display: 'flex', flexDirection: 'column', gap: '40px' }}>
                        <Box className={classes.addVarientsContainer}>
                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={backButton} style={{ cursor: 'pointer' }} onClick={this.goBackOnAddProduct} />
                                <Typography className={classes.title}>{this.state.checkAddorEditVariant ? "Edit variant" : "Add variant"}</Typography>
                            </Box>
                            <Box style={{ display: "flex", gap: '12px' }}>
                                <Box className={classes.iconStyle}><img src={calendarIcon} /></Box>
                                <Box className={classes.iconStyle}><img src={bellIcon} /></Box>
                                <Box className={classes.iconHamburgerStyle} onClick={this.closeSideDrawer}>
                                    <img src={hamburgerIc} alt="hamburger" />
                                </Box>
                            </Box>
                        </Box>

                        <Box className={classes.innerContainer} >
                            <Box className={classes.variantsBox}>
                                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography className={classes.uploadTitle}>Colour</Typography>
                                </Box>
                                <Box style={{ display: 'flex', gap: '16px', flexWrap: 'wrap' }}>
                                    {colour.map((item: any) => {
                                    const isDisble = Number(this.state.variantID) > 0 && Number(this.state.variantID) !== item.attributes.id
                                        return (
                                            <Box
                                                className={classes.colorAndSizeBox}
                                                data-test-id={`select-color-${item.id}`}
                                                style={{ border: selectedColour === item.attributes.id ? '2px solid #F5EA16' : 'none', opacity: isDisble ? 0.5 : 1, pointerEvents: isDisble ? 'none' : 'auto' }}
                                                onClick={() => this.handleSelectColour(item)}
                                            >
                                                {item.attributes?.name}
                                                <CloseIcon
                                                    className={classes.closeIcon}
                                                    onClick={(event) => this.handleRemoveColour(event, item.id)}
                                                />
                                            </Box>
                                        )
                                    })}
                                    <Box className={classes.colorAndSizeBox} onClick={this.openAddColorDialog}>
                                        <img src={plusIcon} />
                                    </Box>
                                </Box>
                                <Typography className={classes.errorTxt}>{colourSelctionError}</Typography>
                            </Box>
                            <Box className={classes.variantsBox}>
                                <Typography className={classes.uploadTitle}>Upload Images ({uploadedImages.length}/6)</Typography>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={8}>
                                        <Box className={classes.imageSection}>
                                            <input
                                                ref={this.inputRef}
                                                type="file"
                                                accept=".jpg, .png"
                                                onChange={this.handleImageUpload}
                                                style={{ display: "none" }}
                                                id="imageInput"
                                                multiple
                                            />
                                            {uploadedImages.length === 0 &&
                                                <label htmlFor="imageInput">
                                                    <Box className={classes.imagePreviewCard}>
                                                        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                            <img src={uploadIcon} />
                                                            <Typography className={classes.uploadHereText}>
                                                                <Typography className={classes.uploadText}>upload</Typography>here
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </label>
                                            }
                                            {uploadedImages.length > 0 &&
                                                <Grid container spacing={2} >
                                                    {uploadedImages.map((image: any, index: number) => (
                                                        <Grid key={index} item xs={12} md={4} >
                                                            <Box key={index} className={classes.previewImagesSection}   >
                                                                <img src={image.url || URL.createObjectURL(image)} className={classes.eachPreviewImage} />
                                                                <Box
                                                                    className={classes.removeIcon}
                                                                    data-test-id={`removeImage-${index}`}
                                                                    onClick={() => this.handleRemoveImage(index)}
                                                                >
                                                                    <img src={removeIcon} alt="removeIcon" style={{ cursor: 'pointer' }} />
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                    ))}
                                                    {uploadedImages.length < 6 && (
                                                        <label htmlFor="imageInput2" onClick={this.handleAddImage} >
                                                            <img src={imageAddButton} alt="imageAddButton" className={classes.defaultAddButton} />
                                                        </label>
                                                    )}
                                                </Grid>
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4} style={{ display: 'flex', alignItems: 'center' }}>
                                        <Box className={classes.validationInfoBox}>
                                            <img src={exclamatoryIcon} />
                                            <Box>
                                                <Typography className={classes.validationTxt}><span className={classes.validationValue}>Size max :</span > 5mb</Typography>
                                                <Typography className={classes.validationTxt}><span className={classes.validationValue}>Format :</span> JPG, PNG</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Typography className={classes.errorTxt}>{uploadImageError}</Typography>
                            </Box>
                            <Box className={classes.variantsBox}>
                                <Typography className={classes.uploadTitle}>Upload Video ({uploadedVideos.length}/2)</Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={8}>
                                        <Box className={classes.imageSection}>
                                            <input
                                                ref={this.videoInputRef}
                                                type="file"
                                                accept="video/*"
                                                onChange={this.handleVideoUpload}
                                                style={{ display: "none" }}
                                                data-test-id="video-upload"
                                                id="videoInput"
                                                multiple
                                            />
                                            {uploadedVideos.length === 0 &&
                                                <label htmlFor="videoInput">
                                                    <Box className={classes.imagePreviewCard}>
                                                        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                            <img src={uploadIcon} />
                                                            <Typography className={classes.uploadHereText}>
                                                                <Typography className={classes.uploadText}>upload</Typography>here
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </label>
                                            }
                                            {uploadedVideos.length > 0 && (
                                                <Grid container spacing={2} >
                                                    {uploadedVideos.map((video: any, index: number) => {
                                                        return (
                                                            <Grid key={index} item xs={12} md={4} lg={4}>
                                                                <Box key={index} className={classes.previewVideosSection}>
                                                                    <video src={video.url || URL.createObjectURL(video)} className={classes.eachvideoPreviewImage} />
                                                                    <Box
                                                                        className={classes.removeIcon}
                                                                        onClick={() => this.handleRemoveVideo(index)}
                                                                    >
                                                                        <img src={removeIcon} style={{ cursor: 'pointer' }} />
                                                                    </Box>
                                                                </Box>
                                                            </Grid>
                                                        )
                                                    })}

                                                    {uploadedVideos.length < 2 && (
                                                        <label htmlFor="videoInput" onClick={this.handleAddVideo}>
                                                            <img src={videoAddButton} className={classes.defaultAddButton} />
                                                        </label>
                                                    )}
                                                </Grid>
                                            )}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4} style={{ display: 'flex', alignItems: 'center' }}>
                                        <Box className={classes.validationInfoBox}>
                                            <img src={exclamatoryIcon} />
                                            <Box>
                                                <Typography className={classes.validationTxt}><span className={classes.validationValue}>Size max :</span > 10 mb</Typography>
                                                {/* <Typography className={classes.validationTxt}><span className={classes.validationValue}>Resolution :</span> 1280 px X 1280 px</Typography> */}
                                                <Typography className={classes.validationTxt}><span className={classes.validationValue}>Format :</span> MP4</Typography>
                                                <Typography className={classes.validationTxt}>You can publish the listing while the video is processing </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Typography className={classes.errorTxt}>{uploadVideoError}</Typography>
                            </Box>
                            <Box className={classes.variantsBox}>
                                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography className={classes.uploadTitle}>Size</Typography>
                                </Box>
                                <Box style={{ display: 'flex', gap: '16px', flexWrap: 'wrap' }}>
                                    {size.map((item: any) => {
                                        return (
                                            <Box
                                                className={classes.colorAndSizeBox}
                                                style={{ border: this.checkSizeStatus(selectedSizeData, String(item.attributes.id)) ? '2px solid #F5EA16' : 'none' }}
                                                onClick={() => this.handleSelectSize(item)}
                                            >
                                                {item.attributes?.name}
                                                <CloseIcon
                                                    className={classes.closeIcon}
                                                    onClick={(event) => this.handleRemoveSize(event, item.id)}
                                                />
                                            </Box>
                                        )
                                    })}
                                    <Box className={classes.colorAndSizeBox} onClick={this.openAddSizeDialog}>
                                        <img src={plusIcon} />
                                    </Box>
                                </Box>
                                <Typography className={classes.errorTxt}>{sizeSelectionError}</Typography>
                            </Box>
                            <Button className={classes.addBtn} onClick={this.navigateToInfoPage}>Add</Button>
                        </Box>
                    </Box>}
                {pageToShow === 'VariantInfo' &&
                    <Box style={{ paddingTop: "40px", width: '100%', display: 'flex', flexDirection: 'column', gap: '40px' }}>
                        <Box className={classes.addVarientsContainer}>
                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={backButton} style={{ cursor: 'pointer' }} onClick={this.goBackOnAddVariant} />
                                <Typography className={classes.title}>Set Variant Info</Typography>
                            </Box>
                            <Box style={{ display: "flex", gap: '12px' }}>
                                <Box className={classes.iconStyle}><img src={calendarIcon} /></Box>
                                <Box className={classes.iconStyle}><img src={bellIcon} /></Box>
                            </Box>
                        </Box>
                        <Box className={classes.innerContainer} >
                            <Box className={classes.variantsBox}>
                                <Box style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                                    <img src={exclamatoryIcon} />
                                    <Typography style={{ fontSize: '14px', color: '#444444B2' }}>
                                        There should not be a large price difference between your most and least expensive variation
                                    </Typography>
                                </Box>
                                <Box style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap-reverse' }}>
                                    <Box style={{ display: 'flex', flexDirection: 'column', padding: '16px', background: '#F6F6F6', maxWidth: '668px', width: '100%', gap: '24px', borderRadius: '12px' }}>
                                        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                            <Typography style={{ fontSize: '14px', color: '#444444' }}>{selectedColourName}</Typography>
                                            {isExpanded ?
                                                <ExpandLessIcon style={{ cursor: 'pointer' }} onClick={this.handleIsExpand} /> :
                                                <ExpandMoreIcon style={{ cursor: 'pointer' }} onClick={this.handleIsExpand} />}
                                        </Box>
                                        <Box style={{ display: isExpanded ? 'flex' : 'none', flexDirection: 'column', gap: '24px' }}>
                                            {selectedSizeData.map((item: any,index: any) => (
                                                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '12px', flexWrap: 'wrap' }} key={item.id}>
                                                    <Typography style={{ fontSize: '14px', color: '#444444' }}>{item.attributes.name}</Typography>
                                                    <Box style={{ display: 'flex', gap: '24px', flexWrap: 'wrap' }}>
                                                        <Box style={{ maxWidth: '274px' }}>
                                                            <TextField
                                                                label="Price"
                                                                variant="outlined"
                                                                className={classes.outlinedInputStyle}
                                                                value={item.price}
                                                                data-test-id={`change-price-${index}`}
                                                                onChange={(e) => this.handleVariantPriceChange(e, index)}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                    style: { color: '#444444B2' }
                                                                }}
                                                                type="number"
                                                            />
                                                            <FormHelperText style={{maxWidth: '223px'}} className={classes.errorTxt}>{item.priceError}</FormHelperText>
                                                        </Box>
                                                        <Box style={{ maxWidth: '274px' }}>
                                                            <TextField
                                                                label="Stock"
                                                                variant="outlined"
                                                                type="number"
                                                                className={classes.outlinedInputStyle}
                                                                value={item.stock_qty}
                                                                onChange={(e) => this.handleVariantStockChange(e, index)}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                    style: { color: '#444444B2' }
                                                                }}
                                                            />
                                                            <FormHelperText style={{maxWidth: '223px'}} className={classes.errorTxt}>{item.stockError}</FormHelperText>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            ))}
                                            <FormHelperText className={classes.errorTxt}>{varianrPriceError}</FormHelperText>
                                        </Box>
                                    </Box>
                                    <Box style={{ display: 'flex', gap: '16px', marginBottom: '32px' }}>
                                        <Typography style={{ fontSize: '14px', color: '#444444B2', maxWidth: '276px', width: '100%' }}>
                                            {configJSON.samePriceText}
                                        </Typography>
                                        <StyledSwitch
                                            checked={sameValueForAll}
                                            onChange={this.handleSamePriceAndStock}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Button className={classes.addBtn1} data-test-id="save-btn" onClick={this.handleAddProductVariant}>Save</Button>
                        </Box>
                    </Box>}
                    <MessageModalWeb data-test-id="errorMessage" displayPopup={this.state.errorModal} closeErrorPopup={this.errorModalClose} errorMessage={this.state.errorMessage} />
            </Paper >
        )
        // Customizable Area End
    }
}

// Customizable Area Start
export default withStyles(styles)(AddProductVariants);
// Customizable Area End
