import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  styled,
  TextField,
  Tab,
  Tabs,
  Paper
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
  cancelledIc,
  deliveredIc,
  dispatchedIc,
  returnedIc,
  imgSearch,
} from './assets'
import TabPanel from "../../../components/src/TabPanel.web";
import { noDataFound } from "../../landingpage/src/assets";
const baseURL = require("../../../framework/src/config.js").baseURL
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import CustomisedOrderStatusController, {
  Props,
  configJSON,
} from "./CustomisedOrderStatusController";
import StayTuned from "../../../components/src/StayTuned.web";

export default class CustomisedOrderStatus extends CustomisedOrderStatusController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  removeDeliveryDate = (status: string) => {
    const dateNew = new Date();
    dateNew.setDate(dateNew.getDate() + 7);

    const deliveryData = dateNew.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).replace(/\//g, '-');;
    return (
      status !== 'declined' &&
      <Typography style={{ color: '#555555', fontSize: '14px', fontWeight: 500 }}>Expected Delivery : {deliveryData}</Typography>
    )
  }

  declinnedText = (status: string) => {
    return (status === "declined" &&
      <Box sx={webStyle.statusBox} style={{ background: '#FF00001A' }} >
        <img src={cancelledIc} />
        <Typography style={webStyle.statusTxt}>Cancelled</Typography>
      </Box>)
  }

  deliveredText = (status: string) => {
    return (
      (status === "delivered") &&
      <Box sx={webStyle.statusBox} style={{ background: '#4BB5431A' }}>
        <img src={deliveredIc} />
        <Typography style={webStyle.statusTxt}>Delivered</Typography>
      </Box>
    )
  }

  inTransitText = (status: string) => {
    return (
      status === "in_transit" &&
      <Box sx={webStyle.statusBox} style={{ background: '#0066FF1A' }}>
        <img src={dispatchedIc} />
        <Typography style={webStyle.statusTxt}>Dispatched</Typography>
      </Box>
    )
  }

  returntext = (status: string) => {
    return (
      status === "returned" &&
      <Box sx={webStyle.statusBox} style={{ background: '#8F00FF1A' }}>
        <img src={returnedIc} />
        <Typography style={webStyle.statusTxt}>Returned</Typography>
      </Box>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MainWrapper>
        <div id="myref"></div>
        <Loader loading={this.state.loading} />
        <Box sx={webStyle.listBox}>
          <Box sx={webStyle.orderTitleWrap}>
            <Typography style={{ fontSize: '26px', fontWeight: 500 }}>Orders</Typography>
            <StyledSearchField
              variant="outlined"
              placeholder="Search orders"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={imgSearch} />
                  </InputAdornment>
                ),
              }}
              value={this.state.searchValue}
              onKeyPress={this.handleKeyPress}
              onBlur={this.getOrderDataList}
              onChange={this.handleSearchFieldValue} />
          </Box>
          <Box sx={webStyle.orderListWrap}>
            <StyledTabs
              value={this.state.tabValue}
              onChange={this.handleChange}
              data-testid='tab'
              indicatorColor="primary"
              textColor="primary"
            >
              <StyledTab label="Orders" />
              <StyledTab label="Bargain" />
              <StyledTab label="On-Order" />
            </StyledTabs>
          </Box>
          <Box style={{ maxWidth: '1096px', width: '100%' }}>
            <TabPanel value={this.state.tabValue} index={0} >
              <Box style={{ display: 'flex', flexDirection: 'column', gap: '40px' }}>
                <Box style={{ display: 'flex', flexWrap: 'wrap', gap: '12px' }}>
                  <StyledButton
                    style={{ background: this.state.filterStatus === '' ? '#F5EA16' : 'none' }}
                    onClick={() => this.handleFilterStatus("")}
                    data-testid='all'
                  >
                    All ({this.state.all})
                  </StyledButton>
                  <StyledButton
                    style={{ background: this.state.filterStatus === 'in_transit' ? '#F5EA16' : 'none' }}
                    onClick={() => this.handleFilterStatus("in_transit")}
                    data-testid='Dispatch'
                  >
                    Dispatch
                  </StyledButton>
                  <StyledButton
                    style={{ background: this.state.filterStatus === 'delivered' ? '#F5EA16' : 'none' }}
                    onClick={() => this.handleFilterStatus("delivered")}
                    data-testid='Delivered'
                  >
                    Delivered
                  </StyledButton>
                  <StyledButton
                    style={{ background: this.state.filterStatus === 'declined' ? '#F5EA16' : 'none' }}
                    onClick={() => this.handleFilterStatus("declined")}
                    data-testid='Cancelled'
                  >
                    Cancelled
                  </StyledButton>
                  <StyledButton
                    style={{ background: this.state.filterStatus === 'returned' ? '#F5EA16' : 'none' }}
                    onClick={() => this.handleFilterStatus("returned")}
                    data-testid='Returned'
                  >
                    Returned
                  </StyledButton>
                </Box>
                <Box style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column', gap: '12px', paddingBottom: '40px' }}>
                  {this.state.orderDataList.length > 0 ?
                    this.state.orderDataList.map((order: any) => (
                      <OrderBox data-test-id="order-box" onClick={() => this.HandleSingleOrderStatus(order.id)}>
                        <Box data-test-id="product-images" style={{ height: '100px', width: '100px', padding: '8px' }} >
                          {
                            order.attributes.catalogue.attributes.product_images?.length > 0 && (
                              <img src={`${baseURL}${order.attributes.catalogue.attributes.product_images[0].url}`} style={{ height: '90px', width: '90px', borderRadius: '10px', border: '1px solid #EEEAEA' }} />
                            )
                          }
                        </Box>
                        <Box style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                          <Box style={{ display: 'flex', gap: '8px', alignItems: 'center', flexWrap: 'wrap' }}>
                            <Typography style={webStyle.orderTitleTxt} >{order.attributes.catalogue.attributes.title}</Typography>
                            {this.declinnedText(order.attributes.status)}
                            {this.deliveredText(order.attributes.status)}
                            {this.inTransitText(order.attributes.status)}
                            {this.returntext(order.attributes.status)}
                          </Box>
                          <Typography style={{ color: '#55555580', fontSize: '12px', fontWeight: 400 }}>{order.attributes.order_number}</Typography>
                          {order.attributes.status === 'delivered' ?
                            <Box>
                              <Typography style={{ color: '#555555', fontSize: '14px', fontWeight: 500 }}>Delivered by Oranlo</Typography>
                              <Typography style={{ color: '#555555', fontSize: '14px', fontWeight: 500 }}>Contact: 00000000, Pin: 999</Typography>
                            </Box> :
                            this.removeDeliveryDate(order.attributes.status)
                          }
                        </Box>
                      </OrderBox>
                    )) :
                    <Box style={{ width: '100%', display: 'flex', height: '50vh', justifyContent: 'center', alignItems: 'center' }}>
                      <img src={noDataFound} style={webStyle.noDataFoundImg} />
                    </Box>}
                </Box>
              </Box>
            </TabPanel>
            <TabPanel value={this.state.tabValue} index={1}>
              <StayTuned openTunedBox={this.state.openBox} closeTunedBox={this.closeBox} />
            </TabPanel>
            <TabPanel value={this.state.tabValue} index={2}>
              <StayTuned openTunedBox={this.state.openBox} closeTunedBox={this.closeBox} />
            </TabPanel>
          </Box>
        </Box>
      </MainWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  listBox: {
    maxWidth: '1440px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    gap: '24px'
  },
  orderTitleWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    maxWidth: '1096px',
    width: '100%',
    paddingTop: '16px'
  },
  orderListWrap: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '1096px',
    width: '100%',
  },
  statusBox: {
    display: 'flex',
    alignItems: 'center',
    padding: '2px 4px',
    borderRadius: '4px',
    gap: '4px'
  },
  statusTxt: {
    color: '#555555B2',
    fontSize: '12px',
    fontWeight: 500,
    whiteSpace: 'nowrap' as const
  },
  noDataFoundImg: {
    maxWidth: '300px',
    width: '100%',
    MozAnimation: 'fadeIn 2s',
    animation: 'fadeIn 2s',
    OAnimation: 'fadeIn 2s',
    WebkitAnimation: 'fadeIn 2s',
    msAnimation: 'fadeIn 2s',
  },
  orderTitleTxt: {
    color: '#555555',
    fontSize: '16px',
    fontWeight: 500,
    maxWidth: '400px',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
  } as const
};

const MainWrapper = styled(Paper)({
  minHeight: "100vh",
  marginTop: '100px',
  display: 'flex',
  justifyContent: 'center',
  boxShadow: 'none',
  padding: '8px',
  '@media(max-width: 1156px)': {
    marginTop: '70px',
  },
})

const StyledSearchField = styled(TextField)({
  maxWidth: '482px',
  width: '100%',
  height: '48px',
  fontSize: '14px',
  color: '#444444CC',
  boxShadow: '0px 4px 20px 0px #0000001A',
  borderRadius: '10px',
  '& .MuiOutlinedInput-input': {
    padding: '16px 14px 16px 0px',
    fontSize: '14px',
    color: '#444444CC'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: '10px',
    border: 'none'
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
});

const StyledTabs = styled(Tabs)({
  "& .MuiTabs-flexContainer": {
    display: 'flex',
    justifyContent: 'space-between',
    width: '542px'
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "#F5EA16",
    height: '4px',
    width: '90px !important'
  },
  "& .MuiTab-root.Mui-selected": {
    color: '#555555',
    fontSize: '16px',
    fontWeight: 500
  },
  "& .MuiTab-root": {
    minWidth: '90px',
    fontSize: '16px',
  },
  borderBottom: '1px solid #44444480',
  width: '100%',
  '@media(max-width: 697px)': {
    "& .MuiTabs-flexContainer": {
      width: 'auto',
      justifyContent: 'flex-start',
    },
  },
});

const StyledTab = styled(Tab)({
  color: '#555555B2',
  fontSize: '16px',
  fontWeight: 500,
  width: '90px',
  whiteSpace: 'nowrap',
  textTransform: 'none'
})

const StyledButton = styled(Button)({
  width: 'auto',
  height: '29px',
  borderRadius: '20px',
  padding: '4px 20px 4px 20px',
  color: '#444444',
  fontSize: '14px',
  fontWeight: 500,
  textTransform: 'none',
  border: '1px solid #444444'
})

const OrderBox = styled(Box)({
  border: '1px solid #4444441A',
  maxWidth: '630px',
  width: '100%',
  minHeight: '120px',
  padding: '8px',
  borderRadius: '10px',
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
  cursor: 'pointer',
  '@media(max-width: 596px)': {
    flexDirection: 'column',
    justifyContent: 'center',
  },
})

// Customizable Area End
