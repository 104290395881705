import React from "react";

import {
    // Customizable Area Start
    Box,
    Grid,
    Typography,
    Button,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
    twoWave,
    App_Logo
} from "./assets";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { Link } from 'react-router-dom';
import OtpInput from 'react-otp-input';


const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
import {configJSON} from "./AccountRegistrationController";
// Customizable Area End

import AccountRegistrationController, {
    Props,
} from "./AccountRegistrationController";
import MessageModalWeb from "../../../components/src/MessageModal.web";

class SellerEmailMobileVarification extends AccountRegistrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <section className={classes.sectionSellerMobileEmailVarification}>
                    <Box className={classes.verifyEmailMobileWrapper} sx={{ display: 'flex', flexDirection: 'column', gridGap: '20px' }} >
                        <Box className={'verifyOverlayImg'}>
                            <img src={twoWave} alt="" />
                        </Box>
                        <Box className={classes.verifyMainBox}>
                            <Grid container spacing={2}>
                                <Grid item lg={5} md={6} sm={12} xs={12}>
                                    <Box className={'verifyLeftColumn'}>
                                        <img src={App_Logo} alt="" />
                                        <Typography variant="h6" component={'h6'}>{'Live shopping & market place'}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={7} md={6} sm={12} xs={12}>
                                    <Box className={'verifyRightColumn'}>
                                        <Box className={'verifyRightColumnInner'}>
                                            <Box className={"verifyRightColumnTop"} sx={{ width: '100%' }}>
                                                <Box className={'verifyBoxHeading'} style={{ margin: '0 auto' }}>
                                                    <Typography component={'h6'} className={classes.verificationCodeTitle}>{configJSON.verificationCodeTitle}</Typography>
                                                    <Typography component={'p'}>{'Enter the OTP you got in your 10 digit mobile number'}</Typography>
                                                </Box>
                                                <Box className={"verifyInputLinkBox"} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flexStart' }} style={{ margin: '0 auto', maxWidth: 'maxContent' }}>
                                                    <Box className={classes.OtpInputBox}>
                                                        <OtpInput
                                                            data-test-id="mobileOtpVerify"
                                                            value={this.state.sellerMobileOtpVerify}
                                                            onChange={this.handleSellerOtpMobilechange}
                                                            numInputs={6}
                                                            renderSeparator={<span>-</span>}
                                                            renderInput={(props) => <input {...props} />}
                                                        />
                                                        {this.state.successMessages ? <p style={{ color: 'green' }}>{this.state.successMessages}</p> : <p style={{ color: 'red' }}>{this.state.sellerOtpMobileValidation}</p>}
                                                    </Box>
                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <Box className={classes.linkBox}
                                                            sx={{ display: 'flex', flexDirection: 'column', gridGap: '20px' }}>

                                                            <Box sx={{ display: 'flex', flexDirection: 'column', gridGap: '20px', alignItems: 'start' }}>
                                                                <Button
                                                                    className="resendBtn"
                                                                    style={{ color: 'white', }}
                                                                    data-test-id="resendMobileOtp"
                                                                    onClick={this.reSendSellerOTPMobile}
                                                                    disabled={this.state.successMessages}
                                                                >
                                                                    Re-send the code.
                                                                </Button>
                                                                {this.state.resendMobileValidation === true ? <p style={{ color: 'green', margin: '-15px 0 0 0 ' }}>{this.state.resendMoibleSuccessMessage}</p> : <p style={{ color: 'red', margin: '-15px 0 0 0 ' }}>{this.state.resendMoibileError}</p>}
                                                                <Button
                                                                    component={Link}
                                                                    to={'EditMobileNumber'}
                                                                    variant="contained"
                                                                    disabled={this.state.successMessages}
                                                                >
                                                                    Edit mobile number
                                                                </Button>
                                                            </Box>
                                                        </Box>
                                                        <Box style={{ display: 'none' }} component={'div'}>
                                                            <span>
                                                                <b>{` ${this.state.otpMinute.toString().padStart(2, '0')} : ${this.state.otpSecond.toString().padStart(2, '0')}`} </b>
                                                            </span>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Box className={classes.submitVarifyBtn}>
                                                    <Button
                                                        variant="contained"
                                                        data-test-id="submit"
                                                        onClick={this.sellerMobileOtpVerifyBtn}
                                                        // disabled={this.state.isSucessMobileVerify !== false}
                                                        // disabled={localStorage.getItem('successMessages') ? true : false}
                                                        disabled={this.state.successMessages}
                                                    >
                                                        Verify
                                                    </Button>
                                                </Box>
                                            </Box>
                                            <Box className={classes.horizontalLine}>
                                                <p>And</p>
                                            </Box>
                                            <Box className={"rightcolumnBottom"} sx={{ width: '100%' }}>
                                                <Box className={'verifyBoxHeading'}>
                                                    <Typography component={'p'}>{'Enter the OTP you got in your email Id'}</Typography>
                                                </Box>
                                                <Box className={"verifyInputLinkBox"} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flexStart' }} style={{ margin: '0 auto', maxWidth: 'maxContent' }}>
                                                    <Box
                                                        className={classes.OtpInputBox}
                                                    >
                                                        <OtpInput
                                                            value={this.state.otpState}
                                                            data-test-id="emailOtpVerify"
                                                            onChange={this.handleSellerOtpEmailOnchange}
                                                            numInputs={6}
                                                            renderSeparator={<span>-</span>}
                                                            renderInput={(props) => <input {...props} />}
                                                        />
                                                        {this.state.successMessagesEmail ? <p style={{ color: 'green' }}>{this.state.successMessagesEmail}</p> : <p style={{ color: 'red' }}>{this.state.sellerOtpEmailValidation}</p>}
                                                    </Box>
                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <Box className={classes.linkBox}
                                                            sx={{ display: 'flex', flexDirection: 'column', gridGap: '20px' }}>
                                                            <Box sx={{ display: 'flex', flexDirection: 'column', gridGap: '20px', alignItems: 'start' }}>
                                                                <Button
                                                                    className="resendBtn"
                                                                    style={{ color: 'white' }}
                                                                    data-test-id="resendEmailOtp"
                                                                    onClick={this.reSendSellerOTPEmail}
                                                                    disabled={this.state.successMessagesEmail}
                                                                >
                                                                    Re-send the code.
                                                                </Button>
                                                                {this.state.resendEmailValidation === true ? <p style={{ color: 'green', margin: '-15px 0 0 0 ' }}>{this.state.resendEmailSuccessMessage}</p> : <p style={{ color: 'red', margin: '-15px 0 0 0 ' }}>{this.state.resendEmailError}</p>}
                                                                <Button
                                                                    component={Link}
                                                                    to={'EditEmail'}
                                                                    variant="contained"
                                                                    disabled={this.state.successMessagesEmail}
                                                                >
                                                                    Edit Email Id
                                                                </Button>
                                                            </Box>
                                                        </Box>
                                                        <Box style={{ display: 'none' }} component={'div'}>
                                                            <span>
                                                                <b>{` ${this.state.otpMinute.toString().padStart(2, '0')} : ${this.state.otpSecond.toString().padStart(2, '0')}`} </b>
                                                            </span>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Box className={classes.submitVarifyBtn}>
                                                    <Button
                                                        onClick={this.sendSellerOTPEmailBtn}
                                                        data-test-id="submitEmail"
                                                        disabled={this.state.successMessagesEmail}
                                                        variant="contained"
                                                    >
                                                        Verify
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </section>
                <MessageModalWeb displayPopup={this.state.otpModal} closeErrorPopup={this.otpModalClose} errorMessage={"OTP sent successfully."} />
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = (theme: any) => ({
    verificationCodeTitle:{
        color: '#FFF !important',
        fontSize: '2em  !important',
        fontWeight: 600,
        lineHeight: 'normal',
    },
    sectionSellerMobileEmailVarification: {
        width: '100%',
        height: '100%',
        color: '#ffffff !important',
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            fontSize: '15px',
            lineHight: '18px',
            color: 'rgba(255, 255, 255, 0.5) !important',
            padding: '0 5px',
            backgroundColor: 'black',
        },
        '& .MuiCheckbox-colorPrimary': {
            color: "#ffffff",
            height: 'max-content',
        },
        '& .MuiCheckbox-colorPrimary.Mui-checked': {
            color: 'yellow',
        },
    },
    verifyEmailMobileWrapper: {
        width: '100%',
        backgroundColor: '#000000',
        position: 'relative' as const,
        '& .verifyOverlayImg': {
            order: '2',
            marginTop: '-288px',
            [theme.breakpoints.down('sm')]: {
                marginTop: '0px',
            },
            '& img': {
                width: '100%',
                objectFit: 'cover',
                position: 'relative',
                zIndex: '1'
            },
        },
    },
    verifyMainBox: {
        padding: '100px',
        [theme.breakpoints.down('sm')]: {
            padding: '50px'
        },
        [theme.breakpoints.down('xs')]: {
            padding: '10px'
        },
        '& .verifyRightColumn': {
            minHeight: '946px',
            border: "1px solid rgba(255, 255, 255, 0.5)",
            borderRadius: '20px',
            position: 'relative',
            zIndex: '9',
            display: 'flex',
            justifyContent: 'center',
            maxWidth: '660px',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                padding: '20px',
            },
            [theme.breakpoints.down('xs')]: {
                padding: '10px',
            },

            '& .verifyRightColumnInner': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                textAlign: 'center',
                maxWidth: '542px',
                width: '100%',
                '& .verifyBoxHeading': {
                    maxWidth: '460px',
                    margin: '0 auto',
                    '& h6, & p': {
                        color: 'white',
                    },
                    '& h6': {
                        fontFamily: 'Poppins-SemiBold',
                        fontSize: '32px',
                        lineHeight: '48px',
                    },
                    '& p': {
                        fontFamily: 'Poppins-Regular',
                        fontSize: '18px',
                        lineHeight: '27px',
                        opacity: '0.5',
                    },
                },
            },
        },
        '& .verifyLeftColumn': {
            marginTop: '56%',
            [theme.breakpoints.down('sm')]: {
                marginTop: '0%',
            },
            '& img': {
                maxWidth: '156px',
                lineHeight: '62px',
            },
            '& h6': {
                fontFamily: 'Poppins-Medium',
                color: '#ffffff',
                fontSize: '20px',
                lineHeight: '30px',
            },
        },
        '& .verifyInputLinkBox': {
            maxWidth: "max-content",
        },
    },
    OtpInputBox: {
        marginTop: '35px',
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        alignItems: 'center',
        '& input': {
            width: '44px !important',
            height: '54px',
            fontSize: '20px',
            textDecoration: '2px solid underline',
            lineHeight: '30px',
            fontFamily: 'Poppins-Regular',
            color: '#ffffff',
            backgroundColor: '#000000',
            border: '1px solid #898989',
            borderRadius: '10px',
            [theme.breakpoints.down('md')]: {
                width: '40px !important',
                height: '48px',
            },
            '& + span': {
                display: 'none',
            },
            '&:not(:last-child)': {
                marginRight: '20px',
                [theme.breakpoints.down('md')]: {
                    marginRight: '10px',
                },
                [theme.breakpoints.down('xs')]: {
                    marginRight: '7px',
                },
            },
        },
    },
    linkBox: {
        '& .resendBtn': {
            padding: '0px',
            textTransform: 'initial',
            textDecoration: 'underline',
            fontFamily: 'Poppins-Regular',
            fontSize: '12px',
            lineHeight: '18px',
        },
        '& a': {
            padding: '0px',
            textTransform: 'initial',
            textDecoration: 'underline',
            fontFamily: 'Poppins-Regular !important',
            fontSize: '12px',
            lineHeight: '18px',
            color: '#ffffff !important',
            backgroundColor: '#000000 !important',
        },
    },
    submitVarifyBtn: {
        '& button': {
            backgroundColor: '#F5EA16',
            width: '100%',
            height: '56px',
            marginTop: '30px',
            fontFamily: 'Poppins-Regular',
            fontSize: '16px',
            lineHeight: '24px',
            textTransform: 'Capitalize',
            borderRadius: '24px',
        },
        '& .MuiButton-contained.Mui-disabled': {
            backgroundColor: '#b5af3c'
        },
    },

    horizontalLine: {
        width: '100%',
        height: '1px',
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        '& p': {
            textAlign: 'center',
            backgroundColor: 'black',
            marginTop: '-9px',
            width: 'max-content',
            margin: 'auto',
            padding: '0px 7px',
            fontFamily: 'Poppins-Regular',
            fontSize: '12px',
            lineHieght: '18px',
            color: 'rgba(255, 255, 255, 0.5)',
        }
    },


});



export default withStyles(styles)(SellerEmailMobileVarification);
export { SellerEmailMobileVarification }
// Customizable Area End
