import React from "react";

import {
    // Customizable Area Start
    Button, Grid, Box, Avatar, Typography, Drawer, Divider, Dialog, DialogContent, RadioGroup, FormControlLabel, Radio, FormGroup, Checkbox, TextField
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Link } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import MessageModalWeb from "../../../components/src/MessageModal.web";
import { createTheme, withStyles, StyleRules } from "@material-ui/core/styles";
import { arrowDown, liveRadar, liveArchive, filterImage, arrowNext, hamburger, liveArchiveBlank, noLiveEventsFound, noRecord } from './assets'
const baseURL = require("../../../framework/src/config.js").baseURL
import { configJSON } from "./LandingPageControllerWeb";
const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
const stylessearch: StyleRules = {
    root: {
        display: "flex",
        width: '100%',
        minHeight: '100vh',
        flexWrap: 'nowrap',
        marginTop: '100px',
        '@media(max-width: 600px)': {
            flexDirection: 'column',
            marginTop: '80px'
        },
        '& .hamburger': {
          display: 'none',
          [theme.breakpoints.down("xs")]: {
            display: "block",
            alignSelf: 'start'
          },
        }
    },
    contentBlockContents: {
        display: "flex",
        alignItems: "center",
        gap: '50px',
        marginTop: "20px",
        overflowX: "auto",
        paddingBottom: "25px",
        "&::-webkit-scrollbar": {
            height: "6px",
        } as const,
        "&::-webkit-scrollbar-track": {
            borderRadius: "10px",
            background: "#D9D9D955"
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#D9D9D9",
            borderRadius: "10px",
        },
    },

    contentBlockNumberContainers: {
        display: "flex",
        alignItems: "center",
        position: "absolute",
        top: "12px",
        left: "12px",
        borderRadius: "15px",
        background: "#FF1F7140",
        padding: "4px 10px"
    },
    contentBlockNumbers: {
        fontSize: "12px",
        fontWeight: 500,
        color: "#FFFFFF",
        marginLeft: "10px"
    },
    contentBlockElement: {
        position: "relative",
        maxWidth: '190px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
    },
    contentBlockArchive: {
        position: "absolute",
        top: "12px",
        right: "12px",
        borderRadius: "25px",
        background: "#00000099",
        padding: "6px 5px 5px 5px",
        cursor: 'pointer'
    },
    contentBlockDesc: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        gap: '12px'
    },
    contentBlockUser: {
        display: "flex",
        alignItems: "center",
        gap: '8px'
    },
    customScrollbars: {
        "&::-webkit-scrollbar": {
            height: "6px",
        },
        "&::-webkit-scrollbar-track": {
            borderRadius: "10px",
            background: "#D9D9D955"
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#D9D9D9",
            borderRadius: "10px",
        },
    },
    contentBlockContainer: {
        [theme.breakpoints.up("sm")]: {
            width: "calc(100vw - 290px)"
        },
        marginTop: "20px",
        [theme.breakpoints.down("sm")]: {
            width: "100vw"
        },
    },
    contentBlockHeading: {
        display: "flex",
        alignItems: "center",
        padding: "0 50px 0 0",
        justifyContent: "space-between",

    },
    dialogBackground: {
        "& .MuiBackdrop-root": {
            background: '#0000004D',
            backdropFilter: "blur(2px)"
        }
    },
    addButton: {
        background: '#F5EA16',
        borderRadius: '50px',
        height: '28px',
        fontSize: '14px',
        lineHeight: '21px',
        padding: '8px 16px',
        color: '#000000',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#F5EA16'
        },
    },
    showResultBtnSearch: {
        width: '224px',
        fontSize: '18px',
        lineHeight: '28px',
        padding: '8px 16px',
        color: '#000000',
        textTransform: 'none',
        background: '#F5EA16',
        borderRadius: '50px',
        height: '53px',
        '&:hover': {
            backgroundColor: '#F5EA16'
        },
    },
    clearButtonSearch: {
        lineHeight: '28px',
        padding: '8px 16px',
        color: '#000000',
        textTransform: 'none',
        borderRadius: '50px',
        border: '0.67px solid #F5EA16',
        height: '53px',
        width: '154px',
        fontSize: '18px',
        fontWeight: 500,
    },
    filterTitleBoxSearch: {
        alignItems: 'center',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '24px',
    },
    filterBoxSearch: {
        alignItems: 'center',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    },
    filterBtnBoxSearch: {
        marginTop: '24px',
        display: 'flex',
        justifyContent: 'space-between',
        gap: '44px',
        width: '100%',
        alignItems: 'center',
    },
    filterOptionBoxSearch: {
        alignItems: 'center',
        width: '100%',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
    },
    filterDialogSearch: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '518px',
        gap: '16px',
        padding: '24px 48px',
        '@media(max-width: 697px)': {
            padding: '16px',
        },
    },
    sortButtonSearch: {
        margin: '0px',
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
        '& .MuiFormControlLabel-label': {
            fontSize: '21px'
        },
        "& .MuiRadio-colorSecondary.Mui-checked": {
            color: '#000000',
        }
    },
    checkBtnSearch: {
        '& .MuiFormControlLabel-label': {
            fontSize: '21px',
            margin: '0px'
        },
        '& .MuiIconButton-label': {
            color: "#F5EA16",
        },
        "& .Mui-checked.MuiCheckbox-root": {
            color: "#F5EA16",
            background: "black"
        },
        "& .MuiCheckbox-root": {
            color: "white",
            background: "white",
            height: "16px",
            width: "16px",
            padding: '6px',
            margin: '12px'
        },
    },
    categoryBoxSearch: {
        height: '400px',
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'hidden',
        "&::-webkit-scrollbar": {
            width: "6px",
        },
        "&::-webkit-scrollbar-track": {
            borderRadius: "10px",
            background: "#D9D9D955"
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#D9D9D9",
            borderRadius: "10px",
        },
    },
    description: {
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',
        height: '36px'
    },
    noDataFoundImg: {
        maxWidth: "200px",
        width: '100%',
        animation: 'fadeIn 2s',
        MozAnimation: 'fadeIn 2s',
        WebkitAnimation: 'fadeIn 2s',
        OAnimation: 'fadeIn 2s',
        msAnimation: 'fadeIn 2s',
    },
    noDataFoundImgBox: {
        width: '100%',
        display: 'flex',
        flexDirection: "column",
        justifyContent: 'center',
        alignItems: "center",
        [theme.breakpoints.down(350)]: {
            justifyContent: 'start',
            marginLeft: '-8px'
        },
    },
    sidebar: {
        '@media(min-width: 600px)': {
            borderRight: '5px solid #D9D9D9',
            marginRight: '-5px',
            paddingLeft: '16px',
            paddingRight: '8px'
        },
    },
    asideMenuTitle: {
        padding: "10px",
        borderBottom: "1px solid #F2F3F6",
        fontFamily: "Poppins",
        fontSize: "18px",
        fontWeight: 400,
        color: "#9695A1"
    },
    asideMenuElement: {
        padding: "10px 0px 10px 12px",
        fontSize: "14px",
        color: "black"
    },
    menuElementTitle: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer"
    },
    asideMenu: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    asideMenuHamburger: {
        width: "70px",
        marginTop: "70px",
        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
    },
    asideMenuSmallScreen: {
        minWidth: "200px",
        width: "200px",
        padding: "20px",
    },
    leftSidebar: {
        '@media(max-width: 600px)': {
            display: "none",
        },
        '@media(min-width: 600px)': {
            display: "block",
            minWidth: "240px"
        },
        minHeight: '100vh',
        borderRight: '5px solid #D9D9D94D',
    },
    rightSideMainDiv: {
        width: '70%',
        '@media(max-width: 600px)': {
            width: '100%'
        }
    },
    inputPriceField: {
        '& ::placeholder': {
            fontFamily: 'Arial',
        },
        "& .MuiOutlinedInput-root": {
            borderRadius: '50px',
            maxWidth: '118px'
        },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #F5EA16'
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #F5EA16'
        },
    },
};
// Customizable Area End

import SearchResultsController, {
    Props,
} from "./SearchResultsController";
class SearchResults extends SearchResultsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End

    }

    // Customizable Area Start
    colors = ["Blue", "Red", "Orange", "White", "Black", "Grey", "Green", "Yellow"]
    sizeDialogBoxSearch = () => {
        const { classes } = this.props;
        const { openSizeFilter, sizeFilter } = this.state;
        return (
            <Dialog
                open={openSizeFilter}
                onClose={this.closeSizeDialogBox}
                PaperProps={{
                    style: {
                        borderRadius: 23,
                    },
                }}
                className={classes.dialogBackground}
            >
                <DialogContent className={classes.filterDialogSearch} style={{ height: '549px', justifyContent: 'space-between' }}>
                    <Box className={classes.filterTitleBoxSearch} style={{ margin: '24px 0px 0px' }} >
                        <Typography style={{ fontSize: '27px', fontWeight: 500 }}>Size</Typography>
                        <CloseIcon onClick={this.closeSizeDialogBox} style={{ cursor: 'pointer' }} />
                    </Box>
                    <FormGroup style={{ gap: '8px', height: '300px', justifyContent: 'space-between' }}>
                        <FormControlLabel
                            control={<Checkbox checked={sizeFilter.includes('XS')} onChange={this.handleSizeChangeSearch} value="XS" className={classes.checkBtn} />}
                            label="XS"
                            className={classes.checkBtnSearch}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={sizeFilter.includes('S')} onChange={this.handleSizeChangeSearch} value="S" className={classes.checkBtn} />}
                            label="S"
                            className={classes.checkBtnSearch}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={sizeFilter.includes('M')} onChange={this.handleSizeChangeSearch} value="M" className={classes.checkBtn} />}
                            label="M"
                            className={classes.checkBtnSearch}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={sizeFilter.includes('L')} onChange={this.handleSizeChangeSearch} value="L" className={classes.checkBtn} />}
                            label="L"
                            className={classes.checkBtnSearch}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={sizeFilter.includes('XL')} onChange={this.handleSizeChangeSearch} value="XL" className={classes.checkBtn} />}
                            label="XL"
                            className={classes.checkBtnSearch}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={sizeFilter.includes('XXL')} onChange={this.handleSizeChangeSearch} value="XXL" className={classes.checkBtn} />}
                            label="XXL"
                            className={classes.checkBtnSearch}
                        />
                    </FormGroup>
                    <Box className={classes.filterBtnBoxSearch} style={{ margin: '0px 0px 24px' }}>
                        <Button className={classes.showResultBtnSearch} onClick={this.getFilteredData}>Show results</Button>
                        <Button className={classes.clearButtonSearch} onClick={this.handleClearSize} >Clear all</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        )
    }
    sortDialogBoxSearch = () => {
        const { classes } = this.props;
        const { openSortFilters, sortBy } = this.state;
        return (
            <Dialog
                open={openSortFilters}
                onClose={this.closeSortDialogBox}
                PaperProps={{
                    style: {
                        borderRadius: 23,
                    },
                }}
                className={classes.dialogBackground}
            >
                <DialogContent className={classes.filterDialogSearch} style={{ height: '595px', justifyContent: 'space-between' }}>
                    <Box className={classes.filterTitleBoxSearch} >
                        <Typography style={{ fontSize: '27px', fontWeight: 500 }}>Sort by</Typography>
                        <CloseIcon onClick={this.closeSortDialogBox} style={{ cursor: 'pointer' }} />
                    </Box>
                    <RadioGroup value={sortBy} onChange={this.handleSortByChange} style={{ gap: '8px', height: '350px', justifyContent: 'space-between' }}>
                        <FormControlLabel value="Best Match" label="Best Match" control={<Radio className={classes.sortButtonSearch} />} className={classes.sortButtonSearch} />
                        <Divider />
                        <FormControlLabel value="Lowest Price" control={<Radio className={classes.sortButtonSearch} />} label="Lowest Price" className={classes.sortButtonSearch} />
                        <Divider />
                        <FormControlLabel value="Highest Price" control={<Radio className={classes.sortButtonSearch} />} label="Highest Price" className={classes.sortButtonSearch} />
                        <Divider />
                        <FormControlLabel value="Newly Listed" control={<Radio className={classes.sortButtonSearch} />} label="Newly Listed" className={classes.sortButtonSearch} />
                    </RadioGroup>
                    <Box className={classes.filterBtnBoxSearch} >
                        <Button className={classes.showResultBtnSearch} onClick={this.getFilteredData}>Show results</Button>
                        <Button className={classes.clearButtonSearch} onClick={this.handleClearSortBy}>Clear all</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        )
    }
    filterDialogBoxSearch = () => {
        const { classes } = this.props;
        const { openFilterBox } = this.state;
        return (
            <Dialog
                open={openFilterBox}
                onClose={this.closeFilterDialogBox}
                PaperProps={{
                    style: {
                        borderRadius: 23,
                    },
                }}
                className={classes.dialogBackground}
            >
                <DialogContent className={classes.filterDialogSearch} style={{ height: '743px', justifyContent: 'space-between' }}>
                    <Box className={classes.filterTitleBoxSearch} style={{ marginBottom: '32px' }} >
                        <Typography style={{ fontSize: '27px', fontWeight: 500 }}>Filter </Typography>
                        <CloseIcon onClick={this.closeFilterDialogBox} style={{ cursor: 'pointer' }} />
                    </Box>
                    <Box className={classes.filterOptionBoxSearch} onClick={this.openSortDialogBox}>
                        <Typography style={{ fontSize: '22px', fontWeight: 500 }}>Sort by</Typography>
                        <img src={arrowNext} style={{ height: '21px', width: '10px' }} />
                    </Box>
                    <Divider />

                    <Box className={classes.filterOptionBoxSearch} onClick={this.openCategoryDialogBox} data-test-id="sellerSidebar">
                        <Typography style={{ fontSize: '22px', fontWeight: 500 }}>Category</Typography>
                        <img src={arrowNext} style={{ height: '21px', width: '10px' }} />
                    </Box>
                    <Divider />
                    <Box className={classes.filterOptionBoxSearch} >
                        <Typography style={{ fontSize: '22px', fontWeight: 500 }}>Ratings</Typography>
                        <img src={arrowNext} style={{ height: '21px', width: '10px' }} />
                    </Box>
                    <Divider />
                    <Box className={classes.filterOptionBoxSearch} onClick={this.openPriceDialogBox}>
                        <Typography style={{ fontSize: '22px', fontWeight: 500 }}>Price</Typography>
                        <img src={arrowNext} style={{ height: '21px', width: '10px' }} />
                    </Box>
                    <Divider />
                    <Box className={classes.filterOptionBoxSearch} onClick={this.openSizeDialogBox}>
                        <Typography style={{ fontSize: '22px', fontWeight: 500 }}>Size</Typography>
                        <img src={arrowNext} style={{ height: '21px', width: '10px' }} />
                    </Box>
                    <Divider />
                    <Box className={classes.filterOptionBoxSearch} onClick={this.openColorDialogBox}>
                        <Typography style={{ fontSize: '22px', fontWeight: 500 }}>Color</Typography>
                        <img src={arrowNext} style={{ height: '21px', width: '10px' }} />
                    </Box>
                    <Divider />
                    <Box className={classes.filterBtnBoxSearch}>
                        <Button className={classes.showResultBtnSearch} onClick={this.getFilteredData}>Show results</Button>
                        <Button className={classes.clearButtonSearch} onClick={this.handleClearAll}>Clear all</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        )
    }
    categoryDialogBoxSearch = () => {
        const { classes } = this.props;
        const { openCategoryFilter, categoryData, subCategoryData, showLoader, subCategoryID } = this.state;
        return (
            <Dialog
                open={openCategoryFilter}
                onClose={this.closeCategoryDialogBox}
                PaperProps={{
                    style: {
                        borderRadius: 23,
                    },
                }}
                className={classes.dialogBackground}
            >
                <DialogContent className={classes.filterDialogSearch} style={{ height: '634px', justifyContent: 'space-between' }}>
                    <Box className={classes.filterTitleBoxSearch} >
                        <Typography style={{ fontWeight: 500, fontSize: '27px' }}>Category</Typography>
                        <CloseIcon onClick={this.closeCategoryDialogBox} style={{ cursor: 'pointer', }} />
                    </Box>
                    <Box className={classes.categoryBoxSearch}>
                        {categoryData.map((items: any, index: any) => (
                            <Box key={index}>
                                <Box style={{ display: 'flex', padding: '24px 0px', justifyContent: 'space-between', cursor: 'pointer' }} onClick={() => this.selectCategory(items.attributes.id, index)} data-testid="categoryButton">
                                    <Typography style={{ fontWeight: 500, fontSize: '16px' }}>{items.attributes.name}</Typography>
                                    <img src={arrowDown} alt="arrow_down" style={{ transform: index === this.state.openItemIndex ? "rotate(180deg)" : "rotate(270deg)" }} />
                                </Box>
                                {index === this.state.openItemIndex &&
                                    <Box style={{ position: 'relative' }}>
                                        {showLoader ? <CircularProgress style={{ color: '#F5EA16' }} />
                                            : <Box style={{ flexWrap: 'wrap', display: 'flex', cursor: 'pointer', paddingBottom: '24px', gap: '8px', }}>
                                                {subCategoryData?.map((item: any,) => (
                                                    <Box style={{ height: '35px', padding: '4px 16px', borderRadius: '50px', border: '0.5px solid #292D32', background: subCategoryID.includes(item.attributes.id) ? "#F5EA16" : "white" }} onClick={() => this.selectSubCategories(item.attributes.id)}>{item.attributes.name}</Box>
                                                ))}
                                            </Box>}
                                    </Box>
                                }
                                <Divider />
                            </Box>
                        ))}
                    </Box>
                    <Box className={classes.filterBtnBoxSearch}>
                        <Button className={classes.showResultBtnSearch} onClick={this.getFilteredData}>Show results</Button>
                        <Button className={classes.clearButtonSearch} onClick={this.handleClearCategory}>Clear all</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        )
    }
    colorDialogBoxSearch = () => {
        const { classes } = this.props;
        const { openColorFilter, colorFilter } = this.state;
        return (
            <Dialog
                open={openColorFilter}
                onClose={this.closeColorDialogBox}
                PaperProps={{
                    style: {
                        borderRadius: 23,
                    },
                }}
                className={classes.dialogBackground}
            >
                <DialogContent className={classes.filterDialogSearch} style={{ height: '549px', justifyContent: 'space-between' }}>
                    <Box className={classes.filterTitleBoxSearch} style={{ margin: '24px 0px 0px' }}>
                        <Typography style={{ fontSize: '27px', fontWeight: 500 }}>Colors</Typography>
                        <CloseIcon onClick={this.closeColorDialogBox} style={{ cursor: 'pointer' }} />
                    </Box>
                    <Box className={classes.filterBoxSearch} style={{ flexWrap: 'wrap', margin: '16px 0px', justifyContent: 'start', gap: '12px', height: '275px', overflowX: 'hidden' }}>
                        {
                            this.colors.map((item: string, index: number) => (
                                <Box style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer', alignItems: 'center', gap: '12px', height: '62px', padding: '15px 18px', borderRadius: '50px', border: colorFilter.includes(item) ? '1.52px solid #000000' : '1.52px solid #DCDCDC' }} key={index} onClick={() => this.handleColorChange(item)}>
                                    <Box style={{ background: item, height: '26px', width: '26px', borderRadius: '6px', border: '1px solid #E2E2E2' }} />
                                    <Typography style={{ fontSize: '21px', color: '#000000' }}>{item}</Typography>
                                </Box>
                            ))
                        }
                    </Box>
                    <Box className={classes.filterBtnBoxSearch} style={{ margin: '0px 0px 24px' }}>
                        <Button className={classes.showResultBtnSearch} onClick={this.getFilteredData}>Show results</Button>
                        <Button className={classes.clearButtonSearch} onClick={this.handleClearColor}>Clear all</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        )
    }
    priceDialogBoxSearch = () => {
        const { classes } = this.props;
        const { openPriceFilter, priceFilter } = this.state;
        return (
            <Dialog
                open={openPriceFilter}
                onClose={this.closePriceDialogBox}
                PaperProps={{
                    style: {
                        borderRadius: 23,
                    },
                }}
                className={classes.dialogBackground}
            >
                <DialogContent className={classes.filterDialogSearch} style={{ height: '625px', justifyContent: 'space-between' }}>
                    <Box className={classes.filterTitleBoxSearch} style={{ margin: '24px 0px 0px' }} >
                        <Typography style={{ fontSize: '27px', fontWeight: 500 }}>Price</Typography>
                        <CloseIcon onClick={this.closePriceDialogBox} style={{ cursor: 'pointer' }} />
                    </Box>
                    <FormGroup style={{ gap: '8px' }}>
                        <FormControlLabel
                            control={<Checkbox checked={priceFilter === '0-500'} onChange={this.handlePriceChangeSearch} value="0-500" className={classes.checkBtnSearch} />}
                            label="0-500"
                            className={classes.checkBtnSearch}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={priceFilter === '501-1000'} onChange={this.handlePriceChangeSearch} value="501-1000" className={classes.checkBtnSearch} />}
                            label="501-1000"
                            className={classes.checkBtnSearch}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={priceFilter === '1001-1500'} onChange={this.handlePriceChangeSearch} value="1001-1500" className={classes.checkBtnSearch} />}
                            label="1001-1500"
                            className={classes.checkBtnSearch}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={priceFilter === '1501-2000'} onChange={this.handlePriceChangeSearch} value="1501-2000" className={classes.checkBtnSearch} />}
                            label="1501-2000"
                            className={classes.checkBtnSearch}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={priceFilter === '2001-2500'} onChange={this.handlePriceChangeSearch} value="2001-2500" className={classes.checkBtnSearch} />}
                            label="2001-2500"
                            className={classes.checkBtnSearch}
                        />
                    </FormGroup>
                    <Box>
                        <Typography style={{ fontSize: '21px', fontWeight: 500 }}>Custom</Typography>
                        <Box style={{ display: 'flex', gap: '16px', margin: '16px 0px', flexWrap: 'wrap' }}>
                            <Box style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
                                <TextField
                                    variant="outlined"
                                    placeholder="₹ 0 "
                                    data-test-id="minprice"
                                    onChange={this.handleMinPriceChange}
                                    className={classes.inputPriceField}
                                    type="number"
                                />
                                <Typography style={{ fontSize: '15px', color: '#AAAAAA' }}>Min.</Typography>
                            </Box>
                            <Box style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
                                <TextField
                                    variant="outlined"
                                    placeholder="₹ 0 "
                                    data-test-id="maxprice"
                                    onChange={this.handleMaxPriceChange}
                                    className={classes.inputPriceField}
                                    type="number"
                                />
                                <Typography style={{ fontSize: '15px', color: '#AAAAAA' }}>Max.</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={classes.filterBtnBoxSearch} style={{ margin: '0px 0px 24px' }}>
                        <Button className={classes.showResultBtnSearch} onClick={this.getFilteredData}>Show results</Button>
                        <Button className={classes.clearButtonSearch} onClick={this.handleClearPriceSearch}>Clear all</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        )
    }

    productViewList = () => {
        const { classes } = this.props;
        return (
            <Box className={classes.rightContainer}>
                <Typography style={{ fontSize: '20px', fontWeight: 400, paddingTop: 20 }}>Products</Typography>
                <Box className={classes.contentBlockContents}>
                    {this.state.responseProductSearch.length > 0 ? (
                        this.state.responseProductSearch.map((item: any, index: number) => (
                            <Box className={classes.contentBlockElement} key={index}>
                                {
                                    this.checkProductStatus(this.state.wishListProduct || [], parseInt(item.id))
                                        ? (
                                            <Box className={classes.contentBlockArchive} data-test-id={`removeWishItemProduct-${index}`} onClick={() => this.deleteProductItem(this.state.wishListProduct, parseInt(item.id))}>
                                                <img src={liveArchive} alt="wished" />
                                            </Box>
                                        ) : (
                                            <Box className={classes.contentBlockArchive} data-test-id={`addWishItemProduct-${index}`} onClick={() => this.updateWishListItem(item.id, item.type)}>
                                                <img src={liveArchiveBlank} alt="not-wished" />
                                            </Box>
                                        )
                                }
                                <Box onClick={()=> this.goToProductDescription(item.id)} data-test-id="gotoProdID">
                                    <img src={`${baseURL}${item.attributes.product_images[0].url}`} style={{ height: '263px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', width: '190px', borderRadius: '10px' }} />
                                </Box>
                                <Box className={classes.contentBlockDesc}>
                                    <Box className={classes.contentBlockUser}>
                                        <Avatar src={item.attributes.host_image} alt="host image" />
                                        <Typography style={{ fontSize: "12px", fontWeight: 500 }}>{item.attributes.host_name}</Typography>
                                    </Box>
                                    <Typography className={classes.description} style={{ fontSize: "12px", fontWeight: 500 }}>{item.attributes.host_bio}</Typography>
                                    <Typography className={classes.description} style={{ fontSize: "12px", color: "#A5A4A4" }}>{item.attributes.description}</Typography>
                                    <Box sx={{ fontSize: '16px', fontWeight: 500, fontFamily: "Arial !important" }}>₹ {item.attributes.selling_price}</Box>
                                </Box>
                            </Box>
                        ))
                    ) :
                        <Grid container>
                            <Grid item xs={12}>
                                <Box className={classes.noDataFoundImgBox}>
                                    <img src={noRecord} className={classes.noDataFoundImg} />
                                    <Typography><strong>{configJSON.productsTitle}</strong></Typography>
                                    <Typography color="textSecondary">{configJSON.productsSubTitle}</Typography>
                                </Box>

                            </Grid>
                        </Grid>
                     }
                </Box>
                <Divider style={{ marginTop: '50px' }} />
            </Box>
        )
    }

    sidebar() {
        const { classes } = this.props;
        const { chooseCatergoriesData } = this.state;
        return (
            <Box className={classes.sidebar}>
                <Typography className={classes.asideMenuTitle}>Categories</Typography>
                {chooseCatergoriesData?.map((items: any, index: any, item: any) => (
                    <div className={classes.asideMenuElement} key={index}>
                        <div data-test-id="sideBarToggle" className={classes.menuElementTitle} onClick={() => this.navigateOnHome(items?.attributes?.id)}>
                            <div>{items?.attributes?.name}</div>
                            <img src={arrowDown} />
                        </div>
                    </div>
                ))}
            </Box>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { activeButton } = this.state;

        const buttonStyle = {
            borderRadius: '23.03px',
            textAlign: 'center',
            fontWeight: '400',
            color: '#000',
            border: '1.396px solid #3D3D3D',
            lineHeight: '24px',

        };

        const activeButtonStyle = {
            ...buttonStyle,
            borderRadius: '23.03px',
            background: '#F5EA16',
            color: '#000',
            border: 'none',
            fontWeight: '600',
            lineHeight: '24px',
        };

        const stylessearch = {
            contentBlockContainers: {
                marginTop: "20px",
            },
            contentBlockHeadings: {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "0 50px 0 0"
            },
            contentBlockTitles: {
                fontSize: "20px",
                fontWeight: 400
            },
            contentBlockContents: {
                marginTop: "20px",
                display: "flex",
                alignItems: "center",
                overflowX: "auto" as any,
                paddingBottom: "25px"
            } as const,
            contentBlockElements: {
                marginRight: "35px",
                position: "relative"
            } as const,
            contentBlockNumberContainers: {
                display: "flex",
                alignItems: "center",
                position: "absolute",
                top: "12px",
                left: "12px",
                borderRadius: "15px",
                background: "#FF1F7140",
                padding: "4px 10px"
            } as const,
            rectangleTag: {
                position: "absolute",
                top: "12px",
            } as const,
            crown: {
                position: "absolute",
                top: "15px",
                left: "9px",
            } as const,
            contentBlockNumbers: {
                fontSize: "12px",
                fontWeight: 500,
                color: "#FFFFFF",
                marginLeft: "10px"
            },
            contentBlockArchives: {
                position: "absolute",
                top: "12px",
                right: "12px",
                borderRadius: "25px",
                background: "#00000099",
                padding: "6px 5px 5px 5px"
            } as const,
            contentBlockDescs: {
                marginTop: "15px",
                maxWidth: "190px"
            },
            contentBlockUsers: {
                display: "flex",
                alignItems: "center"
            },
            contentBlockUserNames: {
                fontSize: "12px",
                fontWeight: 500,
                marginLeft: "10px"
            },
            contentBlockElementTitles: {
                fontSize: "12px",
                fontWeight: 500,
            },
            contentBlockElementDescs: {
                fontSize: "12px",
                fontWeight: 400,
                color: "#A5A4A4"
            },
        }
        return (
            <Grid container className={classes.root}>
                 <div id="myref"></div>
                <Box className={classes.asideMenu}>
                    <Box className={classes.asideMenuHamburger}>
                        <Drawer anchor={'left'} open={this.state.showSidebar} onClose={this.toggleDrawer(false)}>
                            <Box className={classes.asideMenuSmallScreen}>
                                {this.sidebar()}
                            </Box>
                        </Drawer>
                    </Box>
                </Box>
                <Button className={'hamburger'} onClick={this.toggleDrawer(true)}>
                    <img src={hamburger} />
                </Button>
                <Box className={classes.leftSidebar}>
                    {this.sidebar()}
                </Box>
                <Grid className={classes.rightSideMainDiv} style={{ flexGrow: 1, boxShadow: 'none', padding: '30px 0px 0px 30px' }}>
                    <Typography style={{ padding: '12px 12px 0px 0px' }}>Search results for '<strong>{this.state.searchValue}</strong>'</Typography>
                    <Box className={classes.buttonContainer} style={{ flex: '1', display: 'flex', gap: '20px', alignItems: 'center', justifyContent: 'space-between' }}>
                        {this.filterDialogBoxSearch()}
                        {this.sortDialogBoxSearch()}
                        {this.sizeDialogBoxSearch()}
                        {this.colorDialogBoxSearch()}
                        {this.priceDialogBoxSearch()}
                        {this.categoryDialogBoxSearch()}
                        <div>
                            <Button
                                variant="outlined"
                                data-testid="toptest"
                                //@ts-ignore
                                style={{ ...(activeButton === 'Top' ? activeButtonStyle : buttonStyle), margin: '12px 15px' }}
                                onClick={() => this.handleTopButtonClick('Top')}
                            >
                                Top
                            </Button>
                            <Button variant="outlined" data-testid="producttest"
                                //@ts-ignore
                                style={{ ...(activeButton === 'Products' ? activeButtonStyle : buttonStyle), margin: '12px 15px' }}
                                onClick={() => this.handleProductButtonClick('Products')}
                            >
                                Products
                            </Button>
                            <Button
                                variant="outlined"
                                data-testid="showtest"
                                //@ts-ignore
                                style={{
                                    ...(activeButton === 'Shows' ? activeButtonStyle : buttonStyle),
                                    margin: '12px 15px' // Adjust the spacing as needed
                                }}
                                onClick={() => this.handleShowButtonClick('Shows')}
                            >
                                Shows
                            </Button>
                            <Button
                                variant="outlined"
                                //@ts-ignore
                                style={{ ...(activeButton === 'Users' ? activeButtonStyle : buttonStyle), margin: '12px 15px' }}
                            //onClick={() => this.handleButtonClick('Users')} 
                            >
                                Users
                            </Button>
                        </div>
                        <div>
                            <img src={filterImage} onClick={this.openFilterDialogBox} style={{ marginRight: '50px', }} />
                        </div>
                    </Box>

                    <Grid>
                        {(activeButton === 'Shows' || activeButton === 'Top') && (
                            <div style={stylessearch.contentBlockContainers} className={classes.contentBlockContainer}>
                                <div style={stylessearch.contentBlockContainers} className={classes.contentBlockContainer}>
                                    <div style={stylessearch.contentBlockHeadings}>
                                        <div style={stylessearch.contentBlockTitles}>Live shows </div>
                                    </div>
                                    <div style={stylessearch.contentBlockContents} className={classes.customScrollbars}>
                                        {this.state.responseShowSearch.length > 0 ? (
                                            this.state.responseShowSearch.map((item: any, index: any) => (
                                                <div style={stylessearch.contentBlockElements} key={index} >
                                                    <div style={stylessearch.contentBlockNumberContainers}>
                                                        <img src={liveRadar} alt="live reader" />
                                                        <div style={stylessearch.contentBlockNumbers}>24</div>
                                                    </div>
                                                    {
                                                        this.checkWishStatus(this.state.wishListLiveStream || [], parseInt(item.id))
                                                            ? (
                                                                <Box className={classes.contentBlockArchive} data-test-id={`removeWishItem-${index}`} onClick={() => this.deleteWishItem(this.state.wishListLiveStream, parseInt(item.id))}>
                                                                    <img src={liveArchive} alt="wished" />
                                                                </Box>
                                                            ) : (
                                                                <Box className={classes.contentBlockArchive} data-test-id={`addWishItem-${index}`} onClick={() => this.updateWishListItem(item.id, item.type)}>
                                                                    <img src={liveArchiveBlank} alt="not-wished" />
                                                                </Box>
                                                            )
                                                    }
                                                    <Link to={`BuyersLiveStreaming?id=${item.id}`}>
                                                        <img style={{ height: "263", width: "193", borderRadius: "10px", border: "none" }} src={item.attributes.thumbnail} alt="thumbnail" />
                                                    </Link>
                                                    <div style={stylessearch.contentBlockDescs}>
                                                        <div style={stylessearch.contentBlockUsers}>
                                                            <img style={{ height: "24", width: "24", borderRadius: "50px" }} src={item.attributes.host_image} alt="host image" />
                                                            <div style={stylessearch.contentBlockUserNames}>{item.attributes.host_name}</div>
                                                        </div>
                                                        <div style={stylessearch.contentBlockElementTitles}>{item.attributes.title}</div>
                                                        <div style={stylessearch.contentBlockElementDescs}>{item.attributes.description}</div>
                                                    </div>
                                                </div>
                                            ))
                                        ) :
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Box className={classes.noDataFoundImgBox}>
                                                        <img src={noLiveEventsFound} className={classes.noDataFoundImg} />
                                                        <Typography><strong>{configJSON.liveEventsTitle}</strong></Typography>
                                                        <Typography color="textSecondary">{configJSON.liveEventsSubTitle}</Typography>
                                                    </Box>
                                                </Grid>                                              
                                            </Grid>
                                        }
                                    </div>
                                </div>
                            </div>
                        )}


                        {(activeButton === 'Products' || activeButton === 'Top') && (
                            this.productViewList()
                        )}
                    </Grid>
                </Grid>
                <MessageModalWeb
                    displayPopup={this.state.messageModel}
                    closeErrorPopup={this.closeMessageModal}
                    errorMessage={this.state.message} />
            </Grid>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styling = (theme: any) => ({
    customScrollbars: {
        "&::-webkit-scrollbar": {
            height: "6px",
        },
        "&::-webkit-scrollbar-track": {
            borderRadius: "10px",
            background: "#D9D9D955"
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#D9D9D9",
            borderRadius: "10px",
        },
    },
    contentBlockContainer: {
        [theme.breakpoints.down("sm")]: {
            width: "100vw"
        },
        [theme.breakpoints.up("sm")]: {
            width: "calc(100vw - 290px)"
        },
    },
    buttonContainer: {
        padding: '32px',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '20px',
        position: 'relative'
    },
});
export default withStyles(stylessearch)(SearchResults)
export { SearchResults }
// Customizable Area End