import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  TextField,
  styled,
  FormControl,
  FormGroup,
  Grid,
  Switch,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Select,
  Slider,
  Divider,
  MenuItem
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, withStyles, Theme,makeStyles ,createStyles} from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import {videoIcon,logoutIcon} from "./assets"
import {  bellIcon, calendarIcon, searchIcon, hamburgerIc } from "../../InventoryManagement3/src/assets";
import {congratulations, downArrowIc} from "../../landingpage/src/assets";
import  PolicyAndTerms  from "../../../components/src/PolicyAndTerms.web";

const PrettoSlider = withStyles({
  root: {
    color: '#52af77',
    height: 8,
  },
  thumb: {
    height: 27,
    width: 10,
    borderRadius: "5px",
    backgroundColor: '#000',
    border: '2px solid black',
    marginTop: -8,
    marginLeft: -12,
    color: 'black',
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
    background: '#F5EA16'
  },
  rail: {
    height: 8,
    borderRadius: 4,
    background: '#D9D9D9',
    opacity: 1,
  },
})(Slider);
const CustomBox = styled(Box)({
  marginTop: 20,
  gap: 20,
  display: 'flex',
  '& fieldset legend': {
    width: 'min-content'
  },
  '& fieldset': {
    borderRadius: '10px'
  },
  "& .MuiSelect-outlined.MuiSelect-outlined": {
    width: "100%"
  },
  "& .MuiSlider-root": {
    width: "350px"
  },
  '& .MuiFormControl-root': {
    flex: 1
  }
});


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

export const webStyles = (theme: Theme) => ({
  settingsContainer: {
    display: "flex",
    flexWrap: 'wrap' as const,
    justifyContent: "space-between" as const,
    margin: "0em 1em 1em 0em",
    alignItems:"center"
  },
  settingsInnerContainer: {
    paddingTop: "4%",
    width: "100%",
    paddingLeft: "2em",
  },
  sideBar: {
    filter: 'drop-shadow(6px 0px 20px rgba(0, 0, 0, 0.05))',
    height: '100vh',
    background: "gray",
  },
  addProductMainSection: {
    height: "100vh",
    display: "flex",
    justifyContent: "center" as const,
    alignItems: "center" as const
  },
  addProductInnerSection: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center" as const
  },
  iconStyles: {
    width: '48px',
    height: '48px',
    borderRadius: '10px',
    border: '1px solid var(--0, #FFF)',
    background: 'var(--0, #FFF)',
    boxShadow: '0px 0px 20px 1px rgba(0, 0, 0, 0.10)',
    display: "flex",
    alignItems: "center" as const,
    justifyContent: "center" as const,
    margin: "0em 0.5em"
  },
  noProductsText: {
    color: '#444',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  addProductsButton: {
    width: '21em',
    height: '3em',
    background: '#F5EA16',
    borderRadius: "25px",
    color: '#444',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    pointer: "cursor",
    textTransform: "none" as const,
    marginTop: "2em",
    "&:hover": {
      background: '#F5EA16',
    }
  },
  addProductImage: {
    marginBottom: "2em"
  },
  settingsMainContainer: {
    margin: "5em 0em 0em",
    display:"flex"
  },
  searchInventory: {
    width: "90%",
    borderRadius: '10px',
    border: '1px solid #FFF',
    background: '#FFF',
    boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  [`@media (min-width: 992px)`]: {
    hideHamburger: {
      display: 'none'
    },
  },
  hideSideHamburgerSection: {
    display: "flex",
    justifyContent: "end"
  },
  settingsTitle:{
    color: '#444',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: 'Poppins !important',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal'
  },
  audio:{
    color: '#444',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: 'Poppins !important',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal'
  },
  volumeContainer:{
    display: "flex",
    gap: 20,
    marginTop:"1.5em"
  },
  volumeControllContainer:{
    marginRight: "20px",
    flex: 1
  },
  volumeControllContainerText:{
    color: '#444',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: 'Poppins !important',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal'
  },
  micTest:{
    marginTop:"1.5em",
    color: '#444',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: 'Poppins !important',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal'
  },
  micTestDescription:{
    marginBottom: "1.75em", 
    color: 'rgba(68, 68, 68, 0.70)',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: 'Poppins !important',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal'
  },
  letsCheckContainer:{
    display: 'flex',
    gap: 20, 
    alignItems: 'center',
    overflow: 'hidden'
  },
  letsCheckBtn:{
    borderRadius: '20px',
    background: '#F5EA16',
    textTransform:"none" as const,
        "&:hover":{
            background: '#F5EA16',
    } ,
    padding:"0.25em 1.5em"
    
  },
  testVideoBtn:{
    borderRadius: '20px',
    background: '#F5EA16',
    textTransform:"none" as const,
    cursor:"pointer",
    "&:hover":{
            background: '#F5EA16',
    } ,
    padding:"0.25em 0.75em"
    
  },
   soundBar:{
    width: 7,
    height: 21,
    background:" var(--Base-Grey-5, #D9D9D9)",
    borderRadius: "20px "
  },
  soundBarContainer:{
    display:'flex',
    gap:"7px"
  },
  videoContainer:{
    borderTop: '1px solid #dcdcdc',
    paddingTop: 16,
    marginTop: 24,
    display: 'flex',
    flexDirection: 'column'  as const
  },
  videoTitle:{
    color: '#444',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: 'Poppins !important',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    marginTop:"0.75em"
  },
  videoTestContainer:{ 
    width: '100%',
    height: 323,
    borderRadius: "20px",
    background: 'rgba(217, 217, 217, 0.40)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center' 
  },
  videoTest:{
  color: '#444',
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: 'Poppins !important',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  marginTop:"1em"
  },
  support:{
    color: '#444',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: 'Poppins !important',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    margin:"2em 0em"
  },
  notification:{
    color: '#555',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: 'Poppins !important',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal'
  },
  notificationLabelContainer:{
    display: 'flex',
    justifyContent:'space-between',
    gap: 5,
    alignItems: 'center',
    marginTop: 10
  },
  notificationLabelText:{
    color: '#444',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: 'Poppins !important',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal'
  },
  readTandC:{
    color: '#444',
    fontFamily: 'Poppins !important',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '19px',
    margin:"1em 0em"
  },
  privacyPolicy:{
    color: '#444',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: 'Poppins !important',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal'
  },
  marginTB:{
    margin:"1.5em 0em"
  },
  buttonsContainer:{
    display: 'flex',
    gap: 20,
    marginTop: 20,
    marginBottom: 20,
    paddingBottom: 20
  },
  policyBtn:{
    display: 'inline-flex',
    padding: '4px 20px',
    alignItems: 'flex-start',
    gap: '10px',
    borderRadius: '20px',
    background: '#F5EA16',
    textTransform: "none" as const,
    cursor:"pointer",
    "&:hover":{
      background: '#F5EA16',
    } ,

  },
  termsBtn:{
    display: 'inline-flex',
    padding: '4px 20px',
    alignItems: 'flex-start',
    gap: '10px',
    borderRadius: '20px',
    border: '1px solid #444',
    textTransform: "none" as const,
    cursor:"pointer",
    "&:hover":{
      background: 'fff',
    } ,
  },
  logoutAccountTitle:{
    color: '#444',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: 'Poppins !important',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal'
  },
  logoutHelperText:{
    fontFamily: 'Poppins !important',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '19px',
    margin:"2em 0em"
  },
  logoutBtn:{
    background: '#E46262',
    color: 'white',
    display: 'flex',
    alignItems:'center',
    border: '1px solid #dcdcdc',
    minWidth: 'max-content',
    borderRadius: '10px',
    padding: '10px 30px',
    textTransform: "none" as const,
    "&:hover":{
      background: '#E46262',
    } ,
    cursor:"pointer"
  },
  formControl:{
    width:"100%",
    marginTop:"1em"
  },
  auidoContainer:{
    display:'flex',
    jusitfyContent:"space-between"
  }

});


export const OnOffSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#FFFF00', 
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        backgroundColor: 'black', 
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 24,
      height: 24,
      backgroundColor: 'black !important', 
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  })
)(({ classes, ...props }: any) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});


// Customizable Area End

import SettingsController, {
  Props,
  configJSON,
} from "./SettingsController";
import SellerSidebar from "../../landingpage/src/SellerSidebar.web";
import { config } from "process";

class Settings extends SettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes } = this.props;
    return (
      // Customizable Area Start
      <Box data-test-id="mainContainer" className={classes.settingsMainContainer}>
        <div className="dashboard-outer">
          <div className="dashboard-inner">
          <div className="dashboard-outer">
                            <div className="dashboard-inner">
                              <SellerSidebar
                                navigation={""}
                                id={""}
                                classes={undefined}
                                isOpen={this.state.isOpen}
                                data-test-id="sellerSidebar"
                                activetabName= "Settings"
                                closeModal={this.toggleSideBar}
                                />
                            </div>
                        </div>
          </div>
        </div>

        <Box className={classes.settingsInnerContainer}>
                        <Box className={classes.hideSideHamburgerSection}>
                                    <IconButton
                                        className={`${classes.hideHamburger}`}
                                        data-test-id="hamburger-btn"
                                        onClick={() => this.toggleSideBar()}
                                    >
                                        <img src={hamburgerIc} alt="hamburger" />
                                    </IconButton>
                        </Box>
          <Box className={classes.settingsContainer}>
            <Box component='span' className={classes.settingsTitle}>{configJSON.settings}</Box>
            <Box component='span' style={{ display: "flex" }}>
              
              <Box component="span" className={classes.iconStyles}><img src={calendarIcon} alt="calendarIcon" /></Box>
              <Box component="span" className={classes.iconStyles}><img src={bellIcon} alt="bellIcon" /></Box>
            </Box>
          </Box>
          <Grid container spacing={4}> 
            <Grid item xs={12} md={12} lg={8}>
                <Typography className={classes.audio}>
                  {configJSON.audio}
                </Typography>
                <Box className={classes.auidoContainer}>
                  <FormControl variant="outlined" className={classes.formControl} style={{marginRight:"1em"}}>
                  <InputLabel id="demo-simple-select-outlined-label">{configJSON.inputDevice}</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={1}
                    label={configJSON.inputDevice}
                  >
                   {configJSON.audioInputDropdown?.map((eachoption:{id:string,type:"string"}) => (
                          <MenuItem value={eachoption.id}>{eachoption.type}</MenuItem>
                    ))}
                  </Select>
                  </FormControl>
                  <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">{configJSON.outputDevice}</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={1}
                    label={configJSON.outputDevice}
                  >
                   {configJSON.audioOutputDropdown?.map((eachoption:{id:string,type:"string"}) => (
                          <MenuItem value={eachoption.id}>{eachoption.type}</MenuItem>
                    ))}
                  </Select>
                  </FormControl>
                </Box>
                <Box className={classes.volumeContainer}>
                  <Box className={classes.volumeControllContainer}>
                    <Typography gutterBottom className={classes.volumeControllContainerText}>{configJSON.inputVolume}</Typography>
                    <PrettoSlider valueLabelDisplay="auto" aria-label="pretto slider" defaultValue={20} />
                  </Box>
                  <Box className={classes.volumeControllContainer}>
                    <Typography gutterBottom className={classes.volumeControllContainerText} >{configJSON.outputVolume}</Typography>
                    <PrettoSlider valueLabelDisplay="auto" aria-label="pretto slider" defaultValue={20} />
                  </Box>
                </Box>
                <Typography className={classes.micTest}>
                  {configJSON.micTest}
                </Typography>
                <Typography className={classes.micTestDescription}>
                  {congratulations.micTestDescriptionText}
                </Typography>
                <Box className={classes.letsCheckContainer} >
                  <Button className={classes.letsCheckBtn}>{configJSON.letsCheck}</Button>
                  <Box className={classes.soundBarContainer}>
                    {[...Array(35)].map((_, index) => (
                      <Box component={"span"} key={index} className={classes.soundBar}></Box>
                    ))}
                  </Box>
                </Box>
                <Box className={classes.videoContainer}>
                  <Typography className={classes.videoTitle}>
                    {configJSON.video}
                  </Typography>
                  <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">{configJSON.inputDevice}</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={1}
                    label={configJSON.inputDevice}
                  >
                   {configJSON.videoDropdown?.map((eachoption:{id:string,type:"string"}) => (
                          <MenuItem value={eachoption.id}>{eachoption.type}</MenuItem>
                    ))}
                  </Select>
                  </FormControl>
                  <Typography className={classes.videoTest}>{configJSON.videoTest}</Typography>
                  <Box className={classes.videoTestContainer}>
                      <Box>
                      <Box>
                       <img src={videoIcon} />
                      </Box>
                      <Box>
                        <Button className={classes.testVideoBtn}>{configJSON.testVideo}</Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              
            </Grid>
            <Grid item xs={12} md={12} lg={4}>
              <Typography className={classes.notification}>
                {configJSON.notifications}
              </Typography>
              <Box className={classes.notificationLabelContainer}>
                <Typography className={classes.notificationLabelText} >{configJSON.allNotification}</Typography>
                    <OnOffSwitch
                      checked={this.state.allNotification}
                      onChange={this.handleOnOff("allNotification")}
                      data-test-id="allNotification"
                   />
              </Box>
              <Box className={classes.notificationLabelContainer}>
                <Typography  className={classes.notificationLabelText} >{configJSON.emailNotification}</Typography>
                <OnOffSwitch
                      checked={this.state.emailNotification}
                      onChange={this.handleOnOff("emailNotification")}
                      data-test-id="emailNotification"
                   />
              </Box>
              <Box className={classes.notificationLabelContainer}>
              <Typography  className={classes.notificationLabelText} >{configJSON.pushNotification}</Typography>
              <OnOffSwitch
                      checked={this.state.pushNotification}
                      onChange={this.handleOnOff("pushNotification")}
                      data-test-id="pushNotification"
                   />
              </Box>
              <Divider className={classes.marginTB}/>
              <Typography className={classes.privacyPolicy}>
                {configJSON.privacyPolicy}
              </Typography>
              <Typography className={classes.readTandC}>
                {configJSON.readTandC}
              </Typography>
              <Box className={classes.buttonsContainer}>
                <Button data-test-id="policyBtn" className={classes.policyBtn} onClick={()=>this.handlePolicyOrTerms("policy")}>{configJSON.policy}</Button>
                <Button  data-test-id="termsBtn" className={classes.termsBtn} onClick={()=>this.handlePolicyOrTerms("terms")} >{configJSON.tAndC}</Button>
              </Box>
              <Divider className={classes.marginTB}/>
              <Typography className={classes.logoutAccountTitle}>
                  {configJSON.logoutAccount}
              </Typography>
              <Typography className={classes.logoutHelperText}>
                  {configJSON.logoutHelperText}
              </Typography>
              <Box>
              <Button 
                className={classes.logoutBtn}
                startIcon={<img src={logoutIcon}/>}
                onClick={this.handleLogout}
                data-test-id="logoutBtn"
              >
               {configJSON.logout}
              </Button>
              </Box>
              
          
            </Grid>
            <Grid item xs={12} md={12} lg={8}>
              <Typography className={classes.support}>
                {configJSON.support}
              </Typography>
                <Box className="accordion-wrap">
                      {this.state.faqData?.map((eachFaq:any) => (
                        <Accordion className="custom-accordion" key={eachFaq.id}>
                          <AccordionSummary className="accordion-head">
                            <i className="arrow">
                              <img src={downArrowIc} alt="Down Arrow" />
                            </i>
                            {eachFaq.title}
                          </AccordionSummary>
                          <AccordionDetails >
                            {eachFaq.description}
                          </AccordionDetails>
                        </Accordion>
                      ))}
                 </Box>

            </Grid>
          </Grid>
  
        </Box>
        <PolicyAndTerms 
        navigation={this.props.navigation}
        policyOrTermsData={this.state.policyOrTermsData}
        policyOrTermsOpen={this.state.policyOpen}
        handleClose={this.handlePolicyorTermsClose} />
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export default withStyles(webStyles)(Settings)
export { Settings }
// Customizable Area End
