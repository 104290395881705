import React from "react";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { Switch } from '@material-ui/core';

export const StyledSwitch = withStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 49,
            height: 27,
            padding: 0,
            margin: theme.spacing(1),
        },
        switchBase: {
            padding: "3px 0 3px 4px",
            '&$checked': {
                transform: 'translateX(16px)',
                color: theme.palette.common.white,
                '& + $track': {
                    backgroundColor: '#FFFF00',
                    opacity: 1,
                    border: 'none',
                },
            },
            '&$focusVisible $thumb': {
                backgroundColor: 'black',
                border: '6px solid #fff',
            },
        },
        thumb: {
            width: 20,
            height: 20,
            backgroundColor: '#444444 !important',
        },
        track: {
            borderRadius: 26 / 2,
            border: `1px solid ${theme.palette.grey[400]}`,
            backgroundColor: theme.palette.grey[50],
            opacity: 1,
            transition: theme.transitions.create(['background-color', 'border']),
        },
        checked: {},
        focusVisible: {},
    })
)(({ classes, ...props }: any) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

