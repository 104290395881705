// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";

// Customizable Area End

export const configJSON = require("./config");

export interface Props{
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: Record<string,string>
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    loading:boolean
    isSideBarOpen:boolean
    showTitle:string,
    description:string
    thumbnailImage: null | File
    videoFile: null | File;
    errorModal:boolean
    errorMessage:string
    sucessModal:boolean
    primaryCategory: string;
    subCategory: string,
    categoriesList:Array<any>
    subCategoriesList:Array<any>
    date:any
    time:string  | Blob
    addTags: string[],
    addTagsValue: string,
    muteWordsList:string[],
    mutedWordsValue:string,
    explicitContent: boolean,
    muteWords: boolean,
    privateEvent: boolean,
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class CreateStoryController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getCategoriesId: string =""
    getSubCategoriesId: string =""
    createScheduleLiveMsgId:string=""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area End
        ];

        // Customizable Area Start
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        // Customizable Area End

        this.state = {
            // Customizable Area Start
            loading:false,
            isSideBarOpen:true,
            showTitle:"",
            description:"",
            thumbnailImage: null,
            videoFile: null,
            errorModal:false,
            sucessModal:false,
            errorMessage:'',
            primaryCategory: "default",
            subCategory: "default",
            categoriesList:[],
            subCategoriesList:[],
            date:null,
            time:"",
            addTags: [],
            addTagsValue: "",
            muteWordsList:[],
            mutedWordsValue:"",
            explicitContent: false,
            muteWords: false,
            privateEvent: false,
            // Customizable Area End
        };

    }


    // Customizable Area Start

    handleSideBar = () => {
        this.setState({ isSideBarOpen: !this.state.isSideBarOpen })
    }
    handleBackNavigation=()=>{
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage),"LiveStreaming");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
    }

    handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const imageFile = files[0];
            if (imageFile.size <= 5 * 1024 * 1024) {
                this.setState({ thumbnailImage: imageFile });
            } else {
                this.setState({errorModal:true,errorMessage:configJSON.validateMessageForImage})
            }
        } 
    };

    handleRemoveImage = () => {
        this.setState({ thumbnailImage: null });
    };
    errorModalClose=()=>{
        this.setState({errorModal:false,errorMessage:''})
    }
    sucessMessageClose=()=>{
      this.setState({sucessModal:false})
     }

    handleSwitchChange = (fieldName: keyof S) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        this.setState({
            [fieldName]: event.target.checked,
        } as unknown as Pick<S, keyof S>);
        if (fieldName === "muteWords" && !event.target.checked) {
            this.setState(state => ({
                muteWordsList: [],

            }))
        }
    };
    // Customizable Area End
}