import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    drawerOpen: boolean;
    orderType: number;
    statusFilter: string;
    loading: boolean;
    openTunedBox: boolean;
    orderListData: any;
    searchValue: string;
    all: number;
    cancelled: number;
    onHold: number;
    pending: number;
    shipped: number;
    toShip: number;
    paginationCount: number;
    pageCount: number;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class SellerOrderListController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getOrderListApiCallId: string = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
        ];

        this.state = {
            drawerOpen: true,
            orderType: 0,
            statusFilter: '',
            loading: false,
            openTunedBox: false,
            orderListData: [],
            searchValue: '',
            all: 0,
            cancelled: 0,
            onHold: 0,
            pending: 0,
            shipped: 0,
            toShip: 0,
            paginationCount: 0,
            pageCount: 1
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getOrderListApiCallId) {
                this.setState({ loading: false });
                if (responseJson.data) {
                    this.setState({
                        orderListData: responseJson.data,
                    });
                }
                if (responseJson.filters_count) {
                    this.setState({
                        all: responseJson.filters_count.all,
                        cancelled: responseJson.filters_count.cancelled,
                        onHold: responseJson.filters_count.on_hold,
                        pending: responseJson.filters_count.pending,
                        shipped: responseJson.filters_count.shipped,
                        toShip: responseJson.filters_count.to_ship,
                        paginationCount: Math.ceil(responseJson.meta.total_pages),
                    });
                    if (this.state.paginationCount == 0) {
                        this.setState({
                        })
                    }
                }
            }

        }
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        this.getOrderList();
        // Customizable Area End
    }

    // Customizable Area Start
    closeDrawer = () => {
        this.setState({
            drawerOpen: !this.state.drawerOpen
        })
    }

    handleOrderTypeChange = (event: any, newValue: number) => {
        this.setState({
            orderType: newValue,
            openTunedBox: true
        });
    };

    handleStatusFilter = (status: string) => {
        this.setState({
            statusFilter: status,
            searchValue: '',
            pageCount: 1
        }, () => {
            this.getOrderList();
        })
    }

    handleChangePagiation = (event: any, value: number) => {
        this.setState({
            pageCount: value
        }, () => {
            this.getOrderList();
        });
    }

    HandleSingleOrderStatus = (itemId: any) => {
        this.props.navigation.push('SingleOrderStatus', `?item_id=${encodeURIComponent(itemId)}`, encodeURIComponent(itemId))
    }

    handleSearchValue = (event: any) => {
        this.setState({
            searchValue: event.target.value
        })
    }

    handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            this.getOrderList();
        }
    }

    closeTunedBox = () => {
        this.setState({
            openTunedBox: false,
            orderType: 0
        });
    }

    getOrderList = async () => {
        this.setState({ loading: true });
        
        const { searchValue, statusFilter } = this.state;

        const queryParams = [];
        if (searchValue) queryParams.push(`search=${searchValue}`);
        if (statusFilter) queryParams.push(`status=${statusFilter}`);
        queryParams.push(`page=${this.state.pageCount}`);
        queryParams.push(`per_page=15`);
        const queryString = queryParams.length > 0 ? queryParams.join("&") : "";

        const header = { "Content-Type": "application/json", "token": await getStorageData("singupLogin") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getOrderListApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_shopping_cart/order_items?${queryString}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}
