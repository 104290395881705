import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  tabValue: number;
  filterStatus: string;
  orderDataList: any;
  openBox: boolean;
  searchValue: string;
  loading: boolean;
  all: number
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisedOrderStatusController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getOrderDataListApiCallId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      tabValue: 0,
      filterStatus: '',
      orderDataList: [],
      openBox: false,
      searchValue: '',
      loading: false,
      all: 0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallID = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJsonData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallID === this.getOrderDataListApiCallId) {
        this.setState({ loading: false });
        if (responseJsonData.data) {
          this.setState({
            orderDataList: responseJsonData.data,
          });
        }
        if (this.state.filterStatus === ''){
          this.setState({
            all: responseJsonData.data.length,
          });
        }
      }

    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  handleChange = (event: any, newValue: any) => {
    this.setState({
      tabValue: newValue,
      openBox: true
    })
  }

  async componentDidMount() {
    const headingElement = document.getElementById("myref")
    if(headingElement) headingElement.scrollIntoView({behavior: 'smooth'})
    this.getOrderDataList();
  }

  handleFilterStatus = (status: string) => {
    this.setState({
      filterStatus: status,
      searchValue: ''
    }, () => {
      this.getOrderDataList();
    })
  }

  HandleSingleOrderStatus = (itemId: any) => {
    this.props.navigation.push('BuyerOrderDetails', `?item_id=${encodeURIComponent(itemId)}`, encodeURIComponent(itemId))
  }

  closeBox = () => {
    this.setState({
      openBox: false,
      tabValue: 0
    })
  }

  handleSearchFieldValue = (event: any) => {
    this.setState({
      searchValue: event.target.value
    },()=>{
      if (this.state.searchValue === '') {
        this.getOrderDataList();
      }
    })
  }

  handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      this.getOrderDataList();
    }
  }

  getOrderDataList = async () => {
    this.setState({ loading: true });

    const { searchValue, filterStatus } = this.state;

    const queryParams = [];
    if (searchValue) queryParams.push(`search=${searchValue}`);
    if (filterStatus) queryParams.push(`status=${filterStatus}`);
    const queryString = queryParams.length > 0 ? queryParams.join("&") : "";

    const header = { "Content-Type": "application/json", token: await getStorageData("buerLoginToken") };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getOrderDataListApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_order_management/order_items?${queryString}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}
