import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    drawerIsOpen: boolean;
    orderData: any;
    orderItemId: any;
    message: string;
    loading: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class SingleOrderStatusController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getSingleOrderDetailsApiCallId: string = '';
    changeOrderStatusDetailsApiCallId: string = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
        ];

        this.state = {
            drawerIsOpen: true,
            orderData: {},
            orderItemId: '',
            message: '',
            loading: false
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getSingleOrderDetailsApiCallId) {
               
                if (responseJson.data) {
                    this.setState({
                        orderData: responseJson.data?.attributes,
                    });
                }
                this.setState({ loading: false });
            } else if (apiRequestCallId === this.changeOrderStatusDetailsApiCallId) {
              
                if (responseJson.message) {
                    this.getSingleOrderDetails()
                }
                this.setState({ loading: false });
            }

        }
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        const queryParameters = new URLSearchParams(window.location.search)
        const searchName = await queryParameters.get("item_id")
        console.log(searchName,"searchName===>")
        if (searchName !== null) {
            this.setState({
                orderItemId: searchName
            }, () => {
                this.getSingleOrderDetails();
            })
        }
        // Customizable Area End
    }

    // Customizable Area Start
    closeSidebar = () => {
        this.setState({
            drawerIsOpen: !this.state.drawerIsOpen
        })
    }

    goBackOnOrderList = () => {
        this.props.navigation.navigate("SellerOrderList")
    }
    
    handleOnHoldSwitch=(event:React.ChangeEvent<HTMLInputElement>)=>{
        if (event?.target.checked) {
            this.changeStatus("on_hold");
        } else {
            this.changeStatus("accept");
        }
    }

    changeStatus = (status: string) => {
            this.changeOrderStatusDetails(status)
    }


    getSingleOrderDetails = async () => {
        this.setState({ loading: true })
        const header = { "Content-Type": "application/json", "token": await getStorageData("singupLogin") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getSingleOrderDetailsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), 'bx_block_shopping_cart/order_items/' + this.state.orderItemId);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    changeOrderStatusDetails = async (orderStatus: any) => {
        this.setState({ loading: true })
        const { orderItemId } = this.state;
        const header = { "Content-Type": "application/json", "token": await getStorageData("singupLogin") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.changeOrderStatusDetailsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_shopping_cart/order_items/${orderItemId}/${orderStatus}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}
