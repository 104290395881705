import React from "react";

// Customizable Area Start
import {Box, Typography, styled, RadioGroup, Radio, Button, FormControlLabel,Dialog,TextField} from '@material-ui/core';
import Slider from "react-slick";
import {starImg, storeCheckImg, storeLocationImg, righArrowImg, location,dropDown,searchIcon,locationBlackIcon} from "./assets"
import { configJSON, settings, Store as StoreI, Category as CategoryI} from "./CfdigitalmallController";
import Loader from "../../../components/src/Loader.web";
let config = require('../../../framework/src/config')
import MessageModalWeb from "../../../components/src/MessageModal.web";
// Customizable Area End

import CfdigitalmallController, {
  Props,
} from "./CfdigitalmallController";

export default class Cfdigitalmall extends CfdigitalmallController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  showImageSlider = () => {
    return(
      <CarouselContainer >
        <Slider {...settings}>
          {
            this.state.banners?.map((crousel) => (
              <Slide>
              <img className="bannerImg" src={config.baseURL + crousel.attributes.banner} alt={crousel.attributes.name} data-test-id="bannerId" />
              </Slide>
            ))
          }
        </Slider>
      </CarouselContainer>
    )
  }

  renderCategories = () => {
    return (
      <Box className="categoryWrapper">
        <CategoriesHeader>
          <Typography className="title">{configJSON.categoryTxt}</Typography>
          <Typography className="seeAll" onClick={()=>this.goToBuyerHome("categories")} data-test-id="categorySeeAllId">{configJSON.seeAllTxt}</Typography>
        </CategoriesHeader>
        <ScrollContainer className="categoryScroll">
          <CategoryContainer>
            {
              this.state.categoryData?.slice(this.state.categorySlice.startIdx, this.state.categorySlice.endIdx).map((category: CategoryI) => (
                <Category key={category.id}>
                  <img src={config.baseURL + category.attributes.light_icon} alt={category.attributes.name} className="categoryImg" />
                  <Typography className="categoryName"  data-test-id="categoryId">{category.attributes.name}</Typography>
                </Category>
              ))
            }
          </CategoryContainer>
        </ScrollContainer>
      </Box>
    )
  }

  renderStores = () => {
    return <Box className={this.state.isSeeAll ? "categoryWrapper storeWrapper" : "categoryWrapper"}>
      <CategoriesHeader>
        <Typography className="title">{configJSON.storeTxt}</Typography>
        {
          !this.state.isSeeAll && 
        <Typography className="seeAll" onClick={this.handleStoreSeeAll} data-test-id="storeSeeAllId">{configJSON.seeAllTxt}</Typography>
}
      </CategoriesHeader>

      <ScrollContainer className="storeScroll">
        <StoreContainer>
          {
            this.state.stores?.slice(this.state.storeSlice.startIdx, this.state.storeSlice.endIdx).map((store: StoreI) => (
              <Store key={store.id} onClick={()=>this.goToStore(store.id)} data-test-id="visitStoreId">
                <Typography className="storeOffPer">
                  20% Off
                </Typography>
                <Typography className="storeRating">
                  <img src={starImg} alt="star" />
                  4.5 
                </Typography>
                <img src={store.background_image} alt="Store1" className="storeImg" />
                <StoreDetails>
                  <Box className="storeDetailsTop">
                    <img className="storeDetailsImg" src={store.profile_photo} alt="store" />
                    <Box className="storeDetail">
                      <Typography className="storeTitleContainer">
                        <Typography component={"span"} className="storeTitle" data-test-id="storeId">{store.business_name}</Typography>
                        <img src={storeCheckImg} className="storeTitleImg" alt="store title" />
                      </Typography>
                      <Typography className="storeLocationContainer">
                        <img src={storeLocationImg} alt="store location" className="storeLocationImg" />
                        <Typography className="storeLocation">{`${store.address.address_line_1}, ${store.address.city}, ${store.address.country}`}</Typography>
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="storeDetailsBottom">
                    <Typography className="visitStore">{configJSON.visitStoreTxt}</Typography>
                    <img src={righArrowImg} alt="right arrow" />
                  </Box>
                </StoreDetails>
              </Store>

            ))
          }
        </StoreContainer>
      </ScrollContainer>
    </Box>
  }
  renderStoresSidebar = () => {
    return (
      <Box className="sidebarContainer">
      <Box className="sidebar">
        <Typography className="sidebarTitle">{configJSON.sortTxt}</Typography>
        <StyledRadioGroup aria-label="store" name="store" value={this.state.storeSortValue} onChange={this.handleStoreSort}>
          {
            this.state.storesSidbarData.map((store: { [key: string]: string }) => {
              return <FormControlLabel value={store.name} control={<Radio />} label={store.name} />
            })
          }
        </StyledRadioGroup>
          <Button className="goToFeedButton">{configJSON.goToFeedTxt}</Button>
      </Box>
      </Box>
    )
  }
  renderSideBar = () => {
    return (
      <Sidebar>
        <CurrentLocation>
          <Typography className="currentLocationText">{configJSON.currentLocation}</Typography>
          <Box className="currentLocationBox" onClick={this.handleChangeLocationModal} data-test-id="currentLocationBox">
            <Box><img src={location}  alt="location"/></Box>
            <Typography className="city">{this.state.currentLocation}</Typography>
            <Box><img src={dropDown} alt="dropDown"/></Box>
          </Box>
        </CurrentLocation>
        <Button className="feedBtn">{configJSON.goToFeed}</Button>

        <CitiesDailogBox onClose={this.handleCloseLocation} open={this.state.citiesModal} style={{bottom:200}} data-test-id="citiesModal">
          <Box className="citiesInnerContainer">
            <TextField
              variant="outlined"
              className="searchTextField"
              onChange={this.searchCity}
              InputProps={{
                startAdornment: (<img src={searchIcon} alt="searchIcon" />)
              }}
              placeholder={configJSON.searchForCity}
              data-test-id="searchCity"
            />
            <Box>
              <Box className="useCurrentLocationBox" onClick={this.getGpsLocation} data-test-id="userCurrentLocation">
                <>
                <img src={locationBlackIcon} alt="locationIcon" />
                </>
                <Typography className="useCurrentLocationText">{configJSON.useCurrentLocation}</Typography>
              </Box>
              <Box>
                  <Typography className="popularCities">{configJSON.popularCities}</Typography>
                  <Box className="flexWrap">
                    {this.state.citiesData.popular_cities?.map((eachCity:string)=>{
                    return(
                     <Box className="eachCity"  onClick={()=>this.selectedCity(eachCity)} data-test-id={eachCity}>
                      <>
                      <img src={location} alt="location" style={{marginRight:"0.25em"}}/>
                      </>
                      {eachCity}
                      </Box>)
                    })}
                  </Box>
                  <Typography className="otherCities">{configJSON.otherCities}</Typography>
                  <Box className="flexWrap">
                    {this.state.citiesData.other_cities?.map((eachCity:string)=>{
                    return(
                     <Box className="eachOtherCity" onClick={()=>this.selectedCity(eachCity)}>
                      {eachCity}
                     </Box>)
                    })}
                  </Box>
              </Box>
            </Box>
          </Box>
        </CitiesDailogBox>

      </Sidebar>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Wrapper data-test-id="wrapperId">
        <Loader loading={this.state.loading} />
        {this.renderSideBar()}
        {this.state.isSeeAll && this.renderStoresSidebar()}
        <Box className="rightSide">
        {!this.state.isSeeAll && this.showImageSlider()}
        {!this.state.isSeeAll && this.renderCategories()}
        {this.renderStores()}
        </Box>
        <MessageModalWeb
          data-test-id="messageModal"
          displayPopup={this.state.messageModal}
          closeErrorPopup={this.handleClosemessageModal}
          errorMessage={this.state.message}
        />
      </Wrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const Wrapper = styled(Box)({
  marginTop: "120px",
  display: "flex",
  width: "100%",
  minHeight: "calc(100vh - 114px)",
  "& .rightSide":{
    overflow: "hidden",
    width: "100%"
  },
  "& .categoryWrapper":{
    marginBottom: "30px",
    padding: "0 20px 20px 20px"
  },
  "& .storeWrapper":{
    marginTop: "22px"
  },
  "@media(max-width: 1150px)":{
    marginTop: "72px"
  },
  "& .sidebarContainer":{
    borderRight: '5px solid #D9D9D94D',
    width: "260px",
    flexShrink: 0
  },
  '@media(min-width: 600px)': {
    "& .sidebar":{
      borderRight: "5px solid #D9D9D9",
      padding: "20px 16px 20px 8px",
      width: "260px",
    }
  },
  "& .sidebarTitle":{
    paddingBottom: "20px",
    color: "#a8a8a8",
    fontSize: "20px"
  },
  "& .goToFeedButton": {
    color: "black",
    backgroundColor: "#F5EA16",
    width: "calc(100% - 6px)",
    borderRadius: "25px",
    border: "1px solid black",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    padding: "4px",
    marginTop: "20px",
    '&:hover': {
      backgroundColor: '#F5EA16'
    },
  },
})

const CarouselContainer = styled(Box)({
  position: "relative",
  width: "100%",
  height: "300px",
  marginBottom: "30px",
  "@media(max-width: 1150px)":{
    marginTop: "20px"
  },
  padding: "0 20px",
  "& .slick-dots":{
    bottom: "12px"
  },
  "& .slick-dots li":{
    width: "11px",
    height: "8px",
  },
  "& .slick-dots .slick-active":{
    width: "23px",
  },
  "& .slick-dots li button::before":{
    content: "''",
    backgroundColor: "#D9D9D9",
    width: "100%",
    height: "100%",
    borderRadius: "100px",
    opacity: 1
  },
  "& .slick-dots li.slick-active button::before":{
    backgroundColor: "#2E2E2E",
  }
})

const Slide = styled(Box)({
  width: "100%",
  height: "300px",
  borderRadius: "20px",
  overflow: "hidden",
  "& .bannerImg":{
    objectFit: "fill",
    height: "100%",
    width: "100%"
  },
  "& .bannerContent":{ 
    position: "absolute", 
    top: "20px", 
    padding: "20px", 
    marginLeft: "20px" 
  },
  "& .bannerTitle":{ 
    fontSize: "24px", 
    fontWeight: 700, 
    color: "#FFF", 
    width: 400, 
    lineHeight: "36px" 
  },
  "& .bannerSubTitle":{ 
    fontSize: "18px", 
    fontWeight: 400, 
    color: "#FFF", 
    lineHeight: "16px", 
    marginTop: 10 
  },
  "& .shopNowContainer":{ 
    display: "flex", 
    alignItems: "center", 
    gap: "2px",
    fontSize: "18px", 
    lineHeight: "27px", 
    marginTop: 30, 
    fontWeight: 700, 
    color: "#FFF" 
  }
})

const CategoriesHeader = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "20px",
  "& .title":{
    fontSize: "20px",
    lineHeight: "23px",
    fontWeight: 600,
    color: "#2E2E2E"
  },
  "& .seeAll":{
    textDecoration: "underline",
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: 500,
    color: "#2E2E2E",
    cursor: "pointer"
  }
})

const ScrollContainer = styled(Box)({
  overflow: "auto",
  marginTop: "20px",
})

const CategoryContainer = styled(Box)({
  display: "grid",
  gap: "15px",
  gridTemplateColumns: "repeat(5 , 1fr)",
  paddingBottom: "20px",
  minWidth: "1392px"
})

const Category = styled(Box)({
  width: "100%",
  marginBottom: "15px",
  "& .categoryImg":{
    width: "100%",
    height: "140px",
    borderRadius: "8px"
  },
  "& .categoryName":{
    textAlign: "center",
    marginTop: "12px",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "#4E4E4E"
  }
})

const StoreContainer = styled(Box)({
  minWidth: "1475px",
  display: "grid",
  gap: "15px",
  gridTemplateColumns: "repeat(3 , 1fr)",
  paddingBottom: "20px",
  boxSizing: "border-box"
})

const Store = styled(Box)({
  flexShrink: 0,
  borderRadius: "16px",
  border: "1px solid lightgray",
  overflow: "hidden",
  position: "relative",
  width: "100%",
  cursor: "pointer",
  "& .storeOffPer":{
    position: "absolute",
    top: 0,
    left: 0,
    backgroundColor:"#000",
    borderRadius: "0 0 12px 0",
    padding: "8px 0",
    color: "#fff",
    width: "86px",
    height: "34px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "16px"
  },
  "& .storeRating":{
    position: "absolute",
    top: 0,
    right: 0,
    backgroundColor:"yellow",
    borderRadius: "0 0  0 12px",
    padding: "8px 0",
    color: "#000",
    width: "86px",
    height: "34",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "12px",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "16px"
  },
  "& .storeImg":{
    width: "100%",
    height: "173px",
  },
})

const StoreDetails = styled(Box)({
  padding: "16px",
  "& .storeDetailsTop":{
    paddingBottom: "16px",
    borderBottom: "1px solid #E3E3E3",
    display: "flex",
    alignItems: "center",
    gap: "12px"
  },
  "& .storeDetailsImg":{
    width: "64px",
    height: "64px",
    borderRadius: "50%"
  },
  "& .storeTitleContainer":{
    display: "flex",
    alignItems: "center",
    gap: "12px"
  },
  "& .storeTitle":{
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "27px",
    color: "#2E2E2E"
  },
  "& .storeTitleImg":{
    width: "24px",
    height: "24px"
  },
  "& .storeLocationContainer":{
    display: "flex",
    alignItems: "center",
    gap: "8px",
    color: "#6E6E6E"
  },
  "& .storeLocation":{
    fontSize: "16px",
    fontWeight: 300,
    lineHeight: "24px"
  },
  "& .storeLocationImg":{
    width:"18px",
    height: "18px"
  },
  "& .storeDetailsBottom":{
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "16px",
    
  },
  "& .visitStore":{
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    color: "#2E2E2E"
  },
  "& .storeDetailsBottom img":{
    cursor: "pointer"
  }
})

const StyledRadioGroup = styled(RadioGroup)({
  borderTop: "1px solid #D9D9D94D",
  borderBottom: "1px solid #D9D9D94D",
  paddingBottom: "20px",
  "& .MuiFormControlLabel-root":{
    justifyContent: "space-between",
    marginRight: "0",
    marginLeft: "0"
  },
  "& .MuiFormControlLabel-label":{
    flex: 1
  },
  "& .MuiFormControlLabel-label:first-letter":{
    textTransform: "capitalize",
    whiteSpace: "nowrap"
  },
  "& .MuiRadio-root":{
    order: 2,
    color: "#000",
    paddingRight: "0px"
  }
});

const Sidebar = styled(Box)({
  width: "15em",
  borderRight: "5px solid #D9D9D94D",
  backgroundColor: "#fff",
  boxSizing: "border-box",
  flexShrink: 0,
  textAlign: "center",
  "& .feedBtn":{
    backgroundColor: "#F5EA16",
    color: "#000",
    borderRadius: "36px",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    padding: "4px",
    width: "194px",
    height: "33px",
    marginTop: "45px"
  },
})
const CurrentLocation = styled(Box)({
  "& .currentLocationText":{
      fontFamily: 'Poppins !important',
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '18px',
      marginBottom:"1em"
     
  },
  "& .currentLocationBox":{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    cursor:"pointer"
  },
  "& .city":{
    marginRight:"1.1em",
    marginLeft:"0.32em"
  },
  
})

const CitiesDailogBox = styled(Dialog)({

  "&. MuiDialog-paperWidthSm":{
  maxWidth:"1030px !important",
  },
 "& .citiesInnerContainer":{
  height:"29em",
  padding:'1em'
 },
 "& .searchTextField":{
  width:"100% !important",
},
"& .useCurrentLocationBox":{
  display:"flex",
  margin:"1em 0em 2em 0em",
},
"& .useCurrentLocationText":{
    fontFamily: 'Poppins !important',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '16px',
    textDecoration:"underline",
    marginLeft:"0.5em"
},
"& .popularCities":{
  fontFamily: 'Poppins !important',
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '24px',
  
},
"& .otherCities":{
  fontFamily: 'Poppins !important',
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '24px',
  marginTop:"1em"
},
"& .eachOtherCity": { 
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '16px',
    margin: "0.5em",
    cursor:"pointer"
  },
"& .eachCity":{
  display:"flex",
  alignItems:"center",
  justifyContent:"center",
  padding:"0.5em 1em",
  borderRadius:"8px",
  border:"1px solid lightgray",
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '16px',
   margin:"0.5em",
  boxShadow: '0px 0px 20px 1px rgba(0, 0, 0, 0.10)',
  cursor:"pointer"
},

"& .flexWrap":{
  display:"flex",
  flexWrap:"wrap"
}


})



// Customizable Area End
