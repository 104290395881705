import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { checkLocationPermission } from "../../../components/src/utils"
export const configJSON = require("./config");
import { load } from "@cashfreepayments/cashfree-js";
const baseURL = require("../../../framework/src/config.js").baseURL

interface CashfreeResultPayload {
    error?: string;
    redirect?: string
}

interface ApiPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    type?: string;
}

interface PaymentPayloadType {
    order_id: string,
    payment_session_id: string;
    message: string;
}

interface InitiatePaymentApiResponse {
    payment_order: PaymentPayloadType;
    errors: Error[];
    status: number;
}

interface Error {
    token: string;
}  

export interface ProductId {
    favourite_id: number
    product_id: number
  }

  export interface OrderItems {
    id: number;
    type: string;
    attributes: {
      id: number;
      order_management_order_id: number;
      order_item_order_id: string;
      quantity: number;
      old_unit_price: null;
      status: string;
      catalogue_id: number;
      catalogue_variant_id: number;
      order_status_id: number;
      created_at: string;
      updated_at: string;
      unit_price: number;
      total_price: number;
      catalogue: {
        id: number;
        type: string;
        attributes: {
          category_id: number;
          sub_category_id: number;
          title: string;
          description: string;
          status: string;
          mrp: number;
          selling_price: number;
          ask_price: number;
          bargain: boolean;
          on_order: boolean;
          is_ask_price: boolean;
          is_brand: boolean;
          sku: string;
          marked_destroy: boolean;
          product_images: [
            {
              id: number;
              url: string;
            }
          ];
          inventory_details: {
            id: number;
            fullfilment_by: string;
            procurement_type: string;
            procurement_sla: string;
            account_id: number;
            stock_quantity: number;
            catalogue_id: number;
            created_at: string;
            updated_at: string;
          }
        }
      };
      catalogue_variant: {
        id: number;
        type: string;
        attributes: {
          id: number;
          catalogue_id: number;
          catalogue_variant_color: {
            id: number;
            name: string;
            created_at: string;
            updated_at: string;
          };
          catalogue_variant_size: null;
          price: number;
          stock_qty: number;
          on_sale: boolean;
          sale_price: number;
          discount_price: null;
          length: null;
          breadth: null;
          height: null;
          created_at: string;
          updated_at: string;
          block_qty: null;
          product_images: [
            {
              id: number;
              url: string;
            }
          ];
          product_videos: [
            {
              id: number;
              url: string;
            }
          ]
        }
      }
    }
  }
  export interface CartData {
    id: number;
    type: string;
    attributes: {
      id: number;
      order_number: string;
      amount: number;
      account_id: number;
      coupon_code_id: null;
      delivery_address_id: null;
      status: string;
      custom_label: null;
      cancellation_reason: null;
      order_date: null;
      is_gift: boolean;
      placed_at: null;
      confirmed_at: null;
      in_transit_at: null;
      delivered_at: null;
      cancelled_at: null;
      refunded_at: null;
      source: null;
      shipment_id: null;
      delivery_charges: null;
      tracking_url: null;
      schedule_time: null;
      payment_failed_at: null;
      payment_pending_at: null;
      returned_at: null;
      deliver_by: null;
      tracking_number: null;
      is_error: boolean;
      delivery_error_message: null;
      order_status_id: number;
      is_group: boolean;
      is_availability_checked: boolean;
      created_at: string;
      updated_at: string;
      delivery_addresses: [];
      razorpay_order_id: null;
      charged: null;
      invoice_id: null;
      invoiced: null;
      shipping_charge: number;
      shipping_discount: number;
      shipping_net_amt: number;
      shipping_total: number;
      tax_charges: number;
      total_tax: number;
      sub_total: number;
      applied_discount: number;
      total: number;
      order_items: Array<OrderItems>;
      account: {
        id: number;
        type: string;
        attributes: {
          activated: boolean;
          country_code: null;
          email: string;
          upi_id: null;
          full_name: string;
          user_name: string;
          business_popup: boolean;
          full_phone_number: number;
          phone_number: number;
          type: null;
          created_at: string;
          updated_at: string;
          device_id: null;
          unique_auth_id: string;
          role: {
            id: number;
            name: string;
            created_at: string;
            updated_at: string;
            page_names: []
          };
          is_disabled: boolean;
          my_bio: null;
          updated_mobile_number: null;
          updated_email: null;
          same_drop_off: boolean;
          shipment_type: string;
          business_name: null;
          business_type: null;
          profile_photo: null;
          background_photo: null
        }
      };
      order_transaction: null;
      seller_details: {
        id: number;
        type: string;
        attributes: {
          activated: boolean;
          country_code: number;
          email: string;
          upi_id: string;
          full_name: string;
          user_name: string;
          business_popup: boolean;
          full_phone_number: number;
          phone_number: number;
          type: null;
          created_at: string;
          updated_at: string;
          device_id: string;
          unique_auth_id: string;
          role: {
            id: number;
            name: string;
            created_at: string;
            updated_at: string;
            page_names: []
          };
          is_disabled: boolean;
          my_bio: string;
          updated_mobile_number: number;
          updated_email: string;
          same_drop_off: boolean;
          shipment_type: string;
          business_name: string;
          business_type: string;
          profile_photo: string;
          background_photo: string;
        }
      }
    }
  }
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    activeStep: number;
    openOfferDialog: boolean;
    appliedIndex: any;
    openNotifyDialog: boolean;
    orderSuccessDialog: boolean;
    orderFailDialog: boolean;
    cartData: any;
    messageTxt: string;
    addressMessageTxt: string;
    messageBox: boolean;
    addressMessageBox: boolean;
    addressAvailable: boolean;
    addNewAddress: boolean;
    updateAddress: boolean;
    comingSoon: boolean;
    selectedAddressID: any;
    selectedAddress: any;
    shippingAddressData: any;
    updateAddressID: any;
    countryList: any;
    stateList: any;
    cityList: any;
    email: string;
    address: string;
    tempAddress: any;
    country: any;
    name: string;
    mobile: string;
    state: any;
    city: string;
    pincode: string;
    flatNo: string;
    errorMessage: any;
    subCategoryID: string;
    suggestionData: any;
    catalougeIDs: string;
    wishListProduct: ProductId[],
    message: string;
    messageModel: boolean;
    paymentMethod: string;
    toggleCityDropdown: boolean;
    toggleCountryDropdown: boolean;
    toggleStateDropdown: boolean;
    cartId: null | number;
    lat: number,
    lng: number,
    errorMessageLocation: string,
    permissionStatus: string,
    cashFreeError : boolean;
    openPaymentStatus : boolean;
    paymentStatusText : string;
    severity: string;
    cashFreeOrderId: string;
    cashFreeSessionId: string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class BuyerShoppingCartController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getShippingAddressApiCallId: string = '';
    getCountryListApiCallId: string = '';
    getStateListApiCallId: string = '';
    getCityListApiCallId: string = '';
    updateQuantintyInCartApiCallId: string = '';
    createAndUpadteBuyerAddressApiCallId: string = '';
    deleteSavedAddressApiCallId: string = '';
    addAddressInOrderApiCallId: string = '';
    placeOrderApiCallId: string = '';
    apiPorterApiCallId: string = ';'
    getSuggestionDataApiCallId: string = '';
    apiAddToWishList: string = '';
    apiDeleteFromWishList: string = '';
    getSingleCartApiId: string = ""
    getCashfreeSessionApiId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.NavigationMessageSendShoppingCartData),
            getName(MessageEnum.NavigationPayLoadMessage)
        ];

        this.state = {
            loading: false,
            activeStep: 0,
            openOfferDialog: false,
            appliedIndex: null,
            openNotifyDialog: false,
            orderSuccessDialog: false,
            orderFailDialog: false,
            cartData: {},
            messageTxt: '',
            addressMessageTxt: '',
            messageBox: false,
            addressMessageBox: false,
            addressAvailable: false,
            addNewAddress: false,
            updateAddress: false,
            tempAddress: {},
            comingSoon: false,
            selectedAddressID: null,
            selectedAddress: {},
            shippingAddressData: [],
            updateAddressID: null,
            email: '',
            address: '',
            country: '',
            name: '',
            mobile: '',
            state: '',
            city: '',
            pincode: '',
            flatNo: '',
            countryList: [],
            stateList: [],
            cityList: [],
            errorMessage: {
                email: '',
                address: '',
                country: '',
                name: '',
                mobile: '',
                state: '',
                city: '',
                pincode: '',
                flatNo: ''
            },
            suggestionData: [],
            subCategoryID: '',
            catalougeIDs: '',
            wishListProduct: [],
            message: '',
            messageModel: false,
            paymentMethod: 'cashfree',
            toggleCityDropdown: false,
            toggleCountryDropdown: false,
            toggleStateDropdown: false,
            cartId: null,
            lat: 0,
            lng: 0,
            errorMessageLocation: '',
            permissionStatus: 'Prompting',
            cashFreeError: false,
            openPaymentStatus: false,
            paymentStatusText: "",
            severity:"",
            cashFreeOrderId: "",
            cashFreeSessionId: "",
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId === this.getSingleCartApiId) {
                this.setState({ loading: false });
                if (responseJson.data && !responseJson.message) {
                    this.setState({
                        cartData: responseJson.data.attributes,
                        catalougeIDs: responseJson.data.attributes.order_items.map((item: OrderItems) => item.attributes.catalogue.id).join(','),
                        subCategoryID: responseJson.data.attributes.order_items.map((item: OrderItems) => item.attributes.catalogue.attributes.sub_category_id).join(','),
                    }, () => {
                        this.getSuggestionData();
                    });
                } else if (responseJson.message) {
                    this.setState({
                        messageTxt: responseJson.message,
                        messageBox: true
                    })
                } else if (responseJson.errors) {
                    this.setState({
                        messageTxt: responseJson.errors[0].token,
                        messageBox: true
                    })
                }
            } else if (apiRequestCallId === this.getShippingAddressApiCallId) {
                                if (responseJson.data) {
                    this.setState({
                        addressAvailable: true,
                        shippingAddressData: responseJson.data.reverse(),
                        selectedAddressID: responseJson.data[0].id,
                        selectedAddress: responseJson.data.find((data: any) => data.id === (this.state.selectedAddressID ? this.state.selectedAddressID : responseJson.data[0].id))
                    });
                } else if (responseJson.message) {
                    this.setState({
                        addressAvailable: false,
                    })
                }
            } else if (apiRequestCallId === this.getCountryListApiCallId) {
                this.setState({ loading: false })
                if (responseJson.countries) {
                    this.setState({
                        countryList: Object.entries(responseJson.countries).map(([slug, name]) => ({ slug, name })),
                    }, () => {
                        this.state.country && this.getStateList();
                    });
                }
            } else if (apiRequestCallId === this.getStateListApiCallId) {
                this.setState({ loading: false })
                if (responseJson.states) {
                    this.setState({
                        stateList: Object.entries(responseJson.states).map(([slug, name]) => ({ slug, name })),
                    }, () => {
                        this.state.state && this.getCityList();
                    });
                }
            } else if (apiRequestCallId === this.getCityListApiCallId) {
                this.setState({ loading: false })
                if (responseJson.cities) {
                    this.setState({
                        cityList: responseJson.cities,
                    });
                }
            } else if (apiRequestCallId === this.updateQuantintyInCartApiCallId) {
                this.setState({ loading: false });
                if (responseJson.data && !responseJson.message) {
                    this.setState({
                        cartData: responseJson.data?.attributes
                    });
                } else if (responseJson.message) {
                    this.setState({
                        messageTxt: responseJson.message,
                        messageBox: true
                    })
                }
            } else if (apiRequestCallId === this.createAndUpadteBuyerAddressApiCallId) {
                this.setState({ loading: false });
                if (responseJson.data) {
                    this.getShippingAddress();
                    this.setState({
                        addNewAddress: false,
                        updateAddress: false,
                        updateAddressID: null,
                        email: '',
                        address: '',
                        country: '',
                        name: '',
                        mobile: '',
                        state: '',
                        city: '',
                        pincode: '',
                        flatNo: '',
                        stateList: [],
                        cityList: [],
                        tempAddress: {}
                    })
                } else if (responseJson.error) {
                    this.setState({
                        addNewAddress: false,
                        updateAddress: false,
                        updateAddressID: null,
                        email: '',
                        address: '',
                        country: '',
                        name: '',
                        mobile: '',
                        state: '',
                        city: '',
                        pincode: '',
                        flatNo: '',
                        stateList: [],
                        cityList: [],
                        tempAddress: {}
                    })
                }
            } else if (apiRequestCallId === this.deleteSavedAddressApiCallId) {
                if (responseJson.message) {
                    this.setState({
                        selectedAddress: {},
                        selectedAddressID: null
                    }, () => {
                        this.getShippingAddress();
                    })
                }
            } else if (apiRequestCallId === this.addAddressInOrderApiCallId) {
                this.setState({ loading: false });
                if (responseJson.data || responseJson.message) {
                    this.setState((prevState) => ({
                        activeStep: prevState.activeStep + 1
                    }));
                }
            } else if (apiRequestCallId === this.placeOrderApiCallId) {
                this.setState({ loading: false });
                if (responseJson.data) {
                    this.setState({
                        orderSuccessDialog: true
                    });
                    // this.porterApiCall(1)
                } else if (responseJson.message) {
                    this.setState({
                        orderFailDialog: true
                    })
                }
            } else if (apiRequestCallId === this.getSuggestionDataApiCallId) {
                this.setState({ loading: false });
                if (responseJson.data) {
                    this.setState({
                        suggestionData: responseJson.data
                    })
                } else if (responseJson && responseJson.wishlist_items && responseJson.wishlist_items.product_ids) {
                    this.setState({
                        wishListProduct: responseJson.wishlist_items.product_ids
                    })
                }
            }

            switch (apiRequestCallId) {
                case this.apiAddToWishList:
                    this.checkItemAddedWishList(responseJson);
                    break;
                case this.apiDeleteFromWishList:
                    this.checkItemRemovedWishList(responseJson);
                    break;
                case this.getCashfreeSessionApiId:
                    this.getCashfreeOrderDataCallback(responseJson);
                    break;
                default:
                    break;
            }

        }
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        const cartId = window.location.pathname.split("/").pop() as string;
        this.getSingleCart(cartId)
        this.getShippingAddress();
        this.getCountryList();
        checkLocationPermission(this);
        const headingElement = document.getElementById("myref")
        if (headingElement) headingElement.scrollIntoView({ behavior: 'smooth' })
        // Customizable Area End
    }

    // Customizable Area Start
    closeOfferDialogBox = () => {
        this.setState({
            openOfferDialog: false,
        });
    }

    navigateToOrderList = () => {
        this.props.navigation.navigate("CustomisedOrderStatus")
    }

    closeMessageBox = () => {
        this.setState({
            messageBox: false,
        }, () => {
            this.goToBuyerHome()
        });
    }

    closeAddessErrorBox = () => {
        this.setState({
            addressMessageBox: false,
        });
    }

    navigateToSeeAll = () => {
        window.location.href =  window.location.origin
    }

    openOfferDialogBox = () => {
        this.setState({
            openOfferDialog: true,
        });
    }

    closeNotifyDialogBox = () => {
        this.setState({
            openNotifyDialog: false,
        });
    }

    closeOrderFailDialogBox = () => {
        this.setState({
            orderFailDialog: false,
        });
    }

    openNotifyDialogBox = () => {
        this.setState({
            openNotifyDialog: true,
        });
    }

    applyOffer = (index: number) => {
        this.setState({
            appliedIndex: index,
        });
    }

    updateOrDeleteItem = (item: any, increase: boolean) => {
        item.quantity === 1 ?
            this.deleteItemFromCart(item) :
            this.updateQuantintyInCart(item, increase)
    }

    handlePaymentMethod = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            paymentMethod: (event.target as HTMLInputElement).value
        })
    };

    handleCheckout = () => {
        this.checkPayemntCashfree();
        if (this.state.activeStep === 0) {
            this.setState((prevState) => ({
                activeStep: prevState.activeStep + 1
            }));
        } else if (this.state.activeStep === 1) {
            if (this.state.selectedAddressID === null) {
                this.setState({
                    addressMessageTxt: 'Please add delivery address first.',
                    addressMessageBox: true
                })
            } else {
                this.setState((prevState) => ({
                    activeStep: prevState.activeStep + 1
                }));
            }
        } else if (this.state.activeStep === 2) {
            this.addAddressInOrder();
        } else if (this.state.activeStep === 3) {
            if (this.state.paymentMethod === 'upi') {
                const message = new Message(getName(MessageEnum.NavigationMessage));
                message.addData(getName(MessageEnum.NavigationTargetMessage), "BuyerQrCodePayment");
                message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
                this.send(message)
            } else if(this.state.paymentMethod === 'cod') {
                this.placeOrder();
            }
        } 
    };

    checkProductStatus = (producArray: any, ID: any) => {
        const index = producArray.findIndex(
            (
                item: any
            ) =>
                item.product_id === ID
                );
        if (index !== -1) {
            return true;
        } else { return false; }
    }

    deleteProductItem = (producArray: any, ID: any) => {
        const index = producArray.find((item: any) => 
                item.product_id === ID).favourite_id;

        if (index) { this.deleteWishListItem(index) } 
        else {
            return false;
        }
    }

    handleHomePageNavigation = () => {
        this.setState({
            orderSuccessDialog: false,
        });
        this.props.navigation.navigate("BuyerDashboard")
    }

    handleAddressSelection = (event: any) => {
        const addressData = this.state.shippingAddressData.find((data: any) => data.id === event.target.value);

        this.setState({
            selectedAddressID: event.target.value,
            selectedAddress: addressData
        })
    }

    handleAddNewAddress = () => {
        this.setState({
            addNewAddress: true
        })
    }

    stayTuned = () => {
        this.setState({
            comingSoon: true
        })
    }

    closeStayTuned = () => {
        this.setState({
            comingSoon: false
        })
    }

    cancelNewAddress = () => {
        this.setState({
            addNewAddress: false,
            updateAddress: false,
            email: '',
            address: '',
            country: '',
            name: '',
            mobile: '',
            state: '',
            city: '',
            pincode: '',
            flatNo: ''
        })
    }

    handleEmailChange = (event: any) => {
        this.setState({
            email: event.target.value,
        });
        this.setState((prevState) => ({
            errorMessage: {
                ...prevState.errorMessage,
                email: ''
            }
        }));
    }

    handleCountryChange = (event: any, newValue: any) => {
        this.setState({
            country: newValue,
            pincode: '',
            state: {},
            stateList: [],
            city: '',
            cityList: [],
        }, () => {
            newValue && this.getStateList();
        });
        this.setState((prevState) => ({
            errorMessage: {
                ...prevState.errorMessage,
                country: ''
            }
        }));
    }

    handleNameChange = (event: any) => {
        this.setState({
            name: event.target.value,
        });
        this.setState((prevState) => ({
            errorMessage: {
                ...prevState.errorMessage,
                name: ''
            }
        }));
    }

    handleStateChange = (event: any, newValue: any) => {
        this.setState({
            state: newValue,
            pincode: '',
            city: '',
            cityList: []
        }, () => {
            this.getCityList()
        });
        this.setState((prevState) => ({
            errorMessage: {
                ...prevState.errorMessage,
                state: ''
            }
        }));
    }

    handleMobileChange = (event: any) => {
        this.setState({
            mobile: event.target.value,
        });
        this.setState((prevState) => ({
            errorMessage: {
                ...prevState.errorMessage,
                mobile: ''
            }
        }));
    }

    handleCityChange = (event: any, newValue: any) => {
        this.setState({
            city: newValue,
            pincode: ''
        });
        this.setState((prevState) => ({
            errorMessage: {
                ...prevState.errorMessage,
                city: ''
            }
        }));
    }

    handlePincodeChange = (event: any) => {
        this.setState({
            pincode: event.target.value,
        });
        this.setState((prevState) => ({
            errorMessage: {
                ...prevState.errorMessage,
                pincode: ''
            }
        }));
    }

    handleAddressChange = (event: any) => {
        this.setState({
            address: event.target.value,
        });
        this.setState((prevState) => ({
            errorMessage: {
                ...prevState.errorMessage,
                address: ''
            }
        }));
    }

    handleFlatNoChange = (event: any) => {
        this.setState({
            flatNo: event.target.value,
        });
        this.setState((prevState) => ({
            errorMessage: {
                ...prevState.errorMessage,
                flatNo: ''
            }
        }));
    }

    ValidateEmail = () => {
        const { email } = this.state;
        if (!email) {
            this.setState((prevState) => ({
                errorMessage: {
                    ...prevState.errorMessage,
                    email: 'Email is required'
                }
            }));
            return false;
        } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/g.test(email)) {
            this.setState((prevState) => ({
                errorMessage: {
                    ...prevState.errorMessage,
                    email: 'Invalid email format'
                }
            }));
            return false;
        } else {
            this.setState((prevState) => ({
                errorMessage: {
                    ...prevState.errorMessage,
                    email: ''
                }
            }));
            return true;
        }
    }

    ValidateCountry = () => {
        const { country } = this.state;
        if (!country) {
            this.setState((prevState) => ({
                errorMessage: {
                    ...prevState.errorMessage,
                    country: 'Country is required'
                }
            }));
            return false;
        } else {
            this.setState((prevState) => ({
                errorMessage: {
                    ...prevState.errorMessage,
                    country: ''
                }
            }));
            return true;
        }
    }

    ValidateName = () => {
        const { name } = this.state;
        if (!name) {
            this.setState((prevState) => ({
                errorMessage: {
                    ...prevState.errorMessage,
                    name: 'Name is required'
                }
            }));
            return false;
        } else {
            this.setState((prevState) => ({
                errorMessage: {
                    ...prevState.errorMessage,
                    name: ''
                }
            }));
            return true;
        }
    }

    ValidateState = () => {
        const { state } = this.state;
        if (!state) {
            this.setState((prevState) => ({
                errorMessage: {
                    ...prevState.errorMessage,
                    state: 'State is required'
                }
            }));
            return false;
        } else {
            this.setState((prevState) => ({
                errorMessage: {
                    ...prevState.errorMessage,
                    state: ''
                }
            }));
            return true;
        }
    }

    ValidateMobile = () => {
        const { mobile } = this.state;
        if (!mobile) {
            this.setState((prevState) => ({
                errorMessage: {
                    ...prevState.errorMessage,
                    mobile: 'Mobile is required'
                }
            }));
            return false;
        } else if (!/^[\d]{10}$/i.test(mobile)) {
            this.setState((prevState) => ({
                errorMessage: {
                    ...prevState.errorMessage,
                    mobile: 'Invalid mobile format'
                }
            }));
            return false;
        } else {
            this.setState((prevState) => ({
                errorMessage: {
                    ...prevState.errorMessage,
                    mobile: ''
                }
            }));
            return true;
        }
    }

    ValidateCity = () => {
        const { city } = this.state;
        if (!city) {
            this.setState((prevState) => ({
                errorMessage: {
                    ...prevState.errorMessage,
                    city: 'City is required'
                }
            }));
            return false;
        } else {
            this.setState((prevState) => ({
                errorMessage: {
                    ...prevState.errorMessage,
                    city: ''
                }
            }));
            return true;
        }
    }

    ValidatePincode = () => {
        const { pincode } = this.state;
        if (!pincode) {
            this.setState((prevState) => ({
                errorMessage: {
                    ...prevState.errorMessage,
                    pincode: 'Pincode is required'
                }
            }));
            return false;
        } else if (!/^[\d]{6}$/i.test(pincode)) {
            this.setState((prevState) => ({
                errorMessage: {
                    ...prevState.errorMessage,
                    pincode: 'Invalid pincode format'
                }
            }));
            return false;
        } else {
            this.setState((prevState) => ({
                errorMessage: {
                    ...prevState.errorMessage,
                    pincode: ''
                }
            }));
            return true;
        }
    }

    ValidateAddress = () => {
        const { address } = this.state;
        if (!address) {
            this.setState((prevState) => ({
                errorMessage: {
                    ...prevState.errorMessage,
                    address: 'Address is required'
                }
            }));
            return false;
        } else {
            this.setState((prevState) => ({
                errorMessage: {
                    ...prevState.errorMessage,
                    address: ''
                }
            }));
            return true;
        }
    }

    ValidateFlatNo = () => {
        const { flatNo } = this.state;
        if (!flatNo) {
            this.setState((prevState) => ({
                errorMessage: {
                    ...prevState.errorMessage,
                    flatNo: 'FlatNo is required'
                }
            }));
            return false;
        } else {
            this.setState((prevState) => ({
                errorMessage: {
                    ...prevState.errorMessage,
                    flatNo: ''
                }
            }));
            return true;
        }
    }

    editShippingAddress = (data: any) => {
        const shippingAddress = data.attributes;
        this.setState({
            tempAddress: shippingAddress,
            addNewAddress: true,
            updateAddress: true,
            email: shippingAddress.email,
            address: shippingAddress.address,
            country: shippingAddress.country,
            name: shippingAddress.name,
            mobile: shippingAddress.mobile,
            state: shippingAddress.state,
            city: shippingAddress.city,
            pincode: shippingAddress.pin_code,
            flatNo: shippingAddress.flat_no,
            updateAddressID: shippingAddress.id
        }, () => {
            this.getCountryList();
        })
    }

    saveNewAddress = () => {
        let emailVerified = this.ValidateEmail();
        let nameVerified = this.ValidateName();
        let cityVerified = this.ValidateCity();
        let stateVerified = this.ValidateState();
        let countryVerified = this.ValidateCountry();
        let addressVerified = this.ValidateAddress();
        let flatNoVerified = this.ValidateFlatNo();
        let mobileVerified = this.ValidateMobile();
        let pincodeVerified = this.ValidatePincode();

        if (emailVerified && nameVerified && cityVerified && stateVerified && countryVerified && addressVerified && flatNoVerified && mobileVerified && pincodeVerified) {
            this.createAndUpadteBuyerAddress();
        }
    }

    getShippingAddress = async () => {
        const header = { "Content-Type": "application/json", "token": await getStorageData("buerLoginToken") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getShippingAddressApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), 'bx_block_order_management/addresses');
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    deleteItemFromCart = async (item: any) => {
        this.setState({ loading: true });

        const httpBody = {
            "id": Number(item.id)
        }

        const header = { "Content-Type": "application/json", "token": await getStorageData("buerLoginToken") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.updateQuantintyInCartApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), 'bx_block_order_management/remove_item_from_cart');
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    updateQuantintyInCart = async (item: any, increase: boolean) => {
        this.setState({ loading: true });

        const httpBody = {
            "order_items": {
                "id": Number(item.id),
                "catalogue_id": Number(item.catalogue_id),
                "catalogue_variant_id": Number(item.catalogue_variant_id),
                "quantity": increase ? item.quantity + 1 : item.quantity - 1
            }
        }

        const header = { "Content-Type": "application/json", "token": await getStorageData("buerLoginToken") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.updateQuantintyInCartApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_order_management/update_cart_item`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    createAndUpadteBuyerAddress = async () => {
        this.setState({ loading: true });

        const { email, address, city, state, country, pincode, mobile, flatNo, name, updateAddress, updateAddressID } = this.state;
        const requestMethod = updateAddress ? "PUT" : "POST"
        const requestEndPoint = updateAddress ? `bx_block_order_management/addresses/${updateAddressID}` : 'bx_block_order_management/addresses'

        const httpBody = {
            "email": email,
            "address": address,
            "city": city,
            "state": state,
            "country": country,
            "pin_code": pincode,
            "mobile": mobile,
            "flat_no": flatNo,
            "name": name,
            "latitude":this.state.lat,
            "longitude":this.state.lng
        }

        const header = { "Content-Type": "application/json", "token": await getStorageData("buerLoginToken") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.createAndUpadteBuyerAddressApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), requestEndPoint);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), requestMethod);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getCountryList = () => {
        const header = { "Content-Type": "application/json" };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getCountryListApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), 'bx_block_address/get_country_list');
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getStateList = () => {
        const { country, countryList } = this.state;
        const header = { "Content-Type": "application/json" };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getStateListApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_address/get_state_list?country_code=${countryList.find((item: any) => item.name === country)?.slug}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getCityList = () => {
        const { country, countryList, stateList, state } = this.state;

        const countrySlug = countryList.find((item: any) => item.name === country)?.slug;
        const stateSlug = stateList.find((item: any) => item.name === state)?.slug;

        const header = { "Content-Type": "application/json" };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getCityListApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_address/get_city_list?country_code=${countrySlug}&state_code=${stateSlug}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    deleteSavedAddress = async (id: any) => {
        const header = { "Content-Type": "application/json", "token": await getStorageData("buerLoginToken") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.deleteSavedAddressApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), 'bx_block_order_management/addresses/' + id);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getSuggestionData = async () => {
        this.setState({ loading: true });
        const { subCategoryID, catalougeIDs } = this.state;
        const header = { "Content-Type": "application/json", "token": await getStorageData("buerLoginToken") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getSuggestionDataApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_catalogue/catalogues/related_catalogue?sub_category_id=${subCategoryID}&catalogue_id=${catalougeIDs}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getSingleCart= async (cartId: string) => {
        const header = { "Content-Type": configJSON.apiContentType, "token": await getStorageData("buerLoginToken") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getSingleCartApiId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getSingleCartEndPoint + cartId);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getApiMethod);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    closeMessageModal = () => {
        this.setState({
            messageModel: false
        })
    }

    checkItemAddedWishList = (responJson: any) => {
        if (responJson.message) {
          this.getSuggestionData();
          this.setState({ message: responJson.message, messageModel: true })
        } else {
            this.setState({
                message: responJson.errors, messageModel: true
            })
        }
      }
    
      checkItemRemovedWishList = (responJson: any) => {
          if (responJson.message) {
              this.getSuggestionData();
              this.setState({
                  message: responJson.message,
                  messageModel: true
              })
        } else {
          this.setState({ message: responJson.errors[0], messageModel: true })
        }
      }

    addAddressInOrder = async () => {
        this.setState({ loading: true });

        const orderID = Number(this.state.cartData.id)

        const httpBody = {
            "order_id": orderID,
            "address_id": Number(this.state.selectedAddressID),
            "billing_same_as_shipping": true
        }

        const header = { "Content-Type": "application/json", "token": await getStorageData("buerLoginToken") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.addAddressInOrderApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_order_management/orders/${orderID}/add_address_to_order`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    placeOrder = async () => {
        this.setState({ loading: true });

        const httpBody = {
            "id": Number(this.state.cartData.id)
        }

        const header = { "Content-Type": "application/json", "token": await getStorageData("buerLoginToken") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.placeOrderApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), 'bx_block_order_management/place_order_in_cart');
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    porterApiCall = async (id: number) => {
        const buyertoken = await getStorageData("buerLoginToken");
        if (buyertoken) {
            const header = {
                "Content-Type": "application/json",
                "token": buyertoken
            };

            const body = {
                "request_id": "Flixoolive_order_0003",
                "delivery_instructions": {
                    "instructions_list": [
                        {
                            "type": "text",
                            "description": "handle with care"
                        }
                    ]
                },
                "pickup_details": {
                    "address": {
                        "apartment_address": "27",
                        "street_address1": "Sona Towers",
                        "street_address2": "Krishna Nagar Industrial Area",
                        "landmark": "Hosur Road",
                        "city": "Bengaluru",
                        "state": "Karnataka",
                        "pincode": "560029",
                        "country": "India",
                        "lat": 12.935025018880504,
                        "lng": 77.6092605236106,
                        "contact_details": {
                            "name": "Test Sender",
                            "phone_number": "+919876543210"
                        }
                    }
                },
                "drop_details": {
                    "address": {
                        "apartment_address": "this is apartment address",
                        "street_address1": "BTM Layout",
                        "street_address2": "This is My Order ID",
                        "landmark": "BTM Layout",
                        "city": "Bengaluru",
                        "state": "Karnataka",
                        "pincode": "560029",
                        "country": "India",
                        "lat": this.state.lat,
                        "lng": this.state.lng,
                        "contact_details": {
                            "name": "Test Receiver",
                            "phone_number": "+919876543210"
                        }
                    }
                },
                "additional_comments": "This is a test comment"
            }

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.apiPorterApiCallId = requestMessage.messageId;

            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)
            );

            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.porterAddressAddEndPoint}`
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.postApiMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    }
    
    deleteWishListItem = async (id: number) => {
        const buyertoken = await getStorageData("buerLoginToken");
        if (buyertoken) {
            const header = {
                "Content-Type": "application/json",
                "token": buyertoken
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.apiDeleteFromWishList = requestMessage.messageId;

            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.favouriteItemEndPoint}/${id}`
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.deleteApiMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    }

    updateWishListItem = async (id: number, type: string) => {
        const buyertoken = await getStorageData("buerLoginToken");
        if (buyertoken) {
            const header = {
                "Content-Type": "application/json",
                "token": buyertoken
            };
            if (type == "catalogue") {
                type = "product"
            }
            const body = {
                "data": {
                    "favouriteable_id": id,
                    "type": type
                }
            }

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.apiAddToWishList = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.postApiMethod);
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.favouriteItemEndPoint
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    }

    toggleCityDropdownHandler = () => {
        this.setState({
            toggleCityDropdown: !this.state.toggleCityDropdown
        });
    };

    closeCityDropdownHandler = () => {
        this.setState({
            toggleCityDropdown: false
        });
    };

    toggleCountryDropdownHandler = () => {
        this.setState({
            toggleCountryDropdown: !this.state.toggleCountryDropdown
        });
    };

    closeCountryDropdownHandler = () => {
        this.setState({
            toggleCountryDropdown: false
        });
    };

    toggleStateDropdownHandler = () => {
        this.setState({
            toggleStateDropdown: !this.state.toggleStateDropdown
        });
    };

    closeStateDropdownHandler = () => {
        this.setState({
            toggleStateDropdown: false
        });
    };

    goToBuyerHome = () => {
        const msgData: Message = new Message(
            getName(MessageEnum.NavigationMessageLandingPage)
        );
        msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), "BuyerDashboard");
        this.send(msgData);

    };

    



    apiCall = async (apiReqData: ApiPayloadType) => {
        const { contentType, method, endPoint, body, type } = apiReqData;
        this.setState({ loading: true });
        const token = await getStorageData('buerLoginToken');
        const header = {
            "Content-Type": contentType,
            token: token,
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        body && type != 'formData' ?
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )
            : requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    getCashfreeOrderDataCallback = (responseJson: InitiatePaymentApiResponse) => {
        this.setState({ loading: false });
        if (!responseJson.errors && responseJson) {
            this.setState({ cashFreeOrderId: responseJson.payment_order.order_id, cashFreeSessionId: responseJson.payment_order.payment_session_id }, () => {
                this.handleCashfreePayment(responseJson.payment_order.payment_session_id, responseJson.payment_order.order_id);
            })
        } else {
            this.setState({ openPaymentStatus: true, paymentStatusText: responseJson.payment_order.message, severity: "error"});
        }
    };

    
    checkPayemntCashfree = async () => {
        if (this.state.activeStep === 3 && this.state.paymentMethod === 'cashfree') {
            this.getCashfreeSessionApiId = await this.apiCall({
                contentType: configJSON.apiContentType,
                method: configJSON.postApiMethod,
                endPoint: configJSON.getOrderPaymentInitiateEndPoint,
                body: {
                    "id": String(this.state.cartData.id),
                },
                type: ""
            });
        }
    };

    handleCashfreePayment = async (sessionId : string, orderId: string) => {
        const returnBaseUrl = baseURL.replace('ruby','react');
        const cashfree = await load({
            mode: "sandbox",
        });
        let checkoutOptions = {
            paymentSessionId: sessionId,
            returnUrl: `${returnBaseUrl}/Payment/Cashfree/Order/${orderId}`
        };
        cashfree.checkout(checkoutOptions).then((result: CashfreeResultPayload) => {
            if (result.error) {
                this.setState({ openPaymentStatus: true, paymentStatusText: "Something Went wrong!!", severity: "error"});
            } 
        });
    };

    handleSnackbarToogle = () => {
        this.setState({ openPaymentStatus: false, paymentStatusText: "" });
    };
    // Customizable Area End
}
