import React from "react";
// Customizable Area Start
import PostCreationNewController from "./PostCreationNewController";
import {
    Box,
    Typography,
    styled,
    IconButton,
    Grid,
    Avatar,
    TextField,
    Button
} from "@material-ui/core";
import SellerSidebar from "../../landingpage/src/SellerSidebar.web";
import { backButton, calendarIc, crossIcon, hamburgerIcon, notificationIc, plusSquareImg, uploadIcon } from "../../LiveStreaming/src/assets";
import { IOSSwitch } from "../../InventoryManagement3/src/ListingProduct.web";
// Customizable Area End

export interface Props {
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class PostCreationNew extends PostCreationNewController {

    constructor(props: Props) {
        super(props);
    }
    // Customizable Area Start
    renderUploadImageSection = () => {
        const { previewImages, taggedPeople } = this.state;
        return (
            <Grid item xs={12} md={12} lg={8}>
                <Box className={"spaceBetween"}>
                    <Typography className="uploadHeading">
                        Upload Images {previewImages.length}/6
                    </Typography>
                    <Typography data-test-id="clearAllImagesTestID" className="uploadHeading clearAllImage" onClick={this.handleRemoveAllImages}>
                        Clear all image
                    </Typography>
                </Box>
                {previewImages.length > 0 &&
                    <Box style={styles.galleryContainer}>
                        <Box style={styles.imagePreviewBox} position="relative">
                            <img
                                src={previewImages[0]}
                                alt="Preview 0"
                                style={styles.image}
                            />
                            <img data-test-id="singleImagesTestID0" src={crossIcon} alt="Remove" style={styles.crossIcon} onClick={() => this.handleRemoveImage(0)} />
                            {taggedPeople.map((tag, index) => (
                                <Box
                                    key={index}
                                    style={{
                                        ...styles.tagBox,
                                        top: `${tag.y}px`,
                                        left: `${tag.x}px`,
                                    }}
                                >
                                    {tag.name}
                                    <Box
                                        style={{
                                            position: 'absolute',
                                            top: '-10px',
                                            left: '50%',
                                            marginLeft: '-5px',
                                            width: '0',
                                            height: '0',
                                            borderLeft: '5px solid transparent',
                                            borderRight: '5px solid transparent',
                                            borderBottom: '10px solid black',
                                        }}
                                    />
                                </Box>
                            ))}
                        </Box>

                        {previewImages.slice(1).map((image, index) => (
                            <Box key={index} style={styles.imagePreviewBox}>
                                <img
                                    src={image}
                                    alt={`Preview ${index + 1}`}
                                    style={styles.image}
                                />
                                <img src={crossIcon} alt="Remove" style={styles.crossIcon} onClick={() => this.handleRemoveImage(index + 1)} />
                            </Box>
                        ))}

                        <Box style={styles.addIconBox}>
                            <label htmlFor="thumbnail-upload">
                                <input
                                    type="file"
                                    accept=".jpg, .png"
                                    onChange={this.handlePostImageChange}
                                    style={{ display: 'none' }}
                                    id="thumbnail-upload"
                                />
                                <Box style={styles.uploadIconPostContainer}>
                                    <img src={plusSquareImg} alt="Add icon" style={styles.addIconImage} />
                                </Box>
                            </label>
                        </Box>
                    </Box>
                }

                {previewImages.length === 0 &&
                    <Box className={"uploadPostContainer"} component="fieldset">
                        <label htmlFor="thumbnail-upload">
                            <input
                                type="file"
                                accept=".jpg, .png"
                                onChange={this.handlePostImageChange}
                                style={{ display: "none" }}
                                id="thumbnail-upload"
                                data-test-id="thumbnailImageInput"
                            />
                            <Box className={"uploadIconPostContainer"} >
                                <img src={uploadIcon} alt="Upload Icon" className={"uploadPostIcon"} />
                                <Typography className={"uploadPostHereText"}>
                                    <Typography component="span" className={"uploadPostText"}>
                                        Upload
                                    </Typography>
                                    {" "}
                                    here
                                </Typography>
                            </Box>
                        </label>
                    </Box>
                }
                {this.state.isPhotoUploaded &&
                    <Typography className="reqText">Please Upload images</Typography>
                }
                <Box style={{ marginTop: "28px" }} display="flex" alignItems="center" width="100%" borderBottom="1px solid #E0E0E0" pb={1}>
                    <Avatar
                        alt="User Avatar"
                        src="https://your-avatar-url.com/avatar.jpg" // replace with your actual image url
                        style={{ width: 40, height: 40, margin: "0px 5px 0 0 " }}
                    />
                    <TextField
                        fullWidth
                        variant="standard"
                        placeholder="Write a caption..."
                        InputProps={{ disableUnderline: true }}
                        style={{ fontSize: '1rem' }}
                        value={this.state.captionText}
                        onChange={this.handlePostCaptionChange}
                        data-test-id="captionInputTestID"
                    />
                </Box>
                {this.state.isCaption &&
                    <Typography className="reqText">Please enter caption</Typography>
                }
                <Box style={{ marginTop: "28px", paddingBottom: "16px" }} display="flex" alignItems="center" width="100%" borderBottom="1px solid #E0E0E0" pb={1}>
                    <Typography className="uploadHeading headingcolorchange">Tag people</Typography>
                </Box>
                <Box style={{ marginTop: "28px", paddingBottom: "16px" }} display="flex" alignItems="center" width="100%" borderBottom="1px solid #E0E0E0" pb={1}>
                    <Typography className="uploadHeading headingcolorchange">Add product</Typography>
                </Box>
            </Grid>
        )
    }

    renderActiveMessageSection = () => {
        return (
            <Grid item xs={12} md={12} lg={4}>
                <Box className={"switchPostContainer"}>
                    <Box>
                        <Typography className={"switchPostTitle"}>
                            Activate messaging
                        </Typography>
                        <Typography className={"switchPostDescription"}>
                            Turn this on if you want to get messages <br /> from people regarding this product.
                        </Typography>
                    </Box>
                    <IOSSwitch
                        data-test-id="switchTestID"
                        checked={this.state.activeMessage}
                        onChange={this.handleSwitchChange}
                    />
                </Box>
            </Grid>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <>
                <PostMainContainer>
                    <Box className="liveStreamingPostSideMenu">
                        <div className="dashboard-outer">
                            <div className="dashboard-inner">
                                <SellerSidebar
                                    navigation={"/SellerDashboard"}
                                    id={""}
                                    classes={undefined}
                                    isOpen={this.state.isSideBarOpen}
                                    data-test-id="sellerSidebar"
                                    activetabName="create"
                                    closeModal={() => this.handlePostSideBar()}
                                    data-testid="isOpenTestId"
                                />
                            </div>
                        </div>
                    </Box>
                    <MainContainerInner>
                        <Box className="hideSideBarHamburgerSection">
                            <IconButton
                                data-test-id="hamburger-btn"
                                onClick={() => this.handlePostSideBar()}
                            >
                                <img src={hamburgerIcon} alt="hamburger" />
                            </IconButton>
                        </Box>
                        <Box className={"spaceBetween"}>
                            <Box className={"backBtnNavigation"} m={0}>
                                <img data-test-id="backButton" src={backButton} alt="backButton" onClick={() => this.handlePostBackButton()} />

                                <Typography className={"mainPageTitle"} data-test-id="inventoryTxtId">Create a post</Typography>

                            </Box>
                            <Box component='span' className={"flexContainer"}>
                                <Box component="span" className={"headerUpIcons"}><img src={calendarIc} alt="calendarIcon" /></Box>
                                <Box component="span" className={"headerUpIcons"}><img src={notificationIc} alt="bellIcon" /></Box>
                            </Box>
                        </Box>
                        <Grid container spacing={4}>
                            {this.renderUploadImageSection()}
                            {this.renderActiveMessageSection()}
                            <Box className={"saveAndButtonPostContainer"}>
                                <Button data-test-id="saveBtnTestId" className={"saveAndButtonPost"} onClick={this.handlePostShare}>Share</Button>
                            </Box>
                        </Grid>
                    </MainContainerInner>
                </PostMainContainer>
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const styles = {
    galleryContainer: {
        display: 'flex',
        overflowX: 'auto' as 'auto',
        whiteSpace: 'nowrap' as 'nowrap',
        width: '100%',
        padding: '16px',
        boxSizing: 'border-box' as 'border-box',
        border: "2px dashed #ccc",
        borderRadius: "10px",
        marginTop: "28px",
    },
    tagBox: {
        position: 'absolute' as 'absolute',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        color: 'white',
        padding: '2px 5px',
        borderRadius: '5px',
        fontSize: '12px',
    },
    crossIcon: {
        width: "24px",
        height: "24px",
        cursor: "pointer",
        marginLeft: "8px",
        position: 'absolute' as 'absolute',
        top: 5,
        right: 5,
        background: "white",
        borderRadius: '50%',
    },
    imagePreviewBox: {
        flex: '0 0 auto',
        width: '55%',
        marginRight: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #ccc',
        borderRadius: '5px',
        position: 'relative' as 'relative',
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover' as 'cover',
        borderRadius: '8px',
    },
    addIconBox: {
        flex: '0 0 auto',
        width: '55%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #0000004D',
        borderRadius: '8px',
    },
    uploadIconPostContainer: {
        width: '50px',
        height: '50px',
    },
    addIconImage: {
        width: "86px",
        height: "86px",
    },
};
const PostMainContainer = styled(Box)({
    display: "flex",
    marginTop: "93px",
    "@media (max-width: 1399px)": {
        marginTop: "70px"
    },
    "& .liveStreamingPostSideMenu": {
        marginTop: "0px",
        "@media (max-width: 991px)": {
            marginTop: "20px"
        }
    },
    "& .switchPostContainer": {
        justifyContent: "space-between",
        display: "flex",
        marginBottom: "1em",
        alignItems: "center",
        marginTop: "28px"
    },
    "& .switchPostTitle": {
        color: '#555555',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontStyle: 'normal',
        fontFamily: 'Poppins',
        fontSize: '16px',
        margin: "0.2em 0em",
        fontWeight: 600,
        lineHeight: 'normal',
    },
    "& .switchPostDescription": {
        color: '#555555',
        fontStyle: 'normal',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '14px',
        margin: "0.2em 0em",
        fontWeight: 400,
        lineHeight: 'normal',
    },
    "& .saveAndButtonPost": {
        width: '30%',
        height: '3em',
        background: '#F5EA16',
        borderRadius: "25px",
        color: '#444',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        pointer: "cursor",
        textTransform: "none" as const,
        "&:hover": {
            background: '#F5EA16',
        }
    },
    "& .saveAndButtonPostContainer": {
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
        marginBottom: "2em",
        marginTop: "2em",
        "@media(max-width: 960px)": {
            margin: "1em"
        }
    },
})

const MainContainerInner = styled(Box)({
    position: "relative",
    paddingTop: "2%",
    width: "100%",
    padding: "0em 2em",
    "& .uploadPostHereText": {
        fontFeatureSettings: "'clig' off, 'liga' off",
        color: '#555',
        fontSize: '16px',
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontStyle: 'normal',
    },
    "& .uploadPostText": {
        color: '#555',
        fontFamily: 'Poppins',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontStyle: 'normal',
        fontSize: '16px',
        textDecorationLine: 'underline',
        fontWeight: 600,
    },
    "& .uploadPostIcon": {
        width: "48px",
        height: "48px"
    },
    "& .uploadPostContainer": {
        border: "2px dashed #ccc",
        borderRadius: "10px",
        height: "168px",
        alignItems: "center",
        display: "flex",
        position: "relative",
        justifyContent: "center",
        marginTop: "28px",
    },
    "& .uploadHeading": {
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: "600",
        lineHeight: "24px",
        textAlign: "left",
        color: "#555555",
        marginTop: "28px"
    },
    "& .clearAllImage": {
        textDecoration: "underline",
        cursor: "pointer"
    },
    "& .headingcolorchange": {
        color: "#000000",
    },
    "& .reqText": {
        color: "red",
    },
    "@media(max-width: 991px)": {
        paddingTop: "2%",
        padding: "0em 2em",
        width: "100%",
    },
    "& .hideSideBarHamburgerSection": {
        display: "none",
        "@media(max-width: 991px)": {
            display: "block",
            position: "absolute",
            top: "70px",
            right: "18px"
        }
    },
    "& .headerUpIcons": {
        width: '48px',
        borderRadius: '10px',
        height: '48px',
        background: 'var(--0, #FFF)',
        border: '1px solid var(--0, #FFF)',
        display: "flex",
        boxShadow: '0px 0px 20px 1px rgba(0, 0, 0, 0.10)',
        justifyContent: "center",
        alignItems: "center",
        flexShrink: 0,
        margin: "0em 0.5em",
    },
    "& .mainPageTitle": {
        fontSize: '20px',
        color: 'rgba(68, 68, 68, 0.80)',
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontStyle: 'normal',
        marginLeft: "0.5em",
        lineHeight: 'normal',
    },
    "& .spaceBetween": {
        justifyContent: "space-between",
        display: "flex",
    },
    "& .backBtnNavigation": {
        alignItems: "center",
        display: "flex",
    },
    "& .flexContainer": {
        display: "flex"
    },
});
// Customizable Area End
