export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const GetStarted_Img = require("../assets/GetStarted_Img.png");
export const GetStarted2ndImg = require("../assets/GetStarted2ndImg.png");
export const Profile_img = require("../assets/Profile_img.png");
export const profileImgLeft = require("../assets/profileImgLeft.png");
export const profileImgCenter = require("../assets/profileImgCenter.png");
export const profileImgRight = require("../assets/profileImgRight.png");
export const Save_logo = require("../assets/Save_logo.svg");
export const Save_logo_2 = require("../assets/save_logo_2.svg");
export const Timer_logo = require("../assets/Timer_logo.svg");
export const App_Logo = require("../assets/App_Logo.svg");
export const ImageOverlay = require("../assets/ImageOverlay.png");
export const SignupArrow = require("../assets/SignupArrow.svg");
export const AppleLogo = require("../assets/AppleLogo.svg");
export const GoogleLogo = require("../assets/GoogleLogo.svg");
export const FacebookLogo = require("../assets/FacebookLogo.svg");
export const Oval = require("../assets/Oval.png");
export const radar = require("../assets/radar.svg");
export const BoxShaddow_Img = require("../assets/BoxShaddow_Img.png");
export const Pwd_visible_eye = require("../assets/Pwd_visible_eye.svg");
export const Wave = require("../assets/Wave.svg");
export const twoWave = require("../assets/twoWave.png");
export const selectBox = require("../assets/selectBox.svg");
export const centerImage = require("../assets/centerImage.png");
export const leftImage = require("../assets/leftImage.png");
export const rightImage = require("../assets/rightImage.png");
export const Collage = require("../assets/Collage.png");
export const iphoneLogo = require("../assets/iphoneLogo.png");
export const iphoneImage = require("../assets/iphoneImage.png");
export const indiaFlag = require("../assets/indiaFlag.svg");
export const whiteAppLogo = require("../assets/whiteAppLogo.svg");
export const leftArrow = require("../assets/leftArrow.svg");
export const cancelBtn = require("../assets/cancelBtn.svg");
export const hidePassword =  require("../assets/hidePassword.png")




